export const menuItems = [
  {
    label: 'Home',
    path: '/',
  },
  {
    label: 'About Us',
    path: '/about',
  },
  {
    label: 'Our Works',
    path: '/ourworks',
    subMenu: [
      { label: 'AI 서비스', path: '/ourworks/ai-service' },
      { label: 'AI 시스템 개발', path: '/ourworks/ai-system' },
      { label: 'AI 교육 서비스', path: '/ourworks/ai-edu-service' },
      { label: '솔루션라운지', path: '/ourworks/solution-lounge' },
    ],
  },
  {
    label: 'News',
    path: '/news',
  },
  {
    label: 'Careers',
    path: '/careers',
  },
  {
    label: 'Contact',
    path: '/contact',
  },
];
