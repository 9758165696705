import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ourworks,
  ourworksBanner1,
  ourworksBanner2,
  ourworksBanner3,
  miniLogo,
  solutionCard_1,
  solutionCard_2,
  solutionCard_3,
  solutionCard_4,
} from '../assets/images/Images';
import TextSliding from '../components/TextSliding';
import '../styles/ourworks.css';
import PageBanner from '../components/PageBanner';
import { solutionCardData } from '../constants/solutionCardData';

const OurworksPage = () => {
  const navigate = useNavigate();

  const slideRef = useRef(null);

  const handleNext = () => {
    if (slideRef.current) {
      slideRef.current.scrollBy({ left: 320, behavior: 'smooth' });
    }
  };

  const handlePrev = () => {
    if (slideRef.current) {
      slideRef.current.scrollBy({ left: -320, behavior: 'smooth' });
    }
  };

  return (
    <div className="w-full mx-auto bg-[#F1F4F9]">
      {/* 배경 이미지와 스크롤 아래로 안내 */}
      <PageBanner title="OUR WORKS" subtitle="우리의 작품은 혁신과 열정의 결과입니다" backgroundImage={ourworks} />

      {/* 최상단 배너 */}
      <div className="flex flex-col items-center mt-8 p-[80px] gap-[36px] max-w-full w-full mx-auto bg-[#E1E2FF]">
        {/* 첫 번째 섹션 */}
        <div className="flex flex-col items-center max-w-full text-center">
          <p className="flex items-center text-center uppercase text-[36px] font-bold text-black">
            창의적 AI 기술<span className="font-light">로</span>
          </p>
          <p className="flex items-center text-center text-[24px] font-light text-black">기업의 일상을 혁신하고,</p>
        </div>

        {/* 두 번째 섹션 */}
        <div className="flex flex-col items-center max-w-full text-center">
          <p className="flex items-center text-center uppercase text-[36px] font-bold text-black">
            맞춤형 솔루션<span className="font-light">으로</span>
          </p>
          <p className="flex items-center text-center text-[24px] font-light text-black">
            더 많은 산업과 미래를 연결합니다.
          </p>
        </div>
      </div>

      <TextSliding text={'직무에 적합한 인재와 연결 합니다'} position={935} />

      <div className="max-w-[1200px] w-[1180px] mx-auto mt-36">
        <div className="list-services">
          <div className="line"></div>

          {/* AI 서비스 */}
          <div className="row-service">
            <div className="relative overflow-hidden">
              <div className="img1" style={{ backgroundImage: `url(${ourworksBanner1})` }} />
              <img src={miniLogo} alt="MiniLogo" className="union-icon" />
              <div className="image-overlay">
                <div className="title">
                  <span className="service-title title-main">AI 서비스</span>
                </div>
              </div>
            </div>
            <div className="main-content-service">
              <div className="list-service">
                {/* 1-1 */}
                <div className="details">
                  <div className="text">
                    <span className="service-number">01.</span>
                    <span className="service-title">AI 전략 및 컨설팅</span>
                  </div>
                  <div className="content">
                    <p>기업이 인공지능(AI)과 머신러닝 기술을 효과적으로 도입하고 활용할 수 있도록 돕는 서비스입니다.</p>
                    <p>컨설팅은 주로 AI기술의 잠재력을 극대화하여 비즈니스 목표를 달성하는 데 초점을 맞춥니다.</p>
                  </div>
                </div>

                {/* 1-2 */}
                <div className="details">
                  <div className="text">
                    <span className="service-number">02.</span>
                    <span className="service-title">AI기반 MVP개발</span>
                  </div>
                  <div className="content">
                    <p>AI기반 제품에서 M.V.P는 핵심기능만을 포함한 제품 버전으로, 초기</p>
                    <p>사용자의 피드백을 받아</p>
                    <p>최소한의 기능만 갖추고 실제 사용자에게 가치를 제공할 수 있다.</p>
                  </div>
                </div>

                {/* 1-3 */}
                <div className="details">
                  <div className="text">
                    <span className="service-number">03.</span>
                    <span className="service-title">사용자 정의 모델 개발</span>
                  </div>
                  <div className="content">
                    <p>기업이 인공지능(AI)과 머신러닝 기술을 효과적으로 도입하고 활용할</p>
                    <p>수 있도록 돕는 서비스입니다.</p>
                    <p>컨설팅은 주로 AI기술의 잠재력을 극대화하여 비즈니스 목표를 달성하는 데 초점을 맞춥니다.</p>
                  </div>
                </div>

                {/* 1-4 */}
                <div className="details">
                  <div className="text">
                    <span className="service-number">04.</span>
                    <span className="service-title">빅데이터 & 보유 솔루션</span>
                  </div>
                  <div className="content">
                    <p>귀사의 개발된 AI솔루션 운영의 필수 요소로 AI모델의 성능과 확장성을</p>
                    <p>보장하며 데이터 처리 및 저장, </p>
                    <p>모델학습, 배포 등을 효율적으로 관리할 수 있게 합니다.</p>
                  </div>
                </div>
              </div>
              <button
                onClick={() => {
                  navigate('/ourworks/ai-service');
                  window.scrollTo(0, 0);
                }}
                className="detail-button relative flex items-center px-6 py-3 bg-[#E1E2FF] border-[1.5px] border-[#2E3190] rounded-full overflow-hidden group"
              >
                <div
                  className="absolute left-0 top-0 h-full w-0 bg-black transition-all duration-500 group-hover:w-full"
                  style={{ zIndex: 1 }}
                ></div>
                <span className="text-black text-lg font-medium z-10 group-hover:text-white transition-colors duration-500">
                  자세히 보기
                </span>
                <span className="ml-1 text-black z-10 group-hover:text-white transition-colors duration-500">
                  ->
                </span>
              </button>
            </div>
          </div>

          <div className="line"></div>

          {/* AI 시스템 개발 */}
          <div className="row-service">
            <div className="main-content-service">
              <div className="list-service">
                {/* 2-1 */}
                <div className="details">
                  <div className="text">
                    <span className="service-number">01.</span>
                    <span className="service-title">AI 서비스 개발</span>
                  </div>
                  <div className="content">
                    <p>
                      웹, 앱 고도화, 자연어 처리 어플리케이션, 컴퓨터 비전 및 이미지 인식, AI기반 자동화 솔루션 (RPA)
                    </p>
                  </div>
                </div>

                {/* 2-2 */}
                <div className="details">
                  <div className="text">
                    <span className="service-number">02.</span>
                    <span className="service-title">AI 챗봇 개발</span>
                  </div>
                  <div className="content">
                    <p>대한작업치료사협회 '아띠', 스윗솔루션 '스윗', 새롭 등 맞춤형 AI</p>
                    <p>챗봇 솔루션 개발</p>
                  </div>
                </div>

                {/* 2-3 */}
                <div className="details">
                  <div className="text">
                    <span className="service-number">03.</span>
                    <span className="service-title">인턴 AI</span>
                  </div>
                  <div className="content">
                    <p>내 손안의 완벽한 업무 비서</p>
                  </div>
                </div>
              </div>
              <button
                onClick={() => {
                  navigate('/ourworks/ai-system');
                  window.scrollTo(0, 0);
                }}
                className="detail-button relative flex items-center px-6 py-3 bg-[#E1E2FF] border-[1.5px] border-[#2E3190] rounded-full overflow-hidden group"
              >
                <div
                    className="absolute left-0 top-0 h-full w-0 bg-black transition-all duration-500 group-hover:w-full"
                    style={{zIndex: 1}}
                ></div>
                <span
                    className="text-black text-lg font-medium z-10 group-hover:text-white transition-colors duration-500">
                  자세히 보기
                </span>
                <span className="ml-1 text-black z-10 group-hover:text-white transition-colors duration-500">
                  ->
                </span>
              </button>
            </div>
            <div className="relative overflow-hidden">
              <div className="img2" style={{backgroundImage: `url(${ourworksBanner2})`}}/>
              <img src={miniLogo} alt="MiniLogo" className="union-icon" />
              <div className="image-overlay">
                <div className="title">
                  <span className="service-title title-main">AI 시스템 개발</span>
                </div>
              </div>
            </div>
          </div>

          <div className="line"></div>

          {/* AI 교육 서비스 */}
          <div className="row-service">
            <div className="relative overflow-hidden">
              <div className="img3" style={{ backgroundImage: `url(${ourworksBanner3})` }} />
              <img src={miniLogo} alt="MiniLogo" className="union-icon" />
              <div className="image-overlay">
                <div className="title">
                  <span className="service-title title-main">AI 교육 서비스</span>
                </div>
              </div>
            </div>
            <div className="main-content-service">
              <div className="list-service">
                {/* 3-1 */}
                <div className="details">
                  <div className="text">
                    <span className="service-number">01.</span>
                    <span className="service-title">AI 교육 플랫폼</span>
                  </div>
                  <div className="content">
                    <p>AX-ONE 온라인 플랫폼, 생성형 AI 챌린지 오프라인 워크숍 제공</p>
                  </div>
                </div>

                {/* 3-2 */}
                <div className="details">
                  <div className="text">
                    <span className="service-number">02.</span>
                    <span className="service-title">AI 오프라인 워크샵</span>
                  </div>
                  <div className="content">
                    <p>생성형 AI 챌린지 오프라인 워크숍 제공</p>
                  </div>
                </div>
              </div>
              <button
                onClick={() => {
                  navigate('/ourworks/ai-edu-service');
                  window.scrollTo(0, 0);
                }}
                className="detail-button relative flex items-center px-6 py-3 bg-[#E1E2FF] border-[1.5px] border-[#2E3190] rounded-full overflow-hidden group"
              >
                <div
                    className="absolute left-0 top-0 h-full w-0 bg-black transition-all duration-500 group-hover:w-full"
                    style={{zIndex: 1}}
                ></div>
                <span
                    className="text-black text-lg font-medium z-10 group-hover:text-white transition-colors duration-500">
                  자세히 보기
                </span>
                <span className="ml-1 text-black z-10 group-hover:text-white transition-colors duration-500">
                  ->
                </span>
              </button>
            </div>
          </div>

          <div className="line"></div>
        </div>

        {/* 솔루션 라운지 파트 */}
        <div className="flex flex-col items-center mt-12">
          <div className="text-[36px] font-bold text-black uppercase">솔루션 라운지</div>
          <div className="text-[24px] font-thin text-black uppercase mt-2">혁신 솔루션의 공간</div>
        </div>

        {/* 솔루션 라운지 슬라이드 카드 */}
        <div className="relative w-full mt-12 pb-12">
          {/* 좌측 화살표 */}
          <button
            className="absolute left-0 top-1/2 transform -translate-y-1/2 text-[#2E3190] rounded-full"
            style={{ zoom: 2 }}
            onClick={handlePrev}
          >
            &lt;
          </button>

          {/* 슬라이드 카드 */}
          <div
            className="flex overflow-x-auto w-[90%] no-scrollbar space-x-4 justify-start"
            style={{ marginLeft: '3.6rem' }}
            ref={slideRef}
          >
            {solutionCardData.map((card, index) => {
              const solutionCardImages = [solutionCard_1, solutionCard_2, solutionCard_3, solutionCard_4];
              return (
                <div key={index} className="relative flex-shrink-0 w-[308px] h-[308px] overflow-hidden">
                  <div
                    className="absolute inset-0 bg-cover bg-center transition-transform duration-500 ease-in-out"
                    style={{
                      backgroundImage: `url(${solutionCardImages[index]})`,
                    }}
                  ></div>

                  <div
                    className="absolute inset-0 bg-black text-white p-6 text-center content-center transition-transform duration-500 ease-in-out transform"
                    onMouseEnter={(e) => (e.currentTarget.style.transform = 'translateX(-100%)')}
                    onMouseLeave={(e) => (e.currentTarget.style.transform = 'translateX(0)')}
                  >
                    <h3 className="mb-4 text-2xl font-bold break-words">{card.title}</h3>
                    {card.content.map((content, contentIndex) => (
                      <p key={contentIndex} className="text-[17px] font-[100] break-words">
                        {content}
                      </p>
                    ))}
                    <p className="mt-2 text-lg font-thin break-words">{card.details}</p>
                  </div>
                </div>
              );
            })}
          </div>

          {/* 우측 화살표 */}
          <button
            className="absolute right-0 top-1/2 transform -translate-y-1/2 text-[#2E3190] rounded-full"
            style={{ zoom: 2 }}
            onClick={handleNext}
          >
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
};

export default OurworksPage;
