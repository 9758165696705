import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './layout/Layout';
import MainPage from '../pages/MainPage';
import AboutusPage from '../pages/AboutusPage';
import NewsPage from '../pages/NewsPage';
import NewsDetailPage from '../pages/NewsDetailPage';
import OurworksPage from '../pages/OurworksPage';
import AiServicePage from '../pages/AiServicePage';
import AiSystemPage from '../pages/AiSystemPage';
// import AiEducationService from "../pages/AiEducationService";
import AiEducationPage from '../pages/AiEducationPage';
import SolutionLounge from "../pages/SolutionLounge";
import ContactPage from "../pages/ContactPage";
import Careers from '../pages/Careers';
import ScrollToTop from '../components/ScrollToTop';


const RoutesSetup = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<MainPage />} />
          <Route path="/about" element={<AboutusPage />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/newsdetail/:id" element={<NewsDetailPage />} />
          <Route path="/ourworks" element={<OurworksPage />} />
          <Route path="/ourworks/ai-service" element={<AiServicePage />} />
          <Route path="/ourworks/ai-system" element={<AiSystemPage />} />
          <Route path="/ourworks/ai-edu-service" element={<AiEducationPage />} />
          <Route path="/ourworks/solution-lounge" element={<SolutionLounge />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default RoutesSetup;
