import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
    const location = useLocation();
    const prevPathRef = useRef(location.pathname);

    useEffect(() => {
        const prevPath = prevPathRef.current;

        if (prevPath !== location.pathname) {
            window.scrollTo(0, 0);
        }

        prevPathRef.current = location.pathname;
    }, [location]);

    return null;
}

export default ScrollToTop;