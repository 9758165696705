import React from 'react';

const DetailsButton = ({ onClick, label }) => (
  <button
    onClick={onClick}
    className="relative box-border flex items-center justify-center px-6 py-6 w-[150px] h-[48px] bg-[rgba(1,1,1,0.12)] border-[1.5px] border-[#EBEBEB] rounded-full text-white font-bold text-[16px] leading-[24px] uppercase overflow-hidden group"
    style={{ color: '#FFFFFF' }}
  >
    <div className="absolute inset-0 w-0 h-full bg-white transition-all duration-500 ease-in-out group-hover:w-full"></div>
    <span className="relative z-10 transition-colors duration-500 group-hover:text-[#2E3190]">{label}</span>
  </button>
);

export default DetailsButton;
