import React from 'react';
import { partners, partnersList } from '../assets/images/Images';

const Partners = () => (
  <div>
    <div
      className="relative w-full h-[426px]"
      style={{
        aspectRatio: '1440 / 426',
        backgroundImage: `url(${partners})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        width: '100%',
      }}
    >
      <div className="absolute inset-0 bg-[rgba(1,1,1,0.24)] backdrop-blur-[1px]"></div>

      <div className="absolute flex flex-col items-center justify-center inset-0 text-center text-white">
        <div className="text-5xl font-bold uppercase">Partners</div>
        <div className="text-base mt-4">보기만 해도 든든한 파트너</div>
      </div>
    </div>
    <div className="mt-8 pb-12 flex justify-center">
      <img src={partnersList}></img>
    </div>
  </div>
);

export default Partners;
