import React from "react";
import { useSearchParams } from 'react-router-dom';

import logo from '../assets/images/AIEducationPage/main_logo.png';
import axone from '../assets/images/AIEducationPage/axone.png';
import axone_logo from '../assets/images/AIEducationPage/axone_logo.png';
import axone_1 from '../assets/images/AIEducationPage/axone_1.png';
import axone_2 from '../assets/images/AIEducationPage/axone_2.png';
import background1 from '../assets/images/AIEducationPage/background_1.png';
import background2 from '../assets/images/AIEducationPage/background_2.png';
import background3 from '../assets/images/AIEducationPage/background_3.png';
import background4 from '../assets/images/AIEducationPage/background_4.png';
import img1 from '../assets/images/AIEducationPage/img_1.png';
import img2 from '../assets/images/AIEducationPage/img_2.png';
import img3 from '../assets/images/AIEducationPage/img_3.png';
import img4 from '../assets/images/AIEducationPage/img_4.png';
import img5 from '../assets/images/AIEducationPage/img_5.png';
import img6 from '../assets/images/AIEducationPage/img_6.png';
import img7 from '../assets/images/AIEducationPage/img_7.png';
import img8 from '../assets/images/AIEducationPage/img_8.png';
import img9 from '../assets/images/AIEducationPage/img_9.png';
import smile from '../assets/images/AIEducationPage/smile.png';


const HeroSection = () => {
    const scrollToTabSection = () => {
        const element = document.getElementById('tab-section');
        if (element) {
            const headerOffset = 70;
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };

    return (
        <div className="flex flex-col lg:flex-row min-h-[520px]">
            {/* Left Section (55%) */}
            <div
                className="lg:w-[55%] relative p-8 lg:px-16 flex flex-col justify-center overflow-hidden"
                style={{
                    backgroundImage: `url(${background4})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <div className="absolute inset-0 bg-black/30 backdrop-blur-[64px]"/>

                <div className="relative z-10 ml-20">
                    <h1 className="text-[80px] leading-[80px] font-bold text-white mb-6">
                        AI 교육 서비스
                    </h1>
                    <p className="text-[24px] leading-[24px] font-light text-white mb-12">
                        AI 교육으로 미래 역량 강화를 지원합니다
                    </p>
                    <p className="text-[16px] leading-[24px] font-light text-white ">
                        AI 활용의 문턱을 낮추고, 맞춤형 학습과 실습을 통해
                    </p>
                    <p className="text-[16px] leading-[24px] font-light text-white ">
                        혁신적인 업무 성과를 이끄는 교육 솔루션을 제공합니다.
                    </p>
                    <p className="text-[16px] leading-[24px] font-light text-white mb-12 ">
                        기업과 개인의 성장을 지원하며 변화하는 기술 환경에 대응합니다
                    </p>
                    <button
                        onClick={scrollToTabSection}
                        className="group mt-6 inline-flex items-center gap-2 text-lg text-white transition-colors bg-transparent border border-white rounded-full px-6 py-2 relative overflow-hidden"
                    >
                        <span className="relative z-10">자세히 보기</span>
                        <div
                            className="absolute left-0 top-0 h-full w-0 bg-[#2E3190] transition-all duration-700 group-hover:w-full"></div>
                    </button>

                </div>
            </div>

            {/* Right Section (45%) */}
            <div
                className="lg:w-[45%] relative p-8 flex items-center justify-center overflow-hidden"
                style={{
                    // backgroundImage: "url('')",
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            >
                <div className="absolute inset-0 bg-[#2B1056]/80"></div>

                {/* Content */}
                <div className="relative overflow-hidden">
                    <img
                        className="lg:w-[550px] h-[360px] object-contain"
                        src= {logo}
                        alt="AX-ONE"
                    />
                </div>
            </div>
        </div>
    );
};




const TabHeader = ({ currentTab, handleTabChange, tabContent }) => {
    const getTabStyle = (tabName) => {
        return `px-6 py-3 rounded-full transition-all ${
            currentTab === tabName
                ? "bg-purple-600 text-white"
                : "bg-transparent border border-white/30 hover:border-white"
        }`;
    };

    return (
        <div className="w-full h-[680px] text-white"
             style={{
                 backgroundImage: `url(${background2})`,
                 backgroundSize: 'cover',
                 backgroundPosition: 'center',
                 backgroundRepeat: 'no-repeat',
             }}>
            <div className="w-full mt-16 px-4">
                {/* 탭 버튼 */}
                <div className="flex gap-4 mb-12 justify-center">
                    <button
                        onClick={() => handleTabChange('platform')}
                        className={getTabStyle('platform')}
                    >
                        AI 온라인 교육 플랫폼
                    </button>
                    <button
                        onClick={() => handleTabChange('workshop')}
                        className={getTabStyle('workshop')}
                    >
                        AI 오프라인 워크샵
                    </button>
                </div>

                <div className="text-center ">
                    <img src={axone} alt="AX-ONE" className="mx-auto mb-8 w-[340px] h-[104px]" />
                    <div className="relative">
                        <h2 className="text-[64px] font-bold mb-4">
                            {tabContent[currentTab].title}
                        </h2>
                        <p className="text-[24px] font-bold mb-4">
                            {tabContent[currentTab].subtitle}
                        </p>
                        <div className="text-[16px] text-gray-400">
                            {tabContent[currentTab].description.map((line, index) => (
                                <p key={index} className="leading-relaxed">{line}</p>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const TabSection = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const currentTab = searchParams.get('tab') || 'platform';

    const tabContent = {
        platform: {
            title: "생성형 AI 온라인 교육 플랫폼",
            subtitle: "맞춤형 학습을 통한 AI 활용 역량 강화",
            description: [
                "AI 역량 진단과 맞춤형 커리큘럼 수석,",
                "체계적인 단계별 학습으로 실용적인 AI 활용법 제공"
            ]
        },
        workshop: {
            title: "생성형 AI 오프라인 워크샵",
            subtitle: "스토리텔링 기반의 실전 AI 활용",
            description: [
                "이론 중심의 수동적 학습을 넘어,",
                "흥미로운 테마로 배우는 실용적인 AI 활용법"
            ]
        }
    };

    const handleTabChange = (tab) => {
        setSearchParams({ tab });
    };

    return (
        <div id="tab-section">
            <TabHeader
                currentTab={currentTab}
                handleTabChange={handleTabChange}
                tabContent={tabContent}
            />

            <div className="w-full px-4 ">
                {currentTab === 'platform' ? <PlatformContent /> : <WorkshopContent />}
            </div>
        </div>
    );
};



const PlatformContent = () => {
    return (
        <div className="flex flex-col ">
            <div
                className="w-full h-[900px] relative"
                style={{
                    backgroundImage: `url(${background1})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}
            >
                {/* Top Content */}
                <div className="p-12 mt-12 ml-12">
                    <div className="mb-12">
                        <h1 className="text-white text-[44px] leading-[44px] mb-8">
                            <span className="font-bold">AI</span>
                            <span className="font-medium"> e</span>
                            <span className="font-bold">X</span>
                            <span className="font-medium">perience </span>
                            <span className="font-bold">O</span>
                            <span className="font-medium">ne-Stop solution, </span>
                            <span className="font-bold">AX-ONE</span>
                        </h1>

                        <div className="flex items-center gap-6 mb-6">
                            <img
                                src={axone_logo}
                                alt="AX-ONE Icon"
                                className="w-[128px] h-[128px]"
                            />

                            <div className="text-white text-lg flex flex-col">
                                <div className="text-[16px] text-white/60 font-bold mb-4">
                                    <p>AX-ONE의 [A]와 [우주선], [행성]의 이미지를 결합하여 미지의 영역을 탐험한다는 의미를 담았습니다.</p>
                                    <p>'A'는 AI를, 행성은 새로운 지식 영역을, 우주선은 이를 탐험하는 사용자를 나타냅니다.</p>
                                    <p>이런 방식으로 AX-ONE은 <span className="text-white">개인화된 학습 여정과 새로운 도전에 대한 탐험을 상징</span>합니다.
                                    </p>
                                </div>

                                <div className="flex gap-3">
                                <span
                                    className="text-[16px] font-bold px-6 py-2 rounded-full text-base text-white border-2 border-white"
                                 style={{backgroundColor: '#C01DF4'}}
                                ># C01DF4</span>
                                <span
                                    className="text-[16px] font-bold px-6 py-2 rounded-full text-base text-white border-2 border-white"
                                    style={{backgroundColor: '#5C4AD5'}}
                                ># 5C4AD5</span>
                                <span
                                    className="text-[16px] font-bold px-6 py-2 rounded-full text-base text-white border-2 border-white"
                                    style={{backgroundColor: '#0172BA'}}
                                ># 0172BA</span>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>


                <div className="absolute bottom-30 left-12 rounded-3xl p-6 ">
                    <div className="flex items-center justify-between">
                        <div className="flex flex-col items-center">
                            <img
                                src={axone_1}
                                alt="axone 1"
                                className="w-[736px] h-[226px] object-cover"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <OverviewStats/>

            <div className="relative">
                <div className="flex justify-center">
                    <img src={axone_2} alt="Platform Preview" className="w-full h-[900px]"/>
                </div>
            </div>
        </div>
    );
};






const OverviewStats = () => {
    const stats = [
        {
            title: "AI 효율성",
            percentage: 74,
            description: "74%의 회사가 생성적 AI가 수익을 늘리고 혁신을 촉진하는 데 도움이 되었다고 응답했습니다."
        },
        {
            title: "판매",
            percentage: 15.8,
            description: "생성형 AI를 구현한 회사는 평균 15.8%의 수익 증가를 보고했습니다."
        },
        {
            title: "비용",
            percentage: 15.2,
            description: "생성형 AI를 도입한 중 회사는 평균 15.2%의 비용 절감을 보고했습니다."
        },
        {
            title: "생산성",
            percentage: 22.6,
            description: "생성형 AI는 회사의 생산성을 평균 22.6% 증가시키는 것으로 보고되었습니다."
        },
        {
            title: "AI 도입",
            percentage: 42,
            description: "AI를 도입한 회사의 42%는 이미 AI를 적극적으로 사용하여 결과를 얻고 있습니다."
        },
        {
            title: "AI 투자",
            percentage: 59,
            description: "AI 도입 회사의 59%가 최근 몇 년 동안 AI를 구현했습니다."
        }
    ];

    return (
        <div className="relative w-full h-[628px] "
             style={{
                 backgroundImage: `url(${background3})`,
                 backgroundSize: 'cover',
                 backgroundPosition: 'center'
             }}>
            <div className="relative z-10 w-full p-16 flex items-center">
                <div className="w-[40%] flex-shrink-0 pt-8">
                    <h4 className="text-white mb-4">Overview</h4>
                    <h2 className="text-4xl font-bold text-white mb-4">
                        왜 많은 기업들이<br/>
                        AI를 전사적으로 도입하고있을까요?
                    </h2>
                    <p className="text-white/60">
                        비용절감, 생산성혁신, 그리고 지속가능한 성장을 이끄는 AL<br/>
                        기술 그 이상의 전략, 기업의 필수 동력으로 자리 잡고 있습니다.
                    </p>
                </div>

                <div className=" w-[60%] flex-1 grid grid-cols-3 gap-4 pl-16">
                    {stats.map((stat) => (
                        <StatCard key={stat.title} {...stat} />
                    ))}
                </div>
            </div>
        </div>
    );
};



const StatCard = ({ title, percentage, description }) => {
    return (
        <div className="bg-[#1A1A1A]/60 backdrop-blur-sm rounded-2xl w-[244px] h-[244px] p-4">
            <h3 className="text-[24px] leading-[24px] font-bold text-white mb-2">{title}</h3>
            <p className="text-[14px] leading-[14px] font-light text-white/60 mb-6 ">
                {description}
            </p>
            <div className="flex justify-end">
                <div className="relative w-24 h-24">
                    <svg className="w-full h-full transform -rotate-90">
                        <circle
                            cx="48"
                            cy="48"
                            r="44"
                            stroke="currentColor"
                            strokeWidth="4"
                            fill="transparent"
                            className="text-white/10"
                        />
                        <circle
                            cx="48"
                            cy="48"
                            r="44"
                            stroke="currentColor"
                            strokeWidth="4"
                            fill="transparent"
                            strokeDasharray={`${2 * Math.PI * 44}`}
                            strokeDashoffset={`${2 * Math.PI * 44 * (1 - percentage / 100)}`}
                            className="text-white transition-all duration-1000 ease-out"
                        />
                    </svg>
                    <div className="absolute inset-0 flex items-center justify-center">
                        <span className="text-xl font-bold text-white">{percentage}%</span>
                    </div>
                </div>
            </div>
        </div>
    );
};




const ProgramSection = () => {
    return (
        <div className="w-full mx-auto">
            {/* Top section */}
            <div className="h-[240px] p-6 relative"
                 style={{
                     backgroundImage: `url(${smile})`,
                     backgroundSize: 'cover',
                     backgroundPosition: 'center'
                 }}>
                <div className="absolute top-0 right-0 w-[240px] h-[240px]">
                </div>
                <div>
                    <h2 className="text-white text-[36px] font-bold mt-12 ml-4">PROGRAM</h2>
                    <p className="text-white text-[20px] font-light mt-2 ml-4">
                        생성형 AI Challenge 프로그램은 다양한 테마를 통해 AI 활용법을 학습합니다
                    </p>
                </div>
            </div>

            <div className="h-[630px] grid grid-cols-3 bg-gray-900">
                <div className="relative overflow-hidden">
                    <div
                        className="absolute bottom-0 bg-gradient-to-t from-black/30 to-transparent h-1/3 backdrop-blur-sm w-full"/>
                    <img
                        src={img1}
                        alt="K-POP 스타를 찾아라"
                        className="w-full h-full object-cover"
                    />
                    <div className="absolute bottom-0 m-8 text-white ">
                        <h3 className="text-[36px] font-bold mb-4 ">K-POP 스타를 찾아라</h3>
                        <p className="text-[20px] font-bold mb-4">AI와 함께하는 신인 아이돌 기획 프로젝트</p>
                        <p className="text-[16px] font-light mt-1">트렌드 조사 및 데이터 분석 아이돌 컨셉 기획홍보 콘텐츠 제작</p>
                    </div>
                </div>

                <div className="relative overflow-hidden">
                    <div
                        className="absolute bottom-0 bg-gradient-to-t from-black/30 to-transparent h-1/3 backdrop-blur-sm w-full"/>
                    <img
                        src={img2}
                        alt="영화감독"
                        className="w-full h-full object-cover"
                    />
                    <div className="absolute bottom-0 m-8 text-white ">
                        <h3 className="text-[36px] font-bold mb-4 ">나는 영화감독이다</h3>
                        <p className="text-[20px] font-bold mb-4">15초 영화 예고편 제작 프로젝트</p>
                        <p className="text-[16px] font-light mt-1">영화 컨셉 기획 및 시나리오 작성, 예고편 영상 제작</p>
                    </div>
                </div>

                <div className="relative overflow-hidden">
                    <div className="absolute bottom-0 bg-gradient-to-t from-black/30 to-transparent h-1/3 backdrop-blur-sm w-full" />
                    <img
                        src={img3}
                        alt="AI와 함께 떠나는 여행"
                        className="w-full h-full object-cover"
                    />
                    <div className="absolute bottom-0 m-8 text-white ">
                        <h3 className="text-[36px] font-bold mb-4 ">AI와 함께 떠나는 여행</h3>
                        <p className="text-[20px] font-bold mb-4">나만의 여행 패키지 기획 프로젝트</p>
                        <p className="text-[16px] font-light mt-1">시장 조사 및 분석, 여행 컨셉 기획, 패키지 홍보물 제작 서비스</p>
                    </div>
                </div>
            </div>
        </div>
    );
};


const EducationSection = () => {
    const topMethods = [
        { title: '강의식', img: img4 },
        { title: '이론 중심', img: img5 },
        { title: '수동적 학습', img: img6 }
    ];

    const bottomMethods = [
        { title: '체험형', img: img7 },
        { title: '실전 중심', img: img8 },
        { title: '능동적 참여', img: img9 }
    ];

    return (
        <div className="w-full bg-purple-950">
            {/* Header */}
            <div className="relative h-[400px] flex items-center justify-center">
                <div className="absolute inset-0">
                    <img
                        src={background3}
                        alt="background"
                        className="w-full h-full object-cover"
                    />
                </div>
                <h2 className="text-[64px] font-bold text-white z-10">차별화된 교육 특징</h2>
            </div>

            <div className="w-full">
                <div className="flex">
                    {topMethods.map((method, index) => (
                        <div key={index} className="flex-1 border border-gray-600">
                            <div className="h-[116px] bg-[#313364] flex items-center justify-center">
                                <span className="text-white text-[36px] leading-[36px] font-bold">{method.title}</span>
                            </div>
                            <img
                                src={method.img}
                                alt={method.title}
                                className="w-full h-[364px] object-cover"
                            />
                        </div>
                    ))}
                </div>

                <div className="relative bg-white py-1 w-full">
                    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-10">
                        <div className="bg-white text-black px-6 py-6 rounded-full text-[36px] font-bold">
                            VS
                        </div>
                    </div>
                </div>

                <div className="flex">
                    {bottomMethods.map((method, index) => (
                        <div key={index} className="flex-1 border border-gray-600">
                            <img
                                src={method.img}
                                alt={method.title}
                                className="w-full h-[364px] object-cover"
                            />
                            <div className="h-[116px] bg-[#313364] flex items-center justify-center">
                                <span className="text-white text-[36px] leading-[36px] font-bold">{method.title}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};


const WorkshopContent = () => {
    return (
        <div className="">
            <ProgramSection/>
            <EducationSection/>
        </div>
    );
};


const LastHeroSection = () => {
    return (
        <div className="w-full flex justify-center mt-10 mb-8">
            <div className="relative w-full">
                <div
                    className="flex flex-col text-white h-[558px]"
                    style={{
                        backgroundImage: `url(${background4})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                    }}
                >
                    {/* Dark overlay */}
                    <div className="absolute inset-0 bg-black/30 backdrop-blur-[64px]"/>

                    <div className="relative h-full flex flex-col items-center justify-center text-white">
                        <h1 className="text-[64px] leading-[64px] font-bold mb-6">원데이원과 미래를 함께할 파트너</h1>
                        <p className="text-[24px] leading-[24px] font-light mb-8">문의사항을 남겨주시면 확인 후 연락드리겠습니다.</p>
                        <a
                            href="/contact"
                            className="group relative px-[32px] py-[12px] overflow-hidden border border-white text-white text-[16px] leading-[24px] rounded-[100px]"
                        >
                            <span
                                className="relative z-10 text-[20px] leading-[24px] font-medium group-hover:text-[#2E3190] transition-colors">문의하기</span>
                            <div
                                className="absolute left-0 top-0 h-full w-0 bg-white transition-all duration-700 group-hover:w-full"></div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};


const AiEducationService = () => {
    return (
        <div className="min-h-screen bg-black">
            <HeroSection />
            <TabSection />
            <LastHeroSection />
        </div>
    );
};

export default AiEducationService;