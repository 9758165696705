import React from 'react';

const TabMenu = ({ tabs, activeTab, onTabClick }) => {
  return (
    <div className="flex justify-center items-center gap-6 mb-8 pt-4 max-w-[1196px] mx-auto">
      {tabs.map((tab) => (
        <button
          key={tab.id}
          className={`flex items-center px-6 py-3 rounded-full transition-colors duration-500 font-bold text-[20px] leading-[24px] 
            ${
              activeTab === tab.id
                ? 'bg-black text-white'
                : 'bg-[#E1E2FF] text-black hover:bg-black hover:text-white'
            }`}
          onClick={() => onTabClick(tab.id)}
          style={{
            width: `${tab.width}px`,
            height: '48px',
          }}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};

export default TabMenu;
