import React from 'react';
import logo from '../../ourlogo.svg';

const Footer = () => {
  return (
    <footer className="bg-black py-10">
      <div className="container mx-auto flex justify-between items-center max-w-[1440px] px-16">
        {/* 왼쪽 로고 및 정보 섹션 */}
        <div className="flex flex-col gap-6">
          {/* 로고 */}
          <div className="flex items-center gap-6">
            <img src={logo} alt="O:DOC Logo" className="w-[170px] h-[48px]" />
          </div>

          {/* 회사 정보 텍스트 */}
          <div className="text-white font-[100] text-[13px] leading-[18px]">
            <p>
              (주)원데이원커뮤니케이션 | 대표: 김창희 | 사업자등록번호: 707-88-00147 | 개인정보관리책임자: 선세리 |
              통신판매: 제2018-서울서대문-0577호
            </p>
            <p>주소: 서울특별시 성동구 성수일로 99 서울숲AK밸리 809호 | 전화: 070-4143-0617</p>
          </div>
          <div className="flex gap-6">
            <p className="text-[12px] text-[white] font-[100]">개인정보처리방침</p>
            <p className="text-[12px] text-[white] font-[100]">이용약관</p>
          </div>
        </div>

        {/* 오른쪽 메뉴 및 저작권 */}
        <div className="flex flex-col justify-between items-end text-white" style={{ placeSelf: 'end' }}>
          {/* 저작권 정보 */}
          <div className="text-[12px] leading-[16px]">
            <p>&copy; 2024 ODOC. Copyright.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
