import React from 'react';
import logo1 from '../assets/images/Aboutus/logo_1.png'
import logo2 from '../assets/images/Aboutus/logo_2.png'
import logo3 from '../assets/images/Aboutus/logo_3.png'
import logo4 from '../assets/images/Aboutus/logo_4.png'
import logo5 from '../assets/images/Aboutus/logo_5.png'

const CI = () => {
    return (
        <div className="flex flex-col items-center mt-8 pb-8">
            {/* Signature Logotype Section */}
            <div className="w-full">
                <h2 className="text-[32px] font-bold mb-4">SIGNATURE LOGOTYPE</h2>
                <div className="flex justify-between items-center mb-8">
                    <p className="text-[20px] leading-[20px] text-[#575555] font-light">
                        (주)원데이원커뮤니케이션의 철학과 비전이 담긴 로고로, 한글, 영문, 약자로 구성하여 사용합니다.
                    </p>
                    <a
                        // href=""
                        download
                        className="bg-[#2E319D] text-white font-bold px-6 py-2 hover:bg-white transition-colors"
                    >
                        CI Download
                    </a>
                </div>
                <div className="h-[325px] bg-white p-8  mt-8">
                    <p className="text-right text-[12px] text-[#2E3190] font-bold mb-8">
                        *약자에 대한 로고는 다운로드 되지 않습니다.
                    </p>
                    <div className="grid grid-cols-4 gap-8">
                        <div className="flex flex-col items-center">
                            <p className="text-[16px] leading-[16px] text-center mt-4 mb-6 text-[#2E3190] font-bold">한글버전</p>
                            <img src={logo1} alt="한글버전" className="w-[220px] h-[110px]"/>
                        </div>
                        <div className="flex flex-col items-center">
                            <p className="text-[16px] leading-[16px] text-center mt-4 mb-6 text-[#2E3190] font-bold">영문버전</p>
                            <img src={logo3} alt="영문버전" className="w-[320px] h-[110px]"/>
                        </div>
                        <div className="flex flex-col items-center">
                            <p className="text-[16px] leading-[16px] text-center mt-4 mb-8 text-[#2E3190] font-bold">한영버전</p>
                            <img src={logo2} alt="한영버전" className="w-[320px] h-[110px]"/>
                        </div>
                        <div className="flex flex-col items-center">
                            <p className="text-[16px] leading-[16px] text-center mt-4 mb-8 text-[#2E3190] font-bold">약자</p>
                            <img src={logo4} alt="약자" className="w-full h-auto"/>
                        </div>
                    </div>
                </div>
            </div>

            {/* Symbol Mark Section */}
            <div className="w-full mt-16">
                <h2 className="text-[32px] font-bold mb-4">SYMBOL MARK</h2>
                <p className="text-[#575555] text-[20px] font-light mb-2">
                    <strong className="font-bold">"하루에 한번씩 의미있는 소통을 하자!"</strong> (주)원데이원커뮤니케이션의 이름에는
                    <strong className="font-bold">‘진정성있는 소통'</strong>의 의미가 담겨있습니다.
                </p>
                <p className="text-[#575555] text-[20px] font-light mb-2">
                    진정성있는 소통으로 협력하여, 팀을 넘어 세상과의 연결로 모두를 위한 행복한 기술을 개발하겠습니다.
                </p>
                <div className="bg-white p-8 h-[320px]  flex justify-center items-center">
                    <div className="flex space-x-12">
                        <img src={logo5} alt="Symbol Mark Original" className="w-[338px] h-[160px] mr-8 "/>
                        <div className="w-[630px] h-[200px] border rounded-lg border-[rgba(0,0,0,0.22)] p-6">
                            <h3 className="text-[20px] leading-[24px] text-[#575555] font-bold mb-4">‘모두가 행복한 세상’을 꿈꾸는 스마일</h3>
                            <p className="text-[16px] leading-[24px] text-[#575555] mb-2">
                                소소인들 위한 기술 개발이 아닌,
                            </p>
                            <p className="text-[16px] leading-[24px] text-[#575555] mb-2">
                            모두가 함께 성장하고 혜택을 누리는 세상을 만들기 위한 기술 개발이 우리의 목표입니다.
                            </p>
                            <p className="text-[16px] leading-[24px] text-[#575555] mb-42">
                            기술 발전의 끊임가지 더 많은 사람에게 긍정적인 영향을 미칠 수 있도록 노력하고 있습니다.
                            </p>
                        </div>
                </div>
            </div>
        </div>
    <div className="w-full mt-16 mb-16">
        <h2 className="text-[32px] font-bold mb-4">COLOR SYSTEM</h2>
        <p className="text-[24px] mb-4">메인 컬러</p>
        <div className="flex justify-between space-x-8">
            <div className="w-[600px] overflow-hidden">
                        <div className="rounded-[30px] border border-black">
                            <div className="border-b border-black">
                                <div className="h-[100px] bg-[#2E3190] rounded-t-[30px]"></div>
                            </div>
                            <div className="bg-[#EEF1FE] p-8 text-center h-[230px] rounded-b-[30px]">
                                <h3 className="text-[24px] font-bold mb-4">Primary Blue</h3>
                                <div className="space-y-2 text-[20px] text-black">
                                    <p>C100 M95 Y5 K0</p>
                                    <p>R46 G49 B144</p>
                                    <p>#2E3190</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="w-[600px] overflow-hidden">
                        <div className="rounded-[30px] border border-black">
                            <div className="border-b border-black">
                            <div className="h-[100px] bg-white rounded-t-[30px]"></div>
                            </div>
                            <div className="bg-[#EEF1FE] p-8 text-center h-[230px] rounded-b-[30px]">
                                <h3 className="text-[24px] font-bold mb-4">White</h3>
                                <div className="space-y-2 text-[20px] text-black">
                                    <p>C0 M0 Y0 K0</p>
                                    <p>R255 G255 B255</p>
                                    <p>#FFFFFF</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CI;