import React from 'react';

const TagButton = ({ label }) => (
  <div className="flex flex-row items-center justify-center bg-[#E1E2FF] rounded-full px-6 py-3 cursor-pointer transition-colors duration-500 hover:bg-black group">
    <span className="text-xl font-medium text-black group-hover:text-white transition-colors duration-500">
      {label}
    </span>
  </div>
);

export default TagButton;
