import React from 'react';
import { useNavigate } from 'react-router-dom';

const SubNewsCard = ({ id, image, title, content }) => {
  const navigate = useNavigate();

  return (
    <div
      className="w-[372px] bg-white shadow-md cursor-pointer"
      onClick={() => {
        navigate(`/newsdetail/${id}`);
        window.scrollTo(0, 0);
      }}
    >
      <div className="w-[372px] h-[282px] overflow-hidden">
        <div
          className="w-full h-full bg-cover bg-center transform transition-transform duration-500 hover:scale-125"
          style={{ backgroundImage: `url(${image})` }}
        />
      </div>
      <div className="p-6">
        <h3
          className="text-black text-lg font-bold uppercase mb-4"
          style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}
        >
          {title}
        </h3>
        <p className="text-black text-sm" style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>
          {content}
        </p>
      </div>
    </div>
  );
};

export default SubNewsCard;
