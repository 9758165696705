import React from 'react';

const BannerWithOverlay = ({ backgroundImage, title, subtitle }) => (
  <div
    className="relative w-full h-[312px] overflow-hidden banner-bg"
    style={{
      backgroundImage: `url(${backgroundImage})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    }}
  >
    <div className="absolute flex flex-col items-center justify-center inset-0 text-center text-white z-10">
      <div className="text-6xl font-bold uppercase">{title}</div>
      <div className="text-xl font-thin mt-4">{subtitle}</div>
    </div>
    <div className="absolute inset-0 bg-blue-slide"></div>

    <style jsx>{`
      .banner-bg {
        position: relative;
        overflow: hidden;
      }

      .bg-blue-slide {
        background-color: black;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: -100%;
        z-index: 5;
        transition: left 0.4s ease;
      }

      .banner-bg:hover .bg-blue-slide {
        left: 0;
        transition: left 0.4s ease;
      }
    `}</style>
  </div>
);

export default BannerWithOverlay;
