import { main_2_1, main_2_2, main_2_3, main_2_4 } from '../assets/images/Images';

export const serviceCards = [
  {
    backgroundImage: main_2_1,
    title: 'AI 서비스',
    description: ['AI 전략 및 컨설팅', 'AI 기반 MVP 개발', '사용자 정의 모델 개발', '빅데이터 & 보유 솔루션'],
    link: '/ourworks/ai-service',
  },
  {
    backgroundImage: main_2_2,
    title: 'AI 시스템 개발',
    description: ['AI 서비스 개발', 'AI 챗봇 개발', 'AI 에이전트 개발'],
    link: '/ourworks/ai-system',
  },
  {
    backgroundImage: main_2_3,
    title: 'AI 교육 서비스',
    description: ['AI 플랫폼', 'AI 오프라인 워크숍'],
    link: '/ourworks/ai-edu-service',
  },
  {
    backgroundImage: main_2_4,
    title: '솔루션라운지',
    description: ['기업 성공사례'],
    link: '/ourworks/solution-lounge',
  },
];
