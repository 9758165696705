export const bulletPointConditions = {
  '인플루언서,연예인 사진 속 메이크업 분석 앱 ’Mr찍검‘ 서비스 고도화를 위한': true,
  '안면인식 기반 색상 매칭 AI알고리즘 학습용 색조 화장품 데이터 셋 개발': false,
  '비대면 온라인 마케팅 플랫폼［찐마블 :진짜 마켓 블로거］개발을 위한 SNS콘텐츠 생산자의 실질 영향력 분석모델': true,
  'AI학습용 데이터 셋 구축': false,
};

export const events = [
  {
    startDate: '2015.06',
    endDate: '2019.12',
    title: '2015년 6월 17일 설립',
    description: [
      '정보통신산업진흥원 K-Global ICT 기술개발 과제 선정',
      '서울경제진흥원 Re-Start Camp 최우수상 선정',
      '중소벤처기업부 창업도약 패키지 선정',
      '스마트한 화장의 시작 ‘필릿(Fillit)’ 브랜드 6종 개발 일본, 베트남, 미국 수출',
      '롯데홈쇼핑&한국디자인진흥원 ‘디자인 이노베이션 랩’ 우수상 수상',
      '화장품 프로토타이핑 제조를 위한 원스톱 온라인 B2B플랫폼 ’프로토코스(Protocos)‘ R&D과제 선정',
      '화장품 발색 데이터 기반 색조검색서비스 ’핑크티라노(Pinktrano) 개발',
      '삼성전자 스타트업 투자 프로그램 ’C-Lab 8기‘ 선정 투자유치',
      '벤처기업, 메인비즈, 기업부설연구소 보유',
    ],
  },
  {
    startDate: '2020.01',
    endDate: '2023.12',
    title: '',
    description: [
      '정보통신산업진흥원 AI바우처 선정',
      '정보통신산업진흥원 클라우드 지원사업 선정',
      '한국데이터산업진흥원 데이터바우처 기술 공급기업 선정 (21~24년)',
      '기초 화장품 AI추천 서비스 ‘키인(KiiN) 개발 런칭',
      '인플루언서,연예인 사진 속 메이크업 분석 앱 ’Mr찍검‘ 서비스 고도화를 위한',
      '안면인식 기반 색상 매칭 AI알고리즘 학습용 색조 화장품 데이터 셋 개발',
      '수의사를 위한 외과 수술교육 플랫폼 VeterFlix의 해외 진출을 위한 수술 자막 데이터 자동요약 AI개발',
      '비대면 온라인 마케팅 플랫폼［찐마블 :진짜 마켓 블로거］개발을 위한 SNS콘텐츠 생산자의 실질 영향력 분석모델',
      'AI학습용 데이터 셋 구축',
      '고혈압,당뇨병 등 국내 만성질환 의료 데이터 가공을 통한 만성질환자 전용 헬스케어 서비스 JSUN U&H 개발',
      '피부타입 및 호르몬 주기 데이터와 화장품 제품 데이터 기반으로 ’개인 맞춤형 기초 화장품 추천 서비스‘ 뚜왈렛 개발',
      '유기농 단백질 식품 브랜드［바른단백］의 3040 여성 타겟 전문 헬스푸드 플랫폼화를 위한 시장 분석 데이터 수집',
      'K-뷰티 무역거래 간소화 서비스 ’크라겐‘',
      'K-뷰티 한국화장품 역직구 플랫폼 ’치빅스‘의 화장품 추천 서비스 개발을 위한 AI가공 데이터 구축사업',
      '중소화장품 제조사의 역량강화와 신규 고객 모집 활성화를 위한 K-뷰티 제조 특화 AI챗봇 개발',
      '증간현실 메이크업 서비스 ’쀼티‘의 색조 화장품 제품 및 콘텐츠 추천 AI서비스 개발',
      '인플루언서를 활용한 성과형 팔리는 콘텐츠 제작 솔루션 개발',
      '그림깨우기-크리스토퍼루크헤베를레 전 AI아티스트 개발',
      '위메이크페인팅 모바일 색칠 게임의 콘텐츠 생산성과 활용성 향상을 위한 사물 인식 및 경계선 검출 AI솔루션 개발',
    ],
  },
  {
    startDate: '2024.01~',
    title: '',
    description: [
      '(사)대한작업치료사협회 상담챗봇',
      '화장품 제조사 일본수출과 바이어 응대를 위한 K-뷰티 챗봇 개발',
      '무인 키즈카페 운영자의 효율적인 매장 관리와 고객 만족도 향상을 위한 청소상태 판별 ’AI Zenith‘ 개발',
      'UNC갤러리 ’크리스토퍼 루크헤베를레 전‘ ’AI아티스트‘ 개발',
      '온라인 마케팅 시뮬레이터 ‘A-Bridge’ 개발',
      '체험형 AI워크샵 ‘생성형 AI챌린지’ 프로그램 개발, 생성형 AI활용 교육',
      '비전공자를 위한 AI교육 플랫폼 ‘AX-ONE’ 런칭',
    ],
  },
];
