import React from 'react';
import { useNavigate } from 'react-router-dom';
import { news, newsBanner } from '../assets/images/Images';
import SlideCards from '../components/SlideCards';
import { newsData } from '../constants/newsData';
import PageBanner from '../components/PageBanner';
import TagButton from '../components/TagButton';
import BannerWithOverlay from '../components/BannerWithOverlay';

const NewsPage = () => {
  const navigate = useNavigate();

  const news_tags = ['#기술', '#혁신', '#AI 서비스', '#솔루션라운지', '#교육 서비스'];

  return (
    <div className="w-full mx-auto bg-[#F1F4F9]">
      {/* 배경 이미지와 스크롤 아래로 안내 */}
      <PageBanner title="News" subtitle="원데이원 소식" backgroundImage={news} />

      <div className="max-w-[1200px] w-[1180px] mx-auto mt-4">
        {/* 최상단 스티커 메모 파트 */}
        <div className="mb-10">
          <div className="text-center mt-10 flex justify-center">
            <p className="w-[850px] text-[36px] font-bold leading-[24px] text-black">ODOC 스토리</p>
          </div>
          {/* 슬라이드 카드 넣기 */}
          <SlideCards />
        </div>

        {/* 배너 파트 */}
        <BannerWithOverlay
          backgroundImage={newsBanner}
          title="창의적인 AI로 산업을 혁신합니다"
          subtitle="모두가 행복한 개발을 목표로 하는 글로벌 No.1 크리에이티브 파트너"
        />

        {/* 원데이원 최신 소식 */}
        <div className="flex flex-col items-center mt-12">
          <div className="text-[36px] font-bold text-black uppercase mb-4">ODOC 최신 소식</div>
          {/* 태그 버튼 */}
          <div className="flex flex-row justify-center items-center gap-6 w-[1196px] h-[48px]">
            {news_tags.map((tag, index) => (
              <TagButton key={index} label={tag} />
            ))}
          </div>
          {/* 뉴스 목록 */}
          <div className="flex flex-col items-start mt-10 w-[1196px] gap-10">
            {/* 헤드 뉴스 */}
            <div className="flex flex-row items-end w-[1196px] h-[296px] shadow-md bg-white">
              <div className="relative w-[618px] h-[296px] overflow-hidden">
                <div
                  className="absolute w-full h-full bg-cover bg-center transform transition-transform duration-500 hover:scale-125"
                  style={{ backgroundImage: `url(${newsData[0].image})`, backgroundSize: 'cover' }}
                ></div>
              </div>
              <div className="flex flex-col justify-between items-end p-6 gap-6 w-[578px] h-[296px] relative">
                <div className="absolute overflow-hidden rounded-full top-6 right-6">
                  <button
                    className="flex items-center px-6 py-3 bg-[#E1E2FF] border-[1.5px] border-[#2E3190] rounded-full relative overflow-hidden group"
                    onClick={() => {
                      navigate(`/newsdetail/${newsData[0].id}`);
                      window.scrollTo(0, 0);
                    }}
                  >
                    <div
                      className="absolute left-0 top-0 h-full w-0 bg-black transition-all duration-500 group-hover:w-full"
                      style={{ zIndex: 1 }}
                    ></div>
                    <span className="text-black text-lg font-medium z-10 group-hover:text-white transition-colors duration-500">
                      자세히 보기
                    </span>
                    <span className="ml-2 text-black z-10 group-hover:text-white transition-colors duration-500">
                      ->
                    </span>
                  </button>
                </div>
                <div className="absolute bottom-[3.5rem] left-6 right-6">
                  <h2 className="text-black text-3xl font-bold uppercase mb-4 tracking-wide">
                    {newsData[0].title}
                  </h2>
                  <p className="text-black text-lg font-light leading-tight">{newsData[0].title_content}</p>
                </div>
              </div>
            </div>
            {/* 서브 뉴스 */}
            <div className="flex flex-row gap-10 flex-wrap pb-12">
              {newsData.slice(1).map((newsItem) => (
                <div
                  key={newsItem.id}
                  className="w-[372px] bg-white shadow-md cursor-pointer"
                  onClick={() => {
                    navigate(`/newsdetail/${newsItem.id}`);
                    window.scrollTo(0, 0);
                  }}
                >
                  <div className="w-[372px] h-[282px] overflow-hidden">
                    <div
                      className="w-full h-full bg-cover bg-center transform transition-transform duration-500 hover:scale-125"
                      style={{ backgroundImage: `url(${newsItem.image})` }}
                    />
                  </div>
                  <div className="p-6">
                    <h3 className="text-black text-[20px] font-bold uppercase mb-4">{newsItem.title}</h3>
                    <p className="text-black text-[14px]">{newsItem.title_content}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsPage;
