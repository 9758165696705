import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  aboutus,
  orgnization,
  vision,
  history,
  organizationChart,
  visionChart,
  value1,
  value2,
  value3,
  creatviePartners,
} from '../assets/images/Images';
import { Card } from '../components/Components';
import Timeline from '../components/Timeline';
import Partners from '../components/Partners';
import PageBanner from '../components/PageBanner';
import KeywordSection from '../components/Aboutus/KeywordSection';
import CI from '../components/Ci';

const tabs = [
  { id: 'organization', label: 'GROUP', image: orgnization },
  { id: 'vision', label: 'VISION', image: vision },
  { id: 'ci', label: 'CI', image: vision },  // CI 탭 추가 필요
  { id: 'history', label: 'HISTORY', image: history },
];

const AboutusPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(null);
  const [hoveredTab, setHoveredTab] = useState(null);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const tabFromUrl = query.get('tab');
    if (location.pathname === '/about' && !tabFromUrl) {
      setActiveTab(null);
    } else if (tabFromUrl && tabs.some((tab) => tab.id === tabFromUrl)) {
      setActiveTab(tabFromUrl);
    }
  }, [location]);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    navigate(`?tab=${tabId}`);
  };

  const getBackdropBlurStyle = (isActive) => {
    return {
      content: '""',
      position: 'absolute',
      inset: 0,
      backdropFilter: isActive ? 'blur(7px)' : 'none',
      zIndex: 1,
    };
  };

  return (
    <div className="w-full mx-auto bg-[#F1F4F9]">

      <PageBanner title="Company" subtitle="원데이원 소개" backgroundImage={aboutus} />

      <div className="max-w-[1440px] w-[1400px] mx-auto mt-4">

        <div className="mt-20">
          <div className="h-[320px] bg-[#F1F4F9] p-8 text-center mb-8">
            <h2 className="text-[64px] font-bold mt-4 mb-8">(주)원데이원커뮤니케이션</h2>
            <p className="text-[24px] text-black font-light mt-2">
              우리는 단순한 기술 개발을 넘어 <strong className="font-bold">사람 중심의 혁신을 추구</strong>합니다.<br/>
              <strong className="font-bold">‘모두가 행복한 기술개발’이라는 목표</strong>를 실현하며,
              <strong className="font-bold">아이디어와 기술을 결합해 세상을 변화</strong>시키고 있습니다.
            </p>
          </div>

          <div className="h-[680px] bg-[#4A154B] text-white p-12">
            <div className="flex items-center justify-center h-full">
              <div className="flex-1 pr-12 flex flex-col items-start">
                <h2 className="text-[44px] leading-[44px] font-bold mb-8">CREATIVE PARTNER</h2>
                <div className="space-y-6">
                  <div>
                    <h3 className="text-[20px] leading-[20px] font-bold">1. 사람 중심의 기술개발</h3>
                    <p className="text-[18px] leading-[18px] text-light mt-2 opacity-80">
                      우리의 기술은 사람을 위한 것입니다.
                    </p>
                    <p className="text-[18px] leading-[18px] text-light mt-2 opacity-80">
                      우리는 AI와 같은 첨단 기술을 개발하면서도,
                    </p>
                    <p className="text-[18px] leading-[18px] text-light mt-2 opacity-80">
                      그 기술이 모든 사람에게 혜택을 줄 수 있는 방향으로 나아가고자 합니다.
                    </p>
                  </div>
                  <div>
                    <h3 className="text-[20px] leading-[20px] font-bold mt-8">2. 아이디어와 기술의 융합</h3>
                    <p className="text-[18px] leading-[18px] text-light mt-2 opacity-80">
                      혁신적인 기술만으로는 세상을 바꿀 수 없습니다.
                    </p>
                    <p className="text-[18px] leading-[18px] text-light mt-2 opacity-80">
                      우리는 창의적인 아이디어와 기술을 결합하여 문제를 해결하고,
                    </p>
                    <p className="text-[18px] leading-[18px] text-light mt-2 opacity-80">
                      사회적 가치를 창출하는데 집중하고 있습니다.
                    </p>
                  </div>
                  <div>
                    <h3 className="text-[20px] leading-[20px] font-bold mt-8">3. 모두가 행복한 세상</h3>
                    <p className="text-[18px] leading-[18px] text-light mt-2 opacity-80">
                      기술이 소수만을 위한 것이 아니라,
                    </p>
                    <p className="text-[18px] leading-[18px] text-light mt-2 opacity-80">
                      모두가 함께 성장하고 혜택을 누리는 세상을 만드는 것이 우리의 비전입니다.
                    </p>
                    <p className="text-[18px] leading-[18px] text-light mt-2 opacity-80">
                      기술 발전의 결과가 더 많은 사람에게 긍정적인 영향을 미칠 수 있도록 노력하고 있습니다.
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex-1 flex items-center justify-center">
                <div className="w-[426px] h-[426px] rounded-full overflow-hidden bg-white/10">
                  <img src={creatviePartners} alt="Creative Partner" className="w-full h-full object-cover"/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-10">


          <div className="mt-10 relative">
            <p className="text-left text-[48px] leading-[48px] font-bold  ml-4 mb-4">
              Strong Skills
            </p>
            <KeywordSection
                title="실력"
                description={[
                  '"실력은 우리의 경쟁력입니다.',
                  '우리는 지속적인 학습과 자기 개발을 통해 개인 역량을 강화하고, ',
                  '효율적이며 창의적인 문제 해결을 추구합니다. ',
                  '맡은 업무에 대한 책임감을 바탕으로 최상의 결과를 만들어내며, 고객의 기대를 뛰어넘는 성과를 실현합니다."',
                ]}
                index="01"
                alignment="left"
            />
          </div>
          <p className="flex justify-end text-[48px] leading-[48px] font-bold">
            Authenticity
          </p>
          <div className="mt-5 relative flex justify-end">

            <KeywordSection
                title="진정성"
                description={[
                  '“진정성은 우리의 신뢰를 기반으로 합니다.',
                  '우리는 정직하고 투명한 소통을 통해 팀원과 고객 간의 신뢰를 쌓으며,',
                  '고객 중심 사고로 고객의 요구를 정확히 이해하고 초개인화된 맞춤형 솔루션을 제공합니다.',
                  '또한 팀워크와 상호 존중을 바탕으로, 모든 구성원이 진정성 있게 협력하며 함께 성장하고 있습니다.”',
                ]}
                index="02"
                alignment="right"
            />
          </div>
        </div>

        <div className="flex flex-col items-center mt-20">
          <div className="text-[36px] font-bold text-black uppercase">실력과 진정성을 바탕으로</div>
          <div className="text-[20px] font-[100] text-black uppercase mt-2">
            AI 혁신을 이끄는 전문가 집단입니다.
          </div>
        </div>

        <div className="flex justify-center items-center mt-10 space-x-10">
          {tabs.map((tab) => (
              <div
                  key={tab.id}
                  className="relative w-[372px] h-[272px] overflow-hidden flex items-center justify-center cursor-pointer"
                  style={{
                    backgroundImage: `url(${tab.image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                  onClick={() => handleTabClick(tab.id)}
                  onMouseEnter={() => setHoveredTab(tab.id)}
                  onMouseLeave={() => setHoveredTab(null)}
              >
                <div style={{...getBackdropBlurStyle(true), zIndex: 1}}/>
                {activeTab !== tab.id && (
                    <>
                      <div
                          className={`absolute inset-0 bg-[#4A154B] transition-transform duration-500 ease-in-out ${
                              hoveredTab === tab.id ? 'translate-x-[-100%]' : 'translate-x-0'
                          }`}
                          style={{
                            zIndex: 2,
                          }}
                      />
                    </>
                )}

                <span className="text-white text-[64px] font-bold z-10">{tab.label}</span>
              </div>
          ))}
        </div>

        <div className="col-span-12">
          {activeTab === null && <div className="pb-8"></div>}
          {activeTab === 'organization' && (
              <div>
                <div className="flex flex-col items-center mt-8 pb-8">
                  <img src={organizationChart} alt="조직도" className="w-full"/>
                </div>
              </div>
          )}

          {activeTab === 'vision' && (
              <div>
                <div className="relative flex justify-center items-center mt-12">
                  <img src={visionChart} alt="비전" className="w-full"/>
                </div>

                <div className="flex justify-center mt-16 pb-12 space-x-8 w-[95%] mx-auto">
                  <Card image={value1} title="MISSION" content={['모두가 행복한', '기술개발']}/>
                  <Card image={value2} title="VISION" content={['글로벌 NO.1 크리에이티브', 'AI 파트너']}/>
                  <Card image={value3} title="CORE VALUE" content={['실력과', '진정성']}/>
                </div>
              </div>
          )}

          {activeTab === 'history' && (
              <div>
                <div className="text-center text-[32px] text-black mt-8" style={{fontWeight: 600}}>
                  <p>각 분야의 전문성과 협력을 통해</p>
                  <p className="text-[16px] font-light mt-2">글로벌 No.1 크리에이티브 AI 파트너로 도약합니다</p>
                </div>
                <Timeline/>
              </div>
          )}
          {activeTab === 'ci' && <CI />}  {/* 여기로 이동 */}

        </div>

      </div>
      {activeTab === 'history' && <Partners/>}
    </div>
  );
};

export default AboutusPage;
