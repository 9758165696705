import React from 'react';

const KeywordSection = ({ title, description, index, alignment }) => {
  return (
      <div
          className={`flex items-start p-6 gap-6 h-[160px] bg-black rounded-tl-[12px] rounded-tr-[12px] ${
              alignment === 'right' ? ' rounded-bl-[12px] w-[970px]' : 'rounded-br-[12px] w-[968px]'
          }`}
      >
        <div className="flex flex-col justify-center items-end" style={{fontWeight: 600}}>
          <div className="text-white text-[36px] leading-[36px] mt-4">{index}.</div>
          <div className="text-white text-[36px] leading-[36px] mt-4">{title}</div>
        </div>
        <div
            className="w-[804px] h-[144px] text-white text-[23px] font-thin leading-[25px] items-center whitespace-pre-line">
          {description.map((line, idx) => (
              <p key={idx}>{line}</p>
          ))}
        </div>
        <div
            className={`absolute bottom-0 ${
                alignment === 'right' ? 'right-[-15px]' : 'left-[-15px] '
            } w-0 h-0 border-b-[15px] border-b-black border-r-[15px] border-r-transparent border-l-[15px] border-l-transparent`}
        ></div>
      </div>
  );
};

export default KeywordSection;
