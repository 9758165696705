import React, { useState, useRef } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import emailjs from '@emailjs/browser';

const EmailForm = ({ isModal, onModalSuccess }) => {
    const formRef = useRef();
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        category: '',
        resume: null,
        message: ''
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const validateField = (fieldName, value) => {
        if (!value) {
            setErrors(prev => ({ ...prev, [fieldName]: "정보가 입력되지 않았습니다." }));
            return false;
        }

        if (fieldName === 'phone') {
            const phoneRegex = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
            if (!phoneRegex.test(value)) {
                setErrors(prev => ({ ...prev, [fieldName]: "올바른 전화번호 형식이 아닙니다." }));
                return false;
            }
        }

        if (fieldName === 'email') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value)) {
                setErrors(prev => ({ ...prev, [fieldName]: "올바른 이메일 형식이 아닙니다." }));
                return false;
            }
        }

        setErrors(prev => ({ ...prev, [fieldName]: null }));
        return true;
    };

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;

        if (type === 'file') {
            setFormData(prev => ({
                ...prev,
                [name]: files[0]
            }));
            validateField(name, files[0]);
        } else {
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
            validateField(name, value);
        }

        if (name === 'category') {
            setIsOpen(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let hasError = false;

        // Validate all fields except resume
        Object.keys(formData).forEach(key => {
            if (key !== 'resume' && !validateField(key, formData[key])) {
                hasError = true;
            }
        });

        if (!hasError) {
            setLoading(true);
            try {
                const templateParams = {
                    from_name: formData.name,
                    from_email: formData.email,
                    from_phone: formData.phone,
                    position: getCategoryLabel(formData.category),
                    message: formData.message,
                    to_name: 'ODOC 채용담당자'  // 수신자 이름 추가
                };

                const result = await emailjs.send(
                    process.env.REACT_APP_EMAILJS_SERVICE_ID,
                    process.env.REACT_APP_EMAILJS_INQUIRY_TEMPLATE_ID,
                    templateParams,
                    process.env.REACT_APP_EMAILJS_PUBLIC_KEY
                );

                setStatus('success');
                setFormData({
                    name: '',
                    phone: '',
                    email: '',
                    category: '',
                    resume: null,
                    message: ''
                });

                if (isModal && onModalSuccess) {
                    onModalSuccess();
                }
            } catch (error) {
                console.error('Error sending email:', error);
                setStatus('error');
            } finally {
                setLoading(false);
            }
        }
    };

    const getCategoryLabel = (value) => {
        const categories = {
            'ai_tech_planner': 'AI 기술기획자',
            'ai_tech_trainer': 'AI 기술 교육 강사',
            'generative_ai_engineer': '생성형 AI 엔지니어',
            'ai_platform_developer': 'AI 플랫폼 개발자',
            'marketing_communication_manager': '마케팅 및 커뮤니케이션 매니저'
        };
        return categories[value] || value;
    };

    return (
        <div className="bg-white p-8 rounded-lg shadow-lg max-w-5xl mx-auto">
            <h2 className="text-2xl font-bold text-black mb-6">지원서 등록</h2>

            <form ref={formRef} onSubmit={handleSubmit} className="space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="h-[85px]">
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className={`w-full p-2 border ${errors.name ? 'border-red-500' : 'border-gray-300'} rounded bg-[#F0F2FD]`}
                            placeholder="성명"
                        />
                        <p className="text-red-500 text-sm h-5 mt-1">{errors.name || ' '}</p>
                    </div>

                    <div className="h-[85px]">
                        <input
                            type="tel"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            className={`w-full p-2 border ${errors.phone ? 'border-red-500' : 'border-gray-300'} rounded bg-[#F0F2FD]`}
                            placeholder="연락처"
                        />
                        <p className="text-red-500 text-sm h-5 mt-1">{errors.phone || ' '}</p>
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="h-[85px]">
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className={`w-full p-2 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded bg-[#F0F2FD]`}
                            placeholder="이메일 주소"
                        />
                        <p className="text-red-500 text-sm h-5 mt-1">{errors.email || ' '}</p>
                    </div>

                    <div className="h-[85px] relative">
                        <select
                            name="category"
                            value={formData.category}
                            onChange={handleChange}
                            onClick={() => setIsOpen(!isOpen)}
                            onBlur={() => setIsOpen(false)}
                            className={`w-full p-2 border ${errors.category ? 'border-red-500' : 'border-gray-300'} rounded bg-[#F0F2FD] appearance-none pr-8 ${formData.category === '' ? 'text-gray-500' : 'text-black'}`}
                        >
                            <option value="">지원 분야</option>
                            <option value="ai_service_developer">AI 서비스 개발</option>
                            <option value="data_analyst">데이터 분석</option>
                            <option value="custom_solution_developer">맞춤형 솔루션 개발</option>
                            <option value="generative_ai_trainer">생성형 AI 교육</option>
                            <option value="devops_engineer">DevOps 엔지니어</option>
                            <option value="cyber_security_analyst">사이버 보안 분석가</option>
                            <option value="it_support_expert">IT 지원 전문가</option>
                        </select>
                        <div className="absolute right-2 top-3 pointer-events-none">
                            {isOpen ? <ChevronUp className="text-gray-400" /> : <ChevronDown className="text-gray-400" />}
                        </div>
                        <p className="text-red-500 text-sm h-5 mt-1">{errors.category || ' '}</p>
                    </div>
                </div>

                <div className="h-[245px]">
                    <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        className={`w-full p-2 border ${errors.message ? 'border-red-500' : 'border-gray-300'} rounded bg-[#F0F2FD] h-[200px] resize-none`}
                        placeholder="지원 메시지"
                    />
                    <p className="text-red-500 text-sm h-5 mt-1">{errors.message || ' '}</p>
                </div>

                {/* 상태 메시지와 버튼 */}
                <div className="h-[50px] flex justify-between items-center">
                    <div className="flex-1">
                        {status === 'success' && (
                            <div className="p-3 bg-green-50 border border-green-200 rounded">
                                <p className="text-black">지원서가 성공적으로 접수되었습니다!</p>
                            </div>
                        )}

                        {status === 'error' && (
                            <div className="p-3 bg-red-50 border border-red-200 rounded">
                                <p className="text-red-800">지원서 접수 중 오류가 발생했습니다. 다시 시도해주세요.</p>
                            </div>
                        )}
                    </div>

                    <button
                        type="submit"
                        disabled={loading}
                        className="px-6 py-3 bg-white text-black border border-black rounded-full hover:bg-black hover:text-white transition-colors duration-200 disabled:opacity-50 ml-4"
                    >
                        {loading ? '처리중...' : '지원하기'}
                    </button>

                </div>
            </form>
        </div>
    );
};

export default EmailForm;