import React, { useRef } from 'react';
import { upper2 } from '../assets/images/Images';
import { cardData } from '../constants/newsData';

const SlideCards = () => {
  const slideRef = useRef(null);

  const handleNext = () => {
    if (slideRef.current) {
      slideRef.current.scrollBy({ left: 320, behavior: 'smooth' });
    }
  };

  const handlePrev = () => {
    if (slideRef.current) {
      slideRef.current.scrollBy({ left: -320, behavior: 'smooth' });
    }
  };

  return (
    <div className="relative w-full mt-12">
      {/* 좌측 화살표 */}
      <button
        className="absolute left-0 top-1/2 transform -translate-y-1/2 text-[#2E3190] rounded-full"
        style={{ zoom: 2 }}
        onClick={handlePrev}
      >
        &lt;
      </button>

      {/* 슬라이드 카드 */}
      <div
        className="flex overflow-x-auto w-[90%] no-scrollbar space-x-4 justify-start"
        style={{ marginLeft: '3.6rem' }}
        ref={slideRef}
      >
        {cardData.map((card, index) => (
          <div
            key={index}
            className="relative flex flex-shrink-0 w-[308px] h-[308px] bg-black text-white p-6 content-center text-center justify-center items-center overflow-hidden group"
          >
            <img
              src={upper2}
              alt="upper2"
              className="absolute top-6 left-6 w-6 h-6 opacity-0 transform scale-75 transition-all duration-500 ease-in-out group-hover:opacity-100 group-hover:scale-100"
            />
            <img
              src={upper2}
              alt="upper2"
              className="absolute bottom-6 right-6 w-6 h-6 opacity-0 transform rotate-180 scale-75 transition-all duration-500 ease-in-out group-hover:opacity-100 group-hover:scale-100"
            />

            <div className="relative flex flex-col items-center justify-center z-10 transition-all duration-500 ease-in-out">
              <p className="text-2xl font-bold mb-2 group-hover:mb-4 transition-all duration-500 ease-in-out">
                {card.title}
              </p>
              {card.content.map((content, i) => (
                <p key={i} className="text-[17px] font-[100] break-words transition-all duration-500 ease-in-out">
                  {content}
                </p>
              ))}
              <p className="mt-1 text-[17px] font-[100] break-words">{card.details}</p>
            </div>
          </div>
        ))}
      </div>

      {/* 우측 화살표 */}
      <button
        className="absolute right-0 top-1/2 transform -translate-y-1/2 text-[#2E3190] rounded-full"
        style={{ zoom: 2 }}
        onClick={handleNext}
      >
        &gt;
      </button>
    </div>
  );
};

export default SlideCards;
