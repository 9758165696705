import React, { useState} from 'react';
import { ChevronLeft, ChevronRight, X } from 'lucide-react';
import EmailForm from '../components/EmailForm';
import img1 from '../assets/images/CarrerPage/img1.png';
import img2 from '../assets/images/CarrerPage/img2.png';
import img7 from '../assets/images/CarrerPage/img7.png';
import alarms from '../assets/images/CarrerPage/access_alarms_24px.png';
import location from '../assets/images/CarrerPage/location_on_24px.png';
import group from '../assets/images/CarrerPage/group_24px.png';

const jobListings = [
    {
        title: 'AI 기술기획자',
        type: '풀타임',
        location: '한국',
        person: '0명',
        experience: '신입',
        description: 'AI 프로젝트의 전략적 방향 설정과 실증적인 실행을 주도하며, 마케팅 아이덴티티를 산출할 분들의 지원 바랍니다.',
        responsibilities: [
            'AI 기술 트렌드 분석 및 시장 조사',
            'AI 관련 프로젝트의 기획, 설계, 운영',
            '신규 AI 기술 및 서비스의 전략 수립',
            'AI 솔루션 개발을 위한 프로세스 도출 및 시장 전략 수립',
            '내부 및 외부 협업을 통한 AI 서비스 기획 및 실행',
            '데이터 분석을 통해 AI 기술 방안 반영 제안',
            '기술 관련 프레젠테이션 및 문서 작성'
        ],
        qualifications: [
            'AI 기술 및 산업에 대한 이해와 관심이 높은 분',
            'AI/ML 기술 및 알고리즘에 대한 기본적인 이해 (필수)',
            '시장 조사 및 데이터 분석을 기반으로 기술 개발할 수 있는 능력',
            '기술 트렌드에 대한 통찰과 현재적 시사를 갖춘 분',
            '다양한 부서와 원활히 프로젝트를 성공적으로 이끌 수 있는 커뮤니케이션 능력'
        ]
    },
    {
        title: 'AI 기술 교육 강사',
        type: '풀타임',
        location: '한국',
        person: '00명',
        experience: '신입',
        description: '본사 ‘생성형 AI챌린지’ 교육 프로그램 운영 및 전문강사 모집',
        responsibilities: [
            '학습자에게 실시간 또는 비동기 강의를 제공하며, AI 및 머신러닝, 딥러닝 관련 이론과 실습을 교육합니다.'
        ],
        qualifications: [
            'AI, 머신러닝 이론과 기술에 대한 심도 있는 지식',
            '교육 경험 및 효과적인 강의 능력',
            '다양한 교육 방식(온라인, 오프라인)에 대한 적응력',
        ]
    },
    {
        title: '생성형 AI 엔지니어',
        type: '풀타임',
        location: '한국',
        person: '0명',
        experience: '경력',
        description: '고객에게 창의적이고 가치 있는 솔루션을 제공하며, 텍스트, 이미지, 음성 등 다양한 데이터를 생성하고 관리할 수 있는\n' +
            'AI 모델을 연구 및 개발, AI기술을 선도하고 창의적인 솔루션을 개발할 인재를 기다립니다.',
        responsibilities: [
            '생성형 AI 모델(GAN, Transformer 등) 설계, 개발 및 최적화',
            '텍스트, 이미지, 음성 생성 모델 구축 및 운영',
            '생성형 AI 연구 및 최신 알고리즘 적용 (GPT, DALL·E, Stable Diffusion 등)',
            '대규모 데이터셋 준비 및 학습 파이프라인 구축',
            '모델 학습, 평가, 개선을 통한 성능 향상',
            'AI 기술을 활용한 고객 맞춤형 솔루션 개발',
            '생성형 AI 모델을 제품 및 서비스에 통합하는 작업',
            'AI 모델 운영 및 관리, 성능 모니터링 및 최적화'
        ],
        qualifications: [
            '컴퓨터 과학, 데이터 과학, 인공지능, 또는 관련 전공자',
            '딥러닝 모델(특히 생성형 AI) 개발 경험이 있는 분',
            'Python, TensorFlow, PyTorch 등 AI 프레임워크에 익숙한 분',
            'GAN, Transformer, VAE(변분 오토인코더) 등 생성형 모델에 대한 깊은 이해',
            '대규모 데이터셋을 처리하고 모델을 학습시키는 능력',
            '생성형 AI 모델의 실제 적용 경험 또는 프로젝트 경험',
            '기술적 문제 해결 능력과 논리적 사고를 가진 분'
        ]
    },
    {
        title: 'AI 플랫폼 개발자',
        type: '풀타임',
        location: '한국',
        person: '0명',
        experience: '경력',
        description: '',
        responsibilities: [
            'AI 교육 서비스가 원활하게 제공될 수 있도록 플랫폼을 개발하고, 사용자가 편리하게 접근할 수 있는 UI/UX를 설계합니다'
        ],
        qualifications: [
            '웹 개발 및 플랫폼 구축 경험 (Front-end, Back-end)',
            '클라우드 인프라(AWS, GCP) 관리 경험',
            '교육용 사용자 인터페이스(UI/UX) 디자인 경험'
        ]
    },
    {
        title: '마케팅 및 커뮤니케이션 매니저',
        type: '풀타임',
        location: '한국',
        person: '0명',
        experience: '경력',
        description: '',
        responsibilities: [
            'AI 교육 서비스의 마케팅 전략을 수립하고, 시장에 효과적으로 홍보하며 잠재 고객을 유치합니다.'
        ],
        qualifications: [
            '디지털 마케팅 및 소셜 미디어 운영 경험',
            '콘텐츠 마케팅 및 브랜딩 전략 수립 능력',
            '에듀테크 산업에 대한 이해'
        ]
    },
];

const HeroSection = () => {
    const scrollToApplication = () => {
        const element = document.getElementById('application-section');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="relative" style={{
            height: '426px',
            width: 'auto',
            margin: '0 auto',
            backgroundColor: 'gray',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <div className="flex flex-col justify-center items-center text-white"
                 style={{
                     width: '100%',
                     height: '100%',
                     backgroundImage: `url(${img1})`,
                     backgroundSize: 'cover',
                     backgroundPosition: 'center',
                     backgroundRepeat: 'no-repeat',
                 }}>
                <h1 className="text-[64px] leading-[64px] font-bold mb-2">JOIN OUR TEAM!</h1>
                <div style={{height: '28px'}}></div>
                <p className="text-[20px] leading-[20px] font-regular mb-2">AI 혁신의 선두주자 - 원데이원커뮤니케이션</p>
                <div style={{height: '28px'}}></div>
                <button
                    onClick={scrollToApplication}
                    className="relative overflow-hidden group text-white text-[16px] leading-[16px] border border-white rounded-full w-[150px] h-[48px]"
                >
                    <div className="absolute left-0 top-0 h-full w-0 bg-white transition-all duration-700 group-hover:w-full"></div>
                    <span className="relative z-10 group-hover:text-black ">지원하세요</span>
                </button>
                <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 text-center">
                    <div className="text-sm font-medium text-white mb-2">아래로 스크롤</div>
                    <div className="relative w-[6px] h-[9px] mx-auto mt-1">
                        <div className="w-[6px] h-[6px] bg-white rounded-full"></div>
                        <div className="w-[1px] h-[20px] bg-[rgba(255,255,255,0.6)] mx-auto"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const CompanyIntroText = () => {
    return (
        <div className="text-center mb-4">
            <h1 className="text-[36px] leading-[36px] text-black font-bold mt-8 mb-2">
                '모두가 행복한 기술 개발'을 목표로,
            </h1>
            <h2 className="text-[36px] leading-[36px] text-black font-bold mb-2">
                '글로벌 No.1 크리에이티브 AI 파트너'로 도약하고 있습니다.
            </h2>
            <p className="text-[36px] leading-[36px] text-black font-bold">
                우리는 실력과 진정성을 핵심 가치로 삼아 AI 기술 혁신의 최전선에 있습니다.
            </p>
        </div>
    );
};


const CreativePartner = () => (
    <div className="max-w-[1196px] mx-auto bg-black p-8 mt-8">
        <div className="flex items-start gap-6 mt-4">
            <div className="w-[116px] h-[116px] flex-shrink-0">
                <img src={img7} alt="Stripe Icon" className="w-full h-full"/>
            </div>
            <div className="flex-1">
                <div className="flex flex-col text-white">
                    <div className="flex items-center">
                        <div className="w-[600px] flex flex-col items-end">
                            <p className="text-[36px] font-bold mb-[-8px]">글로벌</p>
                            <h2 className="text-[64px] font-bold">크리에이티브 AI 파트너</h2>
                        </div>
                        <div className="w-[6px] h-[120px] bg-white mx-4 mb-4"></div>

                        <div className="w-[350px]">
                            <div className="flex flex-col items-start">
                                <p className=" text-[64px] font-bold mt-[-20px]">01</p>
                                <p className="text-[36px] font-bold mt-[-0px]">로 도약하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <p className="text-white text-[20px] leading-[20px] font-light mt-2">
            '글로벌 No.1 크리에이티브 AI 파트너'로 도약하고 있는 우리는 최첨단 인공지능 기술을 활용해 혁신적이고 창의적인 솔루션을 제공합니다.</p>
        <p className="text-white text-[20px] leading-[20px] font-light mt-2">고객의 필요를 깊이 이해하고 맞춤형 서비스를 통해 비즈니스 성공을
            지원합니다. </p>
        <p className="text-white text-[20px] leading-[20px] font-light mt-2">지속적인 연구 개발을 통해 AI 기술의 경계를 확장하며, 고객과 함께 미래를 창조하고 긍정적인 변화를 가져오는 것을 목표로 하고 있습니다. </p>
        <p className="text-white text-[20px] leading-[20px] font-light mt-2"> 고객의 성공이 우리의 성공입니다. </p>

    </div>
);



const ValueSection = () => (
    <>
        <div className="flex justify-center mt-8 mb-8">
            <img src={img2} alt="" className="w-[1196px] h-[400px] object-cover" />
        </div>
        <div className="max-w-[1196px] mx-auto">
            <div className="mb-8">
                <h2 className="text-[36px] text-black font-bold mb-4">
                    우리는 실력과 진정성을 핵심 가치로 삼아 AI 기술 혁신의 최전선에 있습니다.
                </h2>
                <p className="text-[20px] text-black font-lignt">
                    우리는 실력과 진정성을 핵심 가치로 삼아 AI 기술 혁신의 최전선에 서 있습니다.
                    고객의 요구를 충족시키기 위해 깊이 있는 전문성과 신뢰를 바탕으로 최적의 솔루션을 제공합니다.
                    실력 있는 전문가들이 모여 협력하며, 지속적인 연구 개발을 통해 혁신을 이루고, 사회에 긍정적인 영향을 미치는 기술을 창출하고 있습니다.
                    우리의 목표는 고객과 함께 성장하며, 진정한 가치를 제공하는 것입니다.
                </p>
            </div>
            <div className="border-b-4 border-black mb-8"></div>
            <div className="flex justify-between items-start mb-8">
                <div className="w-1/2">
                    <p className="text-[36px] text-black font-bold">
                        실력과 진정성을 핵심가치로 함께
                    </p>
                    <p className="text-[36px] text-black font-bold">
                        성장하고 혁신을 만들어갈
                    </p>
                    <p className="text-[36px] text-black font-bold">
                        인재를 찾고 있습니다.
                    </p>
                </div>
                <div className="w-1/2 text-right">
                    <p className="text-[20px] text-black font-lignt mt-4">다양한 배경과 전문성을 가진 열정적인 분들과 함께</p>
                    <p className="text-[20px] text-black font-lignt mt-2">AI기술의 미래를 만들어갈 기회</p>
                    <p className="text-[20px] text-black font-lignt mt-2">건강한 문화를 가진 스타트업에서 커리어 성장을 꿈꾸신다면</p>
                    <p className="text-[20px] text-black font-lignt mt-2">지금 바로 원데이원 커뮤니케이션에 지원하세요.</p>
                </div>
            </div>
        </div>
    </>
)

const ApplicationPeriod = () => (
    <div className="bg-black py-4 w-full" >
        <div className="text-left mx-auto" style={{maxWidth: '600px'}}>
            <p className="text-white text-base leading-tight mb-3">
                <span className="text-[16px] leading-[16px] font-bold mr-1 mb-1">지원 기간:</span>
                <span className="text-[16px] leading-[16px] font-Regular mr-1 mb-1">상시 모집 합니다.</span>
            </p>
            <p className="text-white text-base leading-tight mb-1">
                <span className="text-[16px] leading-[16px] font-bold mr-1 mb-1">지원 방법:</span>
                <span className="text-[16px] leading-[16px] font-Regular mr-1 mb-1">
          이력서 및 포트폴리오를 이메일 ASK@ODOC.CO.KR로 제출해 주세요
        </span>
            </p>
        </div>
    </div>
);


const JobListings = ({filteredJobs, startIndex, handlePrev, handleNext, handleApply}) => (
    <div className="max-w-5xl mx-auto">
        <h2 className="text-[36px] leading-[36px] text-center font-bold mb-12 mt-12 text-black  ">미래를 여는 ODOC 여정에 함께하세요</h2>
        <div className="relative">
            <div className="overflow-hidden">
                <div
                    className="transition-transform duration-500 ease-in-out flex gap-4 mb-16"
                    style={{transform: `translateX(-${startIndex * (100 / 3)}%)`}}
                >
                    {filteredJobs.map((job, index) => (
                        <div
                            key={index}
                            className="w-full bg-white p-4 shadow transition-all duration-300 hover:shadow-lg flex-shrink-0"
                            style={{flex: '0 0 calc((100% - 32px) / 3)'}}
                        >
                            <h4 className="text-black text-[20px] leading-[20px] font-bold mb-6 ml-3">{job.title}</h4>
                            <p className="text-black text-[20px] leading-[20px] font-light mb-4 ml-3">
                                <img src={alarms} alt="Alarm icon" className="inline-block mr-4" width={24} height={24}/>
                                {job.type}
                            </p>
                            <p className="text-black] text-[20px] leading-[20px] font-light mb-4 ml-3">
                                <img src={location} alt="location icon" className="inline-block mr-4" width={24} height={24}/>
                                {job.location}
                            </p>
                            <p className="text-black text-[20px] leading-[20px] font-light mb-4 ml-3">
                                <img src={group} alt="group icon" className="inline-block mr-4" width={24} height={24}/>
                                {job.person}
                            </p>
                            <div className="flex justify-center">

                            <button
                                onClick={() => handleApply(job)}
                                className="relative w-[170px] text-black text-[16px] leading-[24px] font-medium bg-[#F1F4F9] border-2 border-black px-4 py-2 rounded-full overflow-hidden group mx-auto mt-2"
                            >
                                <div
                                    className="absolute left-0 top-0 h-full w-0 bg-black transition-all duration-700 group-hover:w-full"></div>
                                <span
                                    className="relative z-10 transition-colors duration-700 group-hover:text-white ">세부정보</span>
                            </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <button
                className="absolute left-0 top-1/2 transform -translate-y-1/2 -ml-12 z-10 p-2 hover:bg-gray-100 rounded-full"
                onClick={handlePrev}
            >
                <ChevronLeft className="w-6 h-6 text-gray-600"/>
            </button>
            <button
                className="absolute right-0 top-1/2 transform -translate-y-1/2 -mr-12 z-10 p-2 hover:bg-gray-100 rounded-full"
                onClick={handleNext}
            >
                <ChevronRight className="w-6 h-6 text-gray-600"/>
            </button>
        </div>
    </div>
);




const JobModal = ({job, jobListings, onClose, onApply}) => {
    const [showApplicationForm, setShowApplicationForm] = useState(false);
    const [selectedJob, setSelectedJob] = useState(job);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const handleApplyClick = (selectedJob) => {
        setSelectedJob(selectedJob);
        setShowApplicationForm(true);
    };

    const handleJobSelect = (newJob) => {
        setSelectedJob(newJob);
    };

    const handleModalSuccess = () => {
        setShowApplicationForm(false);  // 먼저 폼 모달 닫기

        setTimeout(() => {
            setShowSuccessMessage(true);

            setTimeout(() => {
                setShowSuccessMessage(false);
            }, 3000);
        }, 100);
    };

    // const handleApplicationSubmit = async (formData) => {
    //     setShowApplicationForm(false);
    //     await onApply(selectedJob, formData);
    // };

    return (
        <>
            {showSuccessMessage && (
                <div className="fixed top-4 left-1/2 transform -translate-x-1/2 z-[100] bg-[#F0F2FD] text-black text-[16px] leading-[16px] font-bold px-6 py-2 rounded-full shadow-lg mt-40">
                    성공적으로 접수되었습니다!
                </div>
            )}

            <div className="fixed inset-0 z-50 flex items-start justify-center bg-black bg-opacity-50 pt-20">
                <div className="bg-[#F1F4F9] w-full rounded-t-3xl overflow-hidden shadow-xl relative">
                    <div className="bg-[#F1F4F9] p-4 mt-4 flex justify-between items-center">
                        <h1 className="text-black text-[36px] leading-[36px] font-bold">{selectedJob.title}</h1>
                        <div className="flex items-center space-x-4">
                            <button
                                className="relative overflow-hidden group px-4 py-2 rounded-full border border-black transition duration-300 bg-[#F1F4F9]"
                                onClick={() => handleApplyClick(selectedJob)}
                            >
                                <div className="absolute left-0 top-0 h-full w-0 bg-black  transition-all duration-700 group-hover:w-full"></div>
                                <span className="relative z-10 text-black group-hover:text-white">
                                    지금 신청하세요
                                </span>
                            </button>

                            <button
                                onClick={onClose}
                                className="bg-black text-white rounded-full w-8 h-8 flex items-center justify-center"
                            >
                                <X size={20}/>
                            </button>
                        </div>
                    </div>

                    <div className="flex h-[calc(100vh-160px)] overflow-hidden">
                        {/* 왼쪽 섹션 */}
                        <div className="bg-[#F1F4F9] w-[80%] p-6 overflow-y-auto">
                            <div className="flex space-x-4 mb-6">
                                <span className="text-black text-[16px] leading-[16px] font-medium px-3 py-1 bg-[#E1E2FF] rounded-full">
                                    {selectedJob.location}
                                </span>
                                <span className="text-black text-[16px] leading-[16px] font-medium px-3 py-1 bg-[#E1E2FF] rounded-full">
                                    {selectedJob.type}
                                </span>
                                <span className="text-black text-[16px] leading-[16px] font-medium px-3 py-1 bg-[#E1E2FF] rounded-full">
                                    {selectedJob.person}
                                </span>
                                <span className="text-black text-[16px] leading-[16px] font-medium px-3 py-1 bg-[#E1E2FF] rounded-full">
                                    {selectedJob.experience}
                                </span>
                            </div>

                            <div className="text-black text-[16px] leading-[16px] font-light mb-6">
                                <p>{selectedJob.description}</p>
                            </div>

                            <div className="mb-6">
                                <h3 className="text-black text-[20px] leading-[20px] font-bold mb-6">01. 주요업무:</h3>
                                <ul className="list-disc pl-5 text-black text-[20px] leading-[20px] font-light">
                                    {selectedJob.responsibilities.map((item, index) => (
                                        <li key={index} className="mb-2">{item}</li>
                                    ))}
                                </ul>
                            </div>

                            <div className="mb-6">
                                <h3 className="text-black text-[20px] leading-[20px] font-bold mb-6">02. 자격 요건:</h3>
                                <ul className="list-disc pl-5 text-black text-[20px] leading-[20px] font-light">
                                    {selectedJob.qualifications.map((item, index) => (
                                        <li key={index} className="mb-2">{item}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        {/* 오른쪽 섹션 */}
                        <div className="w-[20%] bg-[#F1F4F9] p-6 overflow-y-auto">
                            <div className="space-y-4">
                                {jobListings.map((listingJob, index) => (
                                    <div key={index} className="bg-white rounded-lg p-4 shadow">
                                        <h4 className="text-black  text-[24px] leading-[24px] font-bold mb-2">
                                            {listingJob.title}
                                        </h4>
                                        <p className="text-black  text-[20px] leading-[20px] font-light mb-2">
                                            {listingJob.type}
                                        </p>
                                        <p className="text-black  text-[20px] leading-[20px] font-light mb-2">
                                            {listingJob.location}
                                        </p>
                                        <p className="text-black  text-[20px] leading-[20px] font-light mb-3">
                                            {listingJob.person}
                                        </p>
                                        <button
                                            onClick={() => handleJobSelect(listingJob)}
                                            className="relative w-full bg-[#F1F4F9] text-black  border border-black px-4 py-2 rounded-full text-[20px] leading-[20px] font-medium overflow-hidden group"
                                        >
                                            <div className="absolute left-0 top-0 h-full w-0 bg-black transition-all duration-700 group-hover:w-full"></div>
                                            <span className="relative z-10 transition-colors duration-700 group-hover:text-white">
                                                직업 세부정보
                                            </span>
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                {/* 지원 폼 모달 */}
                {showApplicationForm && (
                    <div className="fixed inset-0 z-[60] flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
                        <div className="bg-white w-full max-w-[1200px] mx-4 rounded-lg overflow-hidden shadow-xl">
                            <div className="bg-white p-4 flex justify-between items-center">
                                <h2 className="text-2xl font-bold">{selectedJob.title}에 지원하기</h2>
                                <button
                                    onClick={() => setShowApplicationForm(false)}
                                    className="text-gray-500 hover:text-gray-700"
                                >
                                    <X size={24} />
                                </button>
                            </div>
                            <div className="p-6">
                                <EmailForm
                                    isModal={true}
                                    onModalSuccess={handleModalSuccess}
                                    className="max-w-[600px] mx-auto"
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

const Careers = () => {
    const [selectedJob, setSelectedJob] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [relatedJobs, setRelatedJobs] = useState([]);
    const [startIndex, setStartIndex] = useState(0);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const itemsPerPage = 3;

    const handleNext = () => {
        setStartIndex(prev =>
            prev + itemsPerPage >= filteredJobs.length ? 0 : prev + itemsPerPage
        );
    };

    const handlePrev = () => {
        setStartIndex(prev =>
            prev - itemsPerPage < 0 ? Math.max(0, filteredJobs.length - itemsPerPage) : prev - itemsPerPage
        );
    };

    const getRelatedJobs = (currentJob) => {
        return jobListings.filter(job =>
            job.experience === currentJob.experience && job.title !== currentJob.title
        ).slice(0, 3);
    };

    const handleApply = (job) => {
        setSelectedJob(job);
        setRelatedJobs(getRelatedJobs(job));
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedJob(null);
        setRelatedJobs([]);
    };

    const handleJobSubmit = async (job, formData) => {
        try {

            setShowModal(false);

            setShowSuccessMessage(true);

            setTimeout(() => {
                setShowSuccessMessage(false);
            }, 3000);
        } catch (error) {
            console.error('Application submission failed:', error);
        }
    };

    const filteredJobs = selectedCategory
        ? jobListings.filter(job => job.experience === selectedCategory)
        : jobListings;

    return (
        <div className="w-full bg-[#F1F4F9] relative">
            {showSuccessMessage && (
                <div className="fixed top-4 left-1/2 transform -translate-x-1/2 z-[70] bg-blue-100 text-[#2E3190] text-[16px] leading-[16px] font-bold px-6 py-2 rounded-full shadow-lg">
                    성공적으로 접수되었습니다!
                </div>
            )}

            <HeroSection />
            {/*<CompanyIntroText />*/}
            <CreativePartner />
            <ValueSection />
            <ApplicationPeriod />
            {/*<JobCategories*/}
            {/*    selectedCategory={selectedCategory}*/}
            {/*    setSelectedCategory={setSelectedCategory}*/}
            {/*/>*/}
            <JobListings
                filteredJobs={filteredJobs}
                startIndex={startIndex}
                handlePrev={handlePrev}
                handleNext={handleNext}
                handleApply={handleApply}
            />
            {/*<ApplicationSection />*/}

            {showModal && selectedJob && (
                <JobModal
                    job={selectedJob}
                    jobListings={jobListings}
                    onClose={handleCloseModal}
                    onApply={handleJobSubmit}
                />
            )}
        </div>
    );
};

export default Careers;