import React, { useState } from 'react';
import { ChevronUp, ChevronDown } from 'lucide-react';
import InquiryForm from "../components/InquiryForm";

import img1 from '../assets/images/Contact/img1.png';
import sub_01 from '../assets/images/Contact/sub_01.png';
import sub_02 from '../assets/images/Contact/sub_02.png';
import sub_03 from '../assets/images/Contact/sub_03.png';
import sub_04 from '../assets/images/Contact/sub_04.png';
import sub_05 from '../assets/images/Contact/sub_05.png';
import sub_06 from '../assets/images/Contact/sub_06.png';
import sub_07 from '../assets/images/Contact/sub_07.png';
import sub_background from '../assets/images/Contact/sub_background.png';
import location from '../assets/images/Contact/location_on_24px.png';
import phone from '../assets/images/Contact/local_phone_24px.png';
import email from '../assets/images/Contact/alternate_email_24px.png';
import star from '../assets/images/Contact/star_border_24px.png';



const HeroSection = () => {
    return (
        <div className="w-full">
            <div className="relative mx-auto" style={{height: '426px', width: 'auto'}}>
                <div
                    className="flex flex-col justify-center items-center text-white h-full"
                    style={{
                        backgroundImage: `url(${img1})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                    }}
                >
                    <h1 className="text-[64px] leading-[64px] font-bold mb-2">CONTACT US</h1>
                    <div className="h-7"/>
                    <p className="text-[20px] leading-[20px] font-light mb-2">맞춤형 컨설팅을 제공해 드립니다.</p>
                    <div className="h-7"/>
                </div>
                <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 text-center">
                    <div className="text-sm font-medium text-white mb-2">아래로 스크롤</div>
                    <div className="relative w-[6px] h-[9px] mx-auto mt-1">
                        <div className="w-[6px] h-[6px] bg-white rounded-full"></div>
                        <div className="w-[1px] h-[20px] bg-[rgba(255,255,255,0.6)] mx-auto"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};



const ContactTabs = ({ Email, FAQ, MapSection }) => {
    const [activeTab, setActiveTab] = useState('inquiry');

    return (
        <div className="w-full bg-[#F1F4F9]">
            <div className="flex justify-center gap-4 mb-8 mt-4">
                <button
                    onClick={() => setActiveTab('inquiry')}
                    className={`px-8 py-2 rounded-full font-medium transition-colors duration-300 ${
                        activeTab === 'inquiry'
                            ? 'bg-black text-white'
                            : 'bg-[#E1E2FF] text-black'
                    }`}
                >
                    문의하기
                </button>
                <button
                    onClick={() => setActiveTab('faq')}
                    className={`px-8 py-2 rounded-full font-medium transition-colors duration-300 ${
                        activeTab === 'faq'
                            ? 'bg-black text-white'
                            : 'bg-[#E1E2FF] text-black'
                    }`}
                >
                    FAQ
                </button>
            </div>

            <div className="w-full">
                {activeTab === 'inquiry' && (
                    <>
                        <Email/>
                        <MapSection/>
                    </>
                )}
                {activeTab === 'faq' && <FAQ/>}
            </div>
        </div>
    );
};

const MapSection = () => {
    return (
        <div className="w-full">
            <div className="mx-auto" style={{maxWidth: '1200px'}}>
                <div className="flex flex-col items-center py-16">
                    <h2 className="text-black text-[32px] leading-[32px] font-bold mb-4">오시는 길</h2>
                    <p className="text-black text-[20px] leading-[20px]  mb-16">(주)원데이원커뮤니케이션</p>

                    <div className="flex w-full gap-8 px-4">
                        {/* Contact Info Card */}
                        <div className="bg-white p-8  shadow-lg w-[564px] h-[336px]">
                            <div className="space-y-3">
                                <div className="flex items-center gap-3">
                                    <img src={location} alt="Location icon" className="w-8 h-8"/>
                                    <span className="text-black text-xl font-bold">|</span>
                                    <p className="text-black text-[19px] leading-[20px]">서울특별시 성동구 성수일로99, 서울숲AK밸리 8F #809</p>
                                </div>
                                <div className="flex items-center gap-3">
                                    <img src={star} alt="star icon" className="w-8 h-8"/>
                                    <span className="text-black text-xl font-bold">|</span>
                                    <p className="text-black text-[20px] leading-[20px] ">AI솔루션개발, 디지털전환교육(DX),
                                        데이터바우처 문의</p>
                                </div>
                                <div className="flex items-center gap-3">
                                    <img src={email} alt="email icon" className="w-8 h-8"/>
                                    <span className="text-black text-xl font-bold">|</span>
                                    <p className="text-black text-[20px] leading-[20px] ">ask@odoc.co.kr</p>
                                </div>
                                <div className="flex items-center gap-3">
                                    <img src={phone} alt="phone icon" className="w-8 h-8"/>
                                    <span className="text-black text-xl font-bold">|</span>
                                    <p className="text-black text-[20px] leading-[20px] ">070-4143-0617</p>
                                </div>
                            </div>

                            <div className="mt-6">
                                <p className="text-black text-[20px] leading-[20px] ">ODOC의 서비스에 관한 모든 문의를
                                    환영합니다!</p>
                                <p className="text-black text-[20px] leading-[20px] mt-4 ">전화 및 다음 문의양식으로 문의해
                                    주세요.</p>
                            </div>
                        </div>

                        {/* Google Map */}
                        <div className="flex-1">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3163.236851133976!2d127.04862477561691!3d37.54948317204302!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c98845c263d81%3A0x76f9c8987955f281!2zKOyjvCnsm5DrjbDsnbTsm5Dsu6TrrqTri4jsvIDsnbTshZg!5e0!3m2!1sko!2skr!4v1728627143509!5m2!1sko!2skr"
                                title="odoc map"
                                width="580"
                                height="336"
                                className="border-4 border-black"
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};



const FAQ = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const faqData = [
        {
            question: "AI서비스 개발을 시작하려면 어떤 준비가 필요한가요?",
            answer: "귀사에서 해결하려는 문제와 목표를 명확하게 정의하는 것이 중요합니다. 그 후 필요한 데이터와 현재 시스템 상태를 파악하여 AI모델이 어떻게 적용될수 있는지 검토합니다. 저희는 이 과정을 도와드리며, 필요한 리소스와 제안서를 작성해서 커뮤니케이션합니다."
        },
        {
            question: "AI 솔루션 개발에 얼마나 시간이 걸리나요?",
            answer: "개발 소요 시간은 프로젝트의 복잡성, 데이터의 양, 요구 사항에 따라 달라집니다. 간단한 솔루션은 몇 주 이내에 완료될 수 있지만, 복잡한 맞춤형 AI솔루션은 3~6개월 정도 걸립니다. 프로젝트 시작 시 인력투입 계획과 일정계획을 제시해 드립니다."
        },
        {
            question: "AI 서비스를 도입하면 우리 회사에 어떤 이점이 있나요?",
            answer: "AI는 데이터를 분석하고 패턴을 찾아 자동화된 의사결정을 지원하며 효율성을 높이고 비용을 절감할 수 있습니다. AI 서비스는 데이터 분석, 예측, 자동화, 개인화된 고객 경험 제공 비즈니스 전반의 효율성을 높이고 새로운 수익 기회를 창출합니다. AI 서비스를 도입하면 고객 경험을 개인화하고, 비즈니스 프로세스를 자동화하며, 데이터 기반의 혁신적인 의사 결정을 가능하게 함으로써 비즈니스 모델을 개선하고 매출을 증대할 수 있습니다. AI는 특히 고객의 요구에 맞춘 서비스 제공과 비용 절감 측면에서 비즈니스 성과를 극대화하는 강력한 도구입니다."
        },
        {
            question: "AI 서비스 개발에 필요한 데이터는 어떻게 준비하나요?",
            answer: "AI 서비스 개발을 위해서는 고품질의 데이터가 필수입니다. 회사에서 이미 보유한 데이터가 있다면 그 데이터를 활용할 수 있고, 부족한 경우 필요한 데이터를 수집하거나 정리하는 과정도 필요합니다. 저희는 데이터 준비 단계부터 함께 협력하여 최적의 데이터를 확보하도록 도와드립니다."
        },
        {
            question: "AI 모델이 잘 작동하는지 어떻게 알 수 있나요?",
            answer: "AI 모델의 성능은 다양한 평가 지표를 통해 측정할 수 있습니다. 정확도, 정밀도, 재현율과 같은 성능 지표를 통해 AI 모델이 목표에 맞게 작동하는지 확인하고, 필요에 따라 모델을 개선합니다. 저희는 정기적으로 모델의 성능을 모니터링하며 개선 방안을 제안합니다."
        },
        {
            question: "AI 솔루션은 우리 시스템과 통합될 수 있나요?",
            answer: "네, AI 솔루션은 기존 시스템과 원활하게 통합될 수 있습니다. 저희는 고객의 기술 인프라와 요구 사항에 맞게 솔루션을 커스터마이징하여 기존 소프트웨어나 플랫폼과 통합되도록 지원합니다."
        },
        {
            question: "AI 개발 비용은 어떻게 책정되나요?",
            answer: "AI 개발 비용은 프로젝트의 복잡성, 데이터 양, 개발 기간 등에 따라 다릅니다. 저희는 고객의 요구 사항을 평가한 후 합리적인 가격 견적을 제공하며, 프로젝트 시작 전에 상세한 비용 구조를 안내해 드립니다."
        },
        {
            question: "AI 모델이 업데이트가 필요한 경우 어떻게 처리되나요?",
            answer: "AI 모델은 시간이 지남에 따라 새로운 데이터나 변화된 비즈니스 요구 사항에 맞게 업데이트될 수 있습니다. 저희는 정기적인 모니터링과 업데이트 서비스를 제공하여 AI 모델이 항상 최적의 성능을 유지하도록 지원합니다."
        },
        {
            question: "AI가 우리 비즈니스에 적합한지 어떻게 알 수 있나요?",
            answer: "AI가 적합한지는 비즈니스 문제와 목표에 따라 다릅니다. AI가 데이터 기반 의사결정, 자동화, 예측 등에 유용하다면 적합할 가능성이 큽니다. 저희는 초기 컨설팅을 통해 귀사의 비즈니스에 AI가 어떻게 활용될 수 있는지 분석해 드립니다."
        },
        {
            question: "AI 서비스 개발을 위한 기술적 요구 사항은 무엇인가요?",
            answer: "AI 서비스 개발을 위해서는 데이터 저장 및 처리 인프라, 충분한 컴퓨팅 리소스, 그리고 AI 모델이 작동할 수 있는 환경이 필요합니다. 저희는 필요한 기술적 요구 사항을 평가하고 최적의 인프라를 구축할 수 있도록 지원합니다."
        },
        {
            question: "AI 서비스 개발 후 유지보수도 제공되나요?",
            answer: "네, 저희는 AI 서비스 개발 후에도 지속적인 유지보수 및 지원을 제공합니다. 모델의 성능을 정기적으로 평가하고, 새로운 요구 사항이나 변화에 맞게 조정하여 AI 서비스가 안정적으로 운영될 수 있도록 관리합니다."
        },
        {
            question: "AI 솔루션은 어떤 산업에 적용될 수 있나요?",
            answer: "AI 솔루션은 의료, 금융, 제조, 마케팅, 뷰티, 교육 등 다양한 산업에 적용될 수 있습니다. 각 산업의 특성에 맞춘 맞춤형 AI 솔루션을 제공하여 비즈니스 문제를 해결하고 성과를 극대화할 수 있습니다."
        }
    ];

    return (
        <div className="w-full max-w-3xl mx-auto p-4 mt-8 mb-8">
            <h2 className="text-black text-[32px] leading-[32px] font-bold mb-8 text-center">자주 묻는 질문 (FAQ)</h2>


            <div className="space-y-8">
                {faqData.map((item, index) => (
                    <div
                        key={index}
                        className="bg-white rounded-lg overflow-hidden border border-gray-100 transition-all duration-500 ease-in-out shadow-sm hover:shadow-md"
                    >
                        <button
                            className="w-full px-6 py-5 text-left flex justify-between items-center bg-white transition-colors duration-500 "
                            onClick={() => setOpenIndex(openIndex === index ? null : index)}
                        >
            <span className="text-black font-bold text-xl">
              Q: {item.question}
            </span>
                            <div className="transition-transform duration-500 ease-in-out">
                                {openIndex === index ? (
                                    <ChevronUp className="h-5 w-5 text-black"/>
                                ) : (
                                    <ChevronDown className="h-5 w-5 text-black"/>
                                )}
                            </div>
                        </button>
                        <div
                            className={`transition-all duration-500 ease-in-out ${
                                openIndex === index
                                    ? 'max-h-96 opacity-100'
                                    : 'max-h-0 opacity-0'
                            } overflow-hidden`}
                        >
                            <div className="px-6 py-5 bg-black">
                                <div className="flex">
                                    <div className="w-1 bg-white mr-4 rounded-full"/>
                                    <p className="text-white font-light text-base leading-relaxed">
                                        {item.answer}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};


const categories = [
    { name: '의료', image: sub_01 },
    { name: '식품', image: sub_02 },
    { name: '유통', image: sub_03 },
    { name: '마케팅', image: sub_04 },
    { name: '콘텐츠', image: sub_05 },
    { name: '예술', image: sub_06 },
    { name: '뷰티/헬스', image: sub_07 },
];


const SubHeroSection = () => {
    return (
        <div className="w-full relative mb-8">
            <div className="mx-auto" style={{height: '426px', width: 'auto'}}>
                <div
                    className="flex flex-col justify-start items-center h-full pt-16 relative"
                    style={{
                        backgroundImage: `url(${sub_background})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundBlendMode: 'overlay',
                    }}
                >
                    <div className="absolute inset-0 backdrop-blur-sm bg-black/30" />

                    <div className="relative z-10">
                        <h1 className="text-[32px] leading-[40px] font-bold text-white text-center mb-4">
                            AI 솔루션은 다양한 산업에 적용할 수 있습니다
                        </h1>
                        <p className="text-[16px] leading-[24px] text-white text-center font-light mb-16">
                            각 산업의 특성에 맞게 성공을 AI 솔루션을 적용하면서 비즈니스 프로세스 혁신과 성과창출의 리더가 될 수 있습니다.
                        </p>

                        <div className="flex justify-center gap-8 px-4">
                            {categories.map((category, index) => (
                                <div key={index} className="w-[100px] border border-white  overflow-hidden">
                                    <div className="aspect-square rounded overflow-hidden  relative">
                                        <img
                                            src={category.image}
                                            alt={category.name}
                                            className="w-full h-full object-cover blur-sm"
                                        />
                                        <p className="absolute inset-0 flex items-center justify-center text-white text-center text-sm">
                                            {category.name}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};



const Email = () => {
    return (
        <div className="max-w-[1196px] mx-auto my-16 flex flex-col">
            <h2 className="text-[32px] leading-[36px] font-bold text-black text-center mb-4">
                어떤 서비스를 원하시나요?
            </h2>

            <div className="flex flex-row bg-white shadow-lg overflow-hidden">
                {/* 이미지 영역 삭제 */}

                <div className="w-full bg-white"> {/* InquiryForm 영역을 다 차지하도록 설정 */}
                    <InquiryForm />
                </div>
            </div>
        </div>
    );
};



const ContactPage = () => {
    return (
        <div className="min-h-screen bg-[#F1F4F9] flex flex-col"> {/* 수정된 부분 */}
            <HeroSection/>
            <ContactTabs Email={Email} FAQ={FAQ} MapSection={MapSection} />
            {/*<RollingBanner/>*/}
            <SubHeroSection />
        </div>
    );
};

export default ContactPage;