import React from 'react';

const Card = ({ image, title, content }) => {
  return (
    <div className="relative w-[375px] h-[477px] overflow-hidden group">
      <img
        src={image}
        alt={title}
        className="w-full h-full object-cover transition-transform duration-500 ease-in-out group-hover:scale-125"
      />

      <div
        className="absolute bottom-0 w-full bg-black opacity-0 group-hover:opacity-100 transition-opacity duration-500 ease-in-out"
        style={{ height: '37%' }}
      ></div>

      <div className="absolute inset-0 flex flex-col justify-end pl-8 pb-8">
        <h3 className="text-white text-2xl drop-shadow-md mb-4 font-semibold">{title}</h3>
        {content.map((line, index) => (
          <p key={index} className="text-white text-2xl drop-shadow-md tracking-wider font-semibold">
            {line}
          </p>
        ))}
      </div>
    </div>
  );
};

export default Card;
