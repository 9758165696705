import React from 'react';
import { bulletPointConditions, events } from '../constants/timelineData';

const TimelineEvent = ({ startDate, endDate, title, description, index }) => {
  return (
    <div className="flex mb-12 relative" style={{ marginTop: index + 1 === 1 ? '5.5rem' : 'none' }}>
      {/* 날짜 부분 */}
      <div className="w-[16.67%] text-black text-right pr-2 font-light" style={{ marginTop: '2.2rem' }}>
        <div className="flex flex-col items-center">
          <span className="text-[28px] leading-[52px]">{startDate}</span>
          {endDate && <span className="text-[28px] leading-[52px]">{endDate}</span>}
        </div>
      </div>

      {/* 타임라인 */}
      <div className="w-[7.33%] relative">
        {/* 타임라인 숫자 */}
        <div
          className="text-black text-[72px] absolute left-1/2 transform -translate-x-1/2"
          style={{ fontWeight: 700, top: '-3.5rem' }}
        >
          {`0${index + 1}`}
        </div>

        {/* 타임라인 선 (마지막 항목까지) */}
        <div
          className={`w-1.5 bg-black absolute left-1/2 top-[calc(27px+6px)] transform -translate-x-1/2 bottom-[0rem] mt-4
          }`}
        ></div>
      </div>

      {/* 설명 부분 */}
      <div className="w-[75%] text-left pl-16 pt-8">
        <ul className="list-disc pl-0">
          <li
            className="text-black text-[22px] mb-2 leading-[52px] list-none ml-[-30px]"
            style={{ fontWeight: 700 }}
          >
            {title}
          </li>
          {description.map((desc, idx) => {
            const hasBulletPoint = bulletPointConditions[desc] ?? true;
            return hasBulletPoint ? (
              <li key={idx} className="text-black text-[17px] leading-[28px]" style={{ fontWeight: 200 }}>
                {desc}
              </li>
            ) : (
              <div key={idx} className="text-black text-[17px] leading-[28px]" style={{ fontWeight: 200 }}>
                {desc}
              </div>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

const Timeline = () => {
  return (
    <div className="col-span-12">
      {events.map((event, index) => (
        <TimelineEvent
          key={index}
          index={index}
          startDate={event.startDate}
          endDate={event.endDate}
          title={event.title}
          description={event.description}
        />
      ))}
    </div>
  );
};
export default Timeline;
