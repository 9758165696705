import React from 'react';
import { useSearchParams } from 'react-router-dom';

import background_1 from "../assets/images/SolutionLounge/background_1.png";
import background_2 from "../assets/images/SolutionLounge/background_2.png";
import background_3 from "../assets/images/SolutionLounge/background_3.png";
import background_4 from "../assets/images/SolutionLounge/background_4.png";
import art from "../assets/images/SolutionLounge/art.png";
import marketing from "../assets/images/SolutionLounge/marketing.png";
import circulation from "../assets/images/SolutionLounge/circulation.png";
import medical from "../assets/images/SolutionLounge/medical.png";
import art_logo from "../assets/images/SolutionLounge/art_logo.png";
import marketing_logo from "../assets/images/SolutionLounge/marketing_logo.png";
import circulation_logo from "../assets/images/SolutionLounge/circulation_logo.png";
import medical_logo from "../assets/images/SolutionLounge/medical_logo.png";


const HeroSection = () => {
    const scrollToSuccessCases = () => {
        const element = document.getElementById('success-cases');
        if (element) {
            const headerOffset = 70;
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };

    return (
        <div className="w-full flex justify-center mb-4">
            <div className="relative w-full max-w-auto">
                <div
                    className="flex flex-col text-white h-[558px]"
                    style={{
                        backgroundImage: `url(${background_1})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                    }}
                >
                    <div className="relative h-full flex flex-col items-start justify-center px-16 text-white">
                        <h1 className="text-[80px] leading-[80px] font-bold mb-4">솔루션 라운지</h1>
                        <p className="text-[24px] leading-[24px] font-light mb-12">다양한 산업에서 검증된 AI 솔루션 성공 사례를 만나보세요</p>
                        <div className="w-[442px] p-8 bg-black/50 rounded border border-white">
                            <p className="text-[16px] leading-[24px] font-light mb-6">
                                솔루션 라운지는 다양한 비즈니스 과제를 해결하기 위해 혁신적인 아이디어와
                                맞춤형 솔루션을 제공합니다. 기업의 성장과 성과 향상을 목표로 지원합니다
                            </p>
                            <button
                                onClick={scrollToSuccessCases}
                                className="group mt-6 inline-flex items-center gap-2 text-lg text-white transition-colors bg-transparent border border-white rounded-full px-6 py-2 relative overflow-hidden"
                            >
                                <span className="relative z-10">자세히 보기</span>
                                <div
                                    className="absolute left-0 top-0 h-full w-0 bg-[#2E3190] transition-all duration-700 group-hover:w-full"></div>
                            </button>

                            <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 text-center">
                                <div className="text-sm font-medium text-white mb-2">아래로 스크롤</div>
                                <div className="relative w-[6px] h-[9px] mx-auto mt-1">
                                    <div className="w-[6px] h-[6px] bg-white rounded-full"></div>
                                    <div className="w-[1px] h-[20px] bg-[rgba(255,255,255,0.6)] mx-auto"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const SubHeroSection = () => {
    return (
        <div id="success-cases" className="flex justify-center mb-8"> {/* id 추가 */}
            <div className="relative w-full max-w-auto">
                <h3 className="text-[36px] leading-[36px] font-bold text-black flex justify-center mt-8 mb-8">SUCCESS STORY</h3>
                <div
                    className="relative h-[542px]"
                    style={{
                        backgroundImage: `url(${background_2})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                >
                    {/* Dark overlay with blur */}
                    <div className="absolute inset-0 bg-black/30 backdrop-blur-[64px]"/>

                    {/* Content */}
                    <div
                        className="relative z-10 flex flex-col items-center justify-center pt-12 pb-24 px-16 text-white h-full"> {/* h-full 추가 */}
                        <div className="text-center"> {/* text-center 추가 */}
                            <h2 className="text-white text-[64px] leading-[64px] font-bold mt-10 mb-4">
                                AI 솔루션 성공 사례 탐색
                            </h2>
                            <p className="text-white text-[24px] leading-[24px] font-light mt-8">
                                다양한 산업 분야에서 검증된 AI 솔루션의 성공 사례를</p>
                            <p className="text-white text-[24px] leading-[24px] font-light mt-4">
                                확인해보세요
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};


const ArtContent = () => {
    return (
        <div
            className="relative w-full h-[1071px] flex items-center justify-center"
            style={{
                backgroundImage: `url(${background_3})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}
        >
            <div className="absolute inset-0 bg-black/30"/>

            <div className="relative z-10 w-[1080px] h-[980px] content-center py-8">
                <div className="flex gap-[40px] mb-[40px]">
                    <div className="w-[228px] h-[200px] border border-white p-6 bg-[#F1F4F9]">
                        <div className="flex flex-col items-center justify-center h-full">
                            <p className="text-black text-[27px] leading-[20px] font-medium mt-6">UNC 갤러리</p>
                            <div className="p-4 mb-2">
                                <img
                                    src={art_logo}
                                    alt="AI 기반 성과형 콘텐츠"
                                    className="w-[180px] h-[108px] object-contain"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="w-[386px] h-[200px] border border-white p-6 bg-[#F1F4F9]">
                        <h3 className="text-[27px] leading-[20px] font-medium text-black flex justify-center mb-8">도입 솔루션</h3>
                        <p className="text-[20px] leading-[30px] font-light text-black flex flex-col items-center mt-6 ml-2 mr-2">
                            <span>작가 이미지 기반 AI 학습 모델 개발</span>
                            <span>초상화 작품 데이터 셋 구축 </span>
                            <span>실시간 관람객 얼굴 변환 기술</span>
                        </p>
                    </div>

                    <div className="w-[386px] h-[200px] border border-white p-6 bg-[#F1F4F9]">
                        <h3 className="text-[27px] leading-[20px] font-medium text-black flex justify-center mb-8">프로젝트 성과</h3>
                        <p className="text-[19px] leading-[30px] font-light text-black flex flex-col items-center mt-6 ml-2 mr-2">
                            <span>크리스토퍼 루크헤베를레 전시 성공적 운영</span>
                            <span>관람객 참여형 AI 체험 제공</span>
                            <span>2023.11.29 ~ 2024.03.03 전시 운영</span>
                        </p>
                    </div>
                </div>

                <div className="w-[1080px] h-[673px] border border-white p-6 bg-[#F1F4F9]">
                    <h3 className="text-[20px] leading-[20px] font-medium text-black">AI 기반 성과형 콘텐츠 구별 개발</h3>
                    <div className="flex justify-center mt-2">
                        <img
                            src={art}
                            alt="AI 기반 성과형 콘텐츠"
                            className="w-[1032px] h-[600px] object-contain"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const MarketingContent = () => {
    return (
        <div
            className="relative w-full h-[1071px] flex items-center justify-center"
            style={{
                backgroundImage: `url(${background_3})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}
        >
            {/* 반투명 오버레이 */}
            <div className="absolute inset-0 bg-black/30"/>

            {/* 콘텐츠 영역 */}
            <div className="relative z-10 w-[1080px] h-[980px] content-center py-8">
                <div className="flex gap-[40px] mb-[40px]">
                    <div className="w-[228px] h-[200px] border border-white p-6 bg-[#F1F4F9]">
                        <div className="flex flex-col items-center justify-center h-full">
                            <p className="text-black text-[27px] leading-[20px] font-medium mt-6">퍼즐 코퍼레이션</p>
                            <div className="p-4 mb-2">
                                <img
                                    src={marketing_logo}
                                    alt="퍼즐 코퍼레이션"
                                    className="w-[180px] h-[108px] object-contain"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="w-[386px] h-[200px] border border-white p-6 bg-[#F1F4F9]">
                        <h3 className="text-[27px] leading-[20px] font-medium text-black flex justify-center mb-8">도입 솔루션</h3>
                        <p className="text-[20px] leading-[30px] font-light text-black flex flex-col items-center mt-6 ml-2 mr-2">
                            <span>Rule-based AI 분석 시스템 개발</span>
                            <span>인플루언서 콘텐츠 성과 예측 모델</span>
                            <span>자동화된 성과 분석 시스템</span>
                        </p>
                    </div>

                    <div className="w-[386px] h-[200px] border border-white p-6 bg-[#F1F4F9]">
                        <h3 className="text-[27px] leading-[20px] font-medium text-black flex justify-center mb-8">프로젝트 성과</h3>
                        <p className="text-[19px] leading-[30px] font-light text-black flex flex-col items-center mt-6 ml-2 mr-2">
                            <span>콘텐츠 성과 분석 자동화 달성</span>
                            <span>정확한 성과형 콘텐츠 구별 시스템 구축</span>
                        </p>
                    </div>
                </div>

                <div className="w-[1080px] h-[673px] border border-white p-6 bg-[#F1F4F9]">
                    <h3 className="text-[20px] leading-[20px] font-medium text-black">AI 기반 성과형 콘텐츠 구별 개발</h3>
                    <div className="flex justify-center mt-2">
                        <img
                            src={marketing}
                            alt="AI 기반 성과형 콘텐츠"
                            className="w-[1032px] h-[600px] object-contain"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const CirculationContent = () => {
    return (
        <div
            className="relative w-full h-[1071px] flex items-center justify-center"
            style={{
                backgroundImage: `url(${background_3})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}
        >
            <div className="absolute inset-0 bg-black/30"/>

            <div className="relative z-10 w-[1080px] h-[980px] content-center py-8">
                <div className="flex gap-[40px] mb-[40px]">
                    <div className="w-[228px] h-[200px] border border-white p-6 bg-[#F1F4F9]">
                        <div className="flex flex-col items-center justify-center h-full">
                            <p className="text-black text-[20px] leading-[20px] font-medium mt-6">제이크이엔엠 (치믹스)</p>
                            <div className="p-4 mb-2">
                                <img
                                    src={circulation_logo}
                                    alt="제이크이엔엠"
                                    className="w-[180px] h-[108px] object-contain"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="w-[386px] h-[200px] border border-white p-6 bg-[#F1F4F9]">
                        <h3 className="text-[27px] leading-[20px] font-medium text-black flex justify-center mb-8">도입 솔루션</h3>
                        <p className="text-[20px] leading-[30px] font-light text-black flex flex-col items-center mt-6 ml-2 mr-2">
                            <span>화장품 도메인 데이터 가공 기술</span>
                            <span>AI 추천 알고리즘 개발</span>
                            <span>맞춤형 상품 추천 시스템</span>
                        </p>
                    </div>

                    <div className="w-[386px] h-[200px] border border-white p-6 bg-[#F1F4F9]">
                        <h3 className="text-[27px] leading-[20px] font-medium text-black flex justify-center mb-8">프로젝트 성과</h3>
                        <p className="text-[19px] leading-[30px] font-light text-black flex flex-col items-center mt-6 ml-2 mr-2">
                            <span>95% 이상의 추천 정확도 달성</span>
                            <span>개발 기간 단축</span>
                            <span>추천 성능 고도화 실현</span>
                        </p>
                    </div>
                </div>

                <div className="w-[1080px] h-[673px] border border-white p-6 bg-[#F1F4F9]">
                    <h3 className="text-[20px] leading-[20px] font-medium text-black">AI 기반 성과형 콘텐츠 구별 개발</h3>
                    <div className="flex justify-center mt-4">
                        <img
                            src={circulation}
                            alt="AI 기반 성과형 콘텐츠"
                            className="w-[1032px] h-[600px] object-contain"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const MedicalContent = () => {
    return (
        <div
            className="relative w-full h-[1071px] flex items-center justify-center"
            style={{
                backgroundImage: `url(${background_3})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}
        >
            <div className="absolute inset-0 bg-black/30"/>

            <div className="relative z-10 w-[1080px] h-[980px] content-center py-8">
                <div className="flex gap-[40px] mb-[40px]">
                    <div className="w-[228px] h-[200px] border border-white p-6 bg-[#F1F4F9]">
                        <div className="flex flex-col items-center justify-center h-full">
                            <p className="text-black text-[27px] leading-[20px] font-medium mt-6">3D 메디비젼</p>
                            <div className="p-4 mb-2">
                                <img
                                    src={medical_logo}
                                    alt="AI 기반 성과형 콘텐츠"
                                    className="w-[180px] h-[108px] object-contain"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="w-[386px] h-[200px] border border-white p-6 bg-[#F1F4F9]">
                        <h3 className="text-[27px] leading-[20px] font-medium text-black flex justify-center mb-8">도입 솔루션</h3>
                        <p className="text-[20px] leading-[30px] font-light text-black flex flex-col items-center mt-6 ml-2 mr-2">
                            <span>의료 전문 용어 AI 번역 시스템</span>
                            <span>수술 영상 자막 자동화</span>
                            <span>글로벌 서비스 지원 시스템</span>
                        </p>
                    </div>

                    <div className="w-[386px] h-[200px] border border-white p-6 bg-[#F1F4F9]">
                        <h3 className="text-[27px] leading-[20px] font-medium text-black flex justify-center mb-8">프로젝트 성과</h3>
                        <p className="text-[19px] leading-[30px] font-light text-black flex flex-col items-center mt-6 ml-2 mr-2">
                            <span>글로벌 진출 기반 마련</span>
                            <span>수술 교육 콘텐츠 다국어 지원</span>
                            <span>자동 번역 시스템 구축</span>
                        </p>
                    </div>
                </div>

                <div className="w-[1080px] h-[673px] border border-white p-6 bg-[#F1F4F9]">
                    <h3 className="text-[20px] leading-[20px] font-medium text-black">AI 기반 성과형 콘텐츠 구별 개발</h3>
                    <div className="flex justify-center mt-2">
                        <img
                            src={medical}
                            alt="AI 기반 성과형 콘텐츠"
                            className="w-[1032px] h-[600px] object-contain"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};


const TabSection = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const currentTab = searchParams.get('tab') || 'art';

    const tabs = [
        {
            id: 'art',
            label: '아트',
            hashtags: ['#이미지 학습 모델', '#작품 데이터 셋 구축', '#실시간 얼굴 변환 기술', '#콘텐츠 생성', '#AI 체험 전시']
        },
        {
            id: 'marketing',
            label: '마케팅',
            hashtags: ['#Rule-based', '#AI 분석 시스템', '#인플루언서 콘텐츠', '#성과 예측 모델', '#자동화']
        },
        {
            id: 'distribution',
            label: '유통',
            hashtags: ['#화장품', '#도메인 데이터 가공 기술', '#AI 추천 알고리즘 개발', '#맞춤형 상품 추진', '#추천 정확도 95% 이상']
        },
        {
            id: 'medical',
            label: '의료',
            hashtags: ['#전문 용어', '#번역', '#다국어 지원', '#자동 번역 시스템', '#글로벌 서비스 지원']
        },
    ];

    const currentHashtags = tabs.find(tab => tab.id === currentTab)?.hashtags || [];

    return (
        <div className="w-full bg-[#F1F4F9]">
            <div className="w-full flex justify-center space-x-6 px-4">
                {tabs.map((tab) => (
                    <button
                        key={tab.id}
                        onClick={() => setSearchParams({ tab: tab.id })}
                        className={`px-8 py-3  rounded-[100px] text-[20px] leading-[24px] font-medium ${
                            currentTab === tab.id
                                ? 'bg-black text-white'
                                : 'bg-[#E1E2FF] text-[#2E3190]'
                        }`}
                    >
                        {tab.label}
                    </button>
                ))}
            </div>

            <div className="w-full bg-[#E1E2FF] mt-4">
                <div className="max-w-7xl mx-auto flex  justify-center space-x-4 px-4 py-3">
                    {currentHashtags.map((hashtag, index) => (
                        <button
                            key={index}
                            className="text-black font-bold text-[20px] leading-[24px] hover:text-black px-3 py-1"
                        >
                            {hashtag}
                        </button>
                    ))}
                </div>
            </div>

            <div className="w-full h-[1080px] flex justify-center">
                <div className="w-full">
                    {currentTab === 'art' && <ArtContent />}
                    {currentTab === 'marketing' && <MarketingContent />}
                    {currentTab === 'distribution' && <CirculationContent />}
                    {currentTab === 'medical' && <MedicalContent />}
                </div>
            </div>
        </div>
    );
};


const LastHeroSection = () => {
    return (
        <div className="w-full flex justify-center mt-8 mb-8">
            <div className="relative w-full max-w-auto">
                <div
                    className="flex flex-col text-white h-[558px]"
                    style={{
                        backgroundImage: `url(${background_4})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                    }}
                >
                    {/* Dark overlay */}
                    <div className="absolute inset-0 bg-black/30 backdrop-blur-[64px]"/>

                    <div className="relative h-full flex flex-col items-center justify-center text-white">
                        <h1 className="text-[64px] leading-[64px] font-bold mb-6">원데이원과 미래를 함께할 파트너</h1>
                        <p className="text-[24px] leading-[24px] font-light mb-8">문의사항을 남겨주시면 확인 후 연락드리겠습니다.</p>
                        <a
                            href="/contact"
                            className="group relative px-[32px] py-[12px] overflow-hidden border border-white text-white text-[16px] leading-[24px] rounded-[100px]"
                        >
                            <span className="relative z-10 text-[20px] leading-[24px] font-medium group-hover:text-[#2E3190] transition-colors">문의하기</span>
                            <div className="absolute left-0 top-0 h-full w-0 bg-white transition-all duration-700 group-hover:w-full"></div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

const SolutionLoungePage = () => {
    return (
        <div className="min-h-screen bg-[#F1F4F9]">
            <HeroSection/>
            <SubHeroSection/>
            <TabSection/>
            <LastHeroSection/>
        </div>
    );
};

export default SolutionLoungePage;