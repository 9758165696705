import {
  aisystem_3_1,
  aisystem_3_2,
  aisystem_3_3,
  aisystem_3_4,
  aisystem_3_5,
  aisystem_3_6,
  aisystem_3_7,
  aisystem_3_8,
  aisystem_3_9,
  aisystem_3_10,
  center_focus_weak_24px,
  create_24px,
  markunread_24px,
  paste_24px,
  perm_identity_24px,
  phonelink_24px,
  poll_24px,
  priority_high_24px,
  show_chart_24px,
  wb_incandescent_24px,
  aisystem_1_0,
  aisystem_1_1,
  aisystem_1_2,
  aisystem_2_0,
  aisystem_2_1,
  aisystem__3_0,
  aisystem__3_1,
  aisystem_4_0,
  aisystem_4_1,
  aisystem_4_2,
  aisystem_5_0,
  aisystem_5_1,
  aisystem_6_0,
  aisystem_6_1,
  aisystem_6_2,
  aisystem_7_0,
  aisystem_7_1,
  aisystem_8_0,
  aisystem_8_1,
  aisystem_9_0,
  aisystem_9_1,
} from '../assets/images/Images';

export const items_3 = [
  { title1: '인터뷰 질문 생성하기', icon: create_24px, image: aisystem_3_1 },
  { title1: '고객 문의 자동 응답 만들기', icon: poll_24px, image: aisystem_3_2 },
  { title1: '상황에 맞는 격식 표현', title2: '제안하기', icon: priority_high_24px, image: aisystem_3_3 },
  { title1: '고객 페르소나', title2: '프로필 작성하기', icon: perm_identity_24px, image: aisystem_3_4 },
  { title1: 'SNS 마케팅문구 & 해시태그 생성하기', icon: phonelink_24px, image: aisystem_3_5 },
  { title1: '서비스 SWOT 분석하기', icon: show_chart_24px, image: aisystem_3_6 },
  { title1: '영상 기획하기', icon: center_focus_weak_24px, image: aisystem_3_7 },
  { title1: '파워포인트', title2: '자료 구성하기', icon: wb_incandescent_24px, image: aisystem_3_8 },
  { title1: '마케팅 카피', title2: '작성하기', icon: paste_24px, image: aisystem_3_9 },
  { title1: '영문 비즈니스', title2: '이메일 작성하기', icon: markunread_24px, image: aisystem_3_10 },
];

export const boxData = [
  {
    id: 1,
    tag: '의료',
    details: '건강과 삶을 지키는 의료 서비스',
    image: aisystem_1_0,
    products: [
      {
        title: '[베타플릭스] 수의학 수술자막 AI요약 서비스',
        partner: '쓰리디메디비젼',
        image: aisystem_1_1,
        width: '342px',
      },
      {
        title: '[U&H] 국내 4대 만성질환 유형화 분류모델 개발',
        partner: '제이썬 바이오테크',
        image: aisystem_1_2,
        width: '450px',
      },
    ],
  },
  {
    id: 2,
    tag: '식품',
    details: '안전하고 신선한 식품 제공',
    image: aisystem_2_0,
    products: [
      {
        title: '[베타플릭스] 수의학 수술자막 AI요약 서비스',
        partner: '비피세븐',
        image: aisystem_2_1,
        width: '342px',
      },
    ],
  },
  {
    id: 3,
    tag: '유통',
    details: '빠르고 효율적인 유통 지원',
    image: aisystem__3_0,
    products: [
      {
        title: '[치빅스] 맞춤형 기초 화장품 추천 서비스 개발',
        partner: '제이크 이엔엠',
        image: aisystem__3_1,
        width: '450px',
      },
    ],
  },
  {
    id: 4,
    tag: '마케팅',
    details: '효과적인 마케팅을 제공합니다',
    image: aisystem_4_0,
    products: [
      {
        title: '[레뷰] 인플루언서를 활용한 성과형/팔리는 콘텐츠 제작 솔루션',
        partner: '퍼즐 코퍼레이션',
        image: aisystem_4_1,
        width: '390px',
      },
      {
        title: '[A-BRIDGE] 온라인 마케팅 시뮬레이션 솔루션',
        partner: '아티언스',
        image: aisystem_4_2,
        width: '348px',
      },
    ],
  },
  {
    id: 5,
    tag: '콘텐츠',
    details: '창의적인 콘텐츠를 제공합니다',
    image: aisystem_5_0,
    products: [
      {
        title: '[웨메이크페인팅] 페인팅 게임의 콘텐츠생산성 향상을 위한 사물인식 경계선 검출 AI가공 솔루션 개발',
        partner: '인터내셔널 - KBG',
        image: aisystem_5_1,
        width: '597px',
      },
    ],
  },
  {
    id: 6,
    tag: '예술',
    details: '창의적인 예술을 선보입니다',
    image: aisystem_6_0,
    products: [
      {
        title: '[AI아티스트] 그림깨우기-크리스토퍼 루크헤베르레 전시회 AI 아티스트 개발',
        partner: '갤러리 - UNC',
        image: aisystem_6_1,
        width: '466px',
      },
      { title: '전시회', partner: '갤러리 - UNC', image: aisystem_6_2, width: '240px' },
    ],
  },
  {
    id: 7,
    tag: '뷰티/헬스',
    details: '아름다움과 건강을 제공합니다',
    image: aisystem_7_0,
    products: [
      {
        title: '[키인] 개인화 맞춤형 기초화장품 추천 플랫폼 런칭',
        partner: '자체개발',
        image: aisystem_7_1,
        width: '526px',
      },
    ],
  },
  {
    id: 8,
    tag: '교육',
    details: '미래를 위한 교육 제공',
    image: aisystem_8_0,
    products: [
      {
        title: '[아띠] 작업치료사의 교육과 관리를 위한 하이브리드 챗봇 개발',
        partner: '대한작업 치료사협회',
        image: aisystem_8_1,
        width: '388px',
      },
    ],
  },
  {
    id: 9,
    tag: '뷰티/헬스',
    details: '아름다움과 건강을 제공합니다',
    image: aisystem_9_0,
    products: [
      {
        title: '[스윗] 뷰티 도메인에 특화된 하이브리드 챗봇 개발',
        partner: '스윗솔루션',
        image: aisystem_9_1,
        width: '526px',
      },
    ],
  },
];
