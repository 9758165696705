import React from 'react';
import { useNavigate } from 'react-router-dom';

const ServiceCard = ({ backgroundImage, title, description, link }) => {
  const navigate = useNavigate();

  const getBackdropBlurStyle = () => {
    return {
      content: '""',
      position: 'absolute',
      inset: 0,
      backdropFilter: 'blur(7px)',
      zIndex: 1,
    };
  };

  return (
    <div
      className="relative w-[269px] h-[280px] bg-transparent flex items-center justify-center cursor-pointer overflow-hidden group"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
      }}
      onClick={() => {
        navigate(link);
        window.scrollTo(0, 0);
      }}
    >
      <div style={getBackdropBlurStyle()} />
      <span className="text-white text-[40px] font-bold" style={{ zIndex: 1 }}>
        {title}
      </span>
      <div className="flex flex-col absolute z-10 inset-0 bg-black translate-x-[-100%] transition-transform duration-500 group-hover:translate-x-0 flex items-center justify-center">
        <span className="text-white text-[20px] font-semibold mb-2">{title}</span>
        {description.map((line, index) => (
          <span key={index} className="text-white text-[15px] font-thin">
            {line}
          </span>
        ))}
        <span className="text-white text-[17px] font-semibold mt-8 underline">자세히 보기</span>
      </div>
    </div>
  );
};

export default ServiceCard;
