import React from 'react';
import { useNavigate } from 'react-router-dom';
import { mainBanner, newsBanner, main_1_1, main_1_2, main_1_3, mainChart, partnersList } from '../assets/images/Images';
import TextSliding from '../components/TextSliding';
import { newsData } from '../constants/newsData';
import FeatureCard1 from '../components/Main/FeatureCard';
import SubNewsCard from '../components/Main/SubNewsCard';
import ServiceCard from '../components/Main/ServiceCard';
import TagButton from '../components/TagButton';
import BannerWithOverlay from '../components/BannerWithOverlay';
import { serviceCards } from '../constants/serviceCardData';

const MainPage = () => {
  const navigate = useNavigate();

  const news_tags = ['#기술', '#혁신', '#AI 서비스', '#솔루션라운지', '#교육 서비스'];

  return (
    <div className="w-full mx-auto bg-[#F1F4F9]">
      {/* 배경 이미지와 스크롤 아래로 안내 */}
      <div
        className="relative w-full h-[1024px] mb-12"
        style={{
          aspectRatio: '1440 / 1024',
          backgroundImage: `url(${mainBanner})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        {/*/!* 서비스 문의하기 버튼 *!/*/}
        {/*<div className="absolute flex flex-col items-center justify-center inset-0">*/}
        {/*  <div className="relative w-[154px] h-[48px] mt-60 overflow-hidden rounded-full group">*/}
        {/*    <div*/}
        {/*      className="absolute w-full h-full bg-[rgba(1,1,1,0.24)] border-[1.5px] border-[#EBEBEB] rounded-full"*/}
        {/*      style={{ boxSizing: 'border-box' }}*/}
        {/*    ></div>*/}
        {/*    <div*/}
        {/*      className="absolute w-0 h-[48px] left-0 top-0 bg-white rounded-full transition-all duration-500 group-hover:w-full"*/}
        {/*      style={{ zIndex: 1 }}*/}
        {/*    ></div>*/}
        {/*    <button*/}
        {/*      onClick={() => {*/}
        {/*        navigate('/contact');*/}
        {/*        window.scrollTo(0, 0);*/}
        {/*      }}*/}
        {/*      className="absolute w-[154px] h-[48px] left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center text-center bg-transparent text-white font-semibold rounded-full z-10 group-hover:text-[#2E3190]"*/}
        {/*    >*/}
        {/*      서비스 문의하기*/}
        {/*    </button>*/}
        {/*  </div>*/}
        {/*</div>*/}

        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 text-center">
          <div className="text-sm font-medium text-white mb-2">아래로 스크롤</div>
          <div className="relative w-[6px] h-[9px] mx-auto mt-1">
            <div className="w-[6px] h-[6px] bg-white rounded-full"></div>
            <div className="w-[1px] h-[20px] bg-[rgba(255,255,255,0.6)] mx-auto"></div>
          </div>
        </div>
      </div>

      {/* 배너 파트 */}
      <div className="max-w-[1200px] w-[1180px] mx-auto mt-4">
        <BannerWithOverlay
          backgroundImage={newsBanner}
          title="창의적인 AI로 산업을 혁신합니다"
          subtitle="모두가 행복한 개발을 목표로 하는 글로벌 No.1 크리에이티브 파트너"
        />
      </div>

      <TextSliding
        text={'AI 기술을 활용하여 기업 맞춤형 솔루션, 초개인화 서비스, 데이터 기반 의사결정을 지원합니다'}
        position={1500}
      />

      {/* 본내용 컴포넌트 */}
      <div className="max-w-[1200px] w-[1180px] mx-auto mt-32">
        {/* ODOC 로고 및 자세히 보기 */}
        <div className="relative w-full h-auto flex flex-row justify-between items-center left-1/2 transform -translate-x-1/2">
          <div className="relative flex flex-col items-start w-[252px]">
            <div className="absolute flex flex-row justify-center items-center left-1/2 transform -translate-x-1/2 overflow-hidden mt-4">
              <h1
                className="text-black text-[80px] font-bold uppercase text-center"
                style={{ clipPath: 'inset(0 0 43% 0)' }}
              >
                odoc
              </h1>
            </div>
            <div
              className="absolute flex flex-row justify-center items-end w-[239px] left-1/2 transform -translate-x-1/2"
              style={{ marginTop: '5.4rem' }}
            >
              <h2 className="text-black text-[20px] font-bold uppercase text-center">
                우리의 비전은 밝은 미래입니다
              </h2>
            </div>
          </div>

          {/* 자세히보기 버튼 */}
          <div className="relative w-[154px]">
            <div className="absolute overflow-hidden rounded-full mt-12">
              <button
                onClick={() => {
                  navigate('/about?tab=vision');
                }}
                className="flex items-center px-6 py-3 bg-[#E1E2FF] border-[1.5px] border-[#2E3190] rounded-full relative overflow-hidden group"
              >
                <div
                  className="absolute left-0 top-0 h-full w-0 bg-black transition-all duration-500 group-hover:w-full"
                  style={{ zIndex: 1 }}
                ></div>
                <span className="text-black text-lg font-medium z-10 group-hover:text-white transition-colors duration-500">
                  자세히 보기
                </span>
                <span className="ml-2 text-black z-10 group-hover:text-white transition-colors duration-500">
                  &gt;
                </span>
              </button>
            </div>
          </div>
        </div>

        {/* 첫번째 중간 탭 */}
        <div className="flex justify-center items-center mt-40 space-x-10">
          <FeatureCard1
            backgroundImage={main_1_1}
            title="기술 솔루션"
            descriptionLines={[
              '기업 맞춤형 솔루션 제공',
              '다양한 산업에서 쌓은 경험을 바탕으로, 귀사의',
              '고유한 문제를 깊이 이해하고 효과적으로',
              '분석하여 맞춤형 솔루션을 제공합니다.',
            ]}
          />
          <FeatureCard1
            backgroundImage={main_1_2}
            title="문제 해결"
            descriptionLines={[
              '창의적 문제 해결',
              '복잡한 과제 돌파 기존 방식으로',
              '해결 불가능한 문제도',
              '혁신적 AI 기술로 극복합니다.',
            ]}
          />
          <FeatureCard1
            backgroundImage={main_1_3}
            title="성장 지원"
            descriptionLines={[
              '지속 가능한 성장 지원',
              '미래 지향 성장 엔진 제공 최신 AI 트렌드를',
              '반영하여 장기적으로',
              '지속 가능한 성장을 실현합니다.',
            ]}
          />
        </div>

        {/* ODOC 로고 및 자세히 보기 */}
        <div className="relative w-full h-auto flex flex-row justify-between items-center left-1/2 transform -translate-x-1/2">
          <div className="relative flex flex-col items-start w-[252px]">
            <div className="absolute flex flex-row justify-center items-center left-1/2 transform -translate-x-1/2 overflow-hidden mt-4">
              <h1
                className="text-black text-[80px] font-bold uppercase text-center"
                style={{ clipPath: 'inset(0 0 43% 0)' }}
              >
                odoc
              </h1>
            </div>
            <div
              className="absolute flex flex-row justify-center items-end w-[239px] left-1/2 transform -translate-x-1/2"
              style={{ marginTop: '5.4rem' }}
            >
              <h2 className="text-black text-[18px] font-bold uppercase text-center">
                우리의 여정은 지금도 계속됩니다
              </h2>
            </div>
          </div>

          {/* 자세히보기 버튼 */}
          <div className="relative w-[154px]">
            <div className="absolute overflow-hidden rounded-full mt-12">
              <button
                onClick={() => {
                  navigate('/about?tab=history');
                }}
                className="flex items-center px-6 py-3 bg-[#E1E2FF] border-[1.5px] border-[#2E3190] rounded-full relative overflow-hidden group"
              >
                <div
                  className="absolute left-0 top-0 h-full w-0 bg-black transition-all duration-500 group-hover:w-full"
                  style={{ zIndex: 1 }}
                ></div>
                <span className="text-black text-lg font-medium z-10 group-hover:text-white transition-colors duration-500">
                  자세히 보기
                </span>
                <span className="ml-2 text-black z-10 group-hover:text-white transition-colors duration-500">
                  &gt;
                </span>
              </button>
            </div>
          </div>
        </div>

        {/* 메인페이지 차트 */}
        <div className="flex flex-col items-center mt-48">
          <img src={mainChart} alt="조직도" className="w-full" />
        </div>
      </div>

      {/* 파트너스 차트 */}
      <div className="partners-section bg-[#E1E2FF] mt-12 py-16">
        <div className="flex flex-col items-center">
          <p className="text-black text-[64px] font-bold uppercase text-center">
            믿음직한 고객사들과 함께하고 있습니다
          </p>
          <p className="text-black text-[24px] font-light text-center">
            (원데이원커뮤니케이션은 신뢰받는 혁신 솔루션을 제공합니다)
          </p>
        </div>

        {/* 파트너 로고 리스트 */}
        <div className="flex flex-col items-center gap-10">
          <div className="mt-8 pb-4 flex justify-center">
            <img src={partnersList} alt="partnersList img"></img>
          </div>
        </div>
      </div>

      {/* 가운데 4개 카드 */}
      <div className="max-w-[1200px] w-[1180px] mx-auto mt-4">
        {/* ODOC 로고 및 자세히 보기 */}
        <div className="relative w-full h-auto flex flex-row justify-between items-center left-1/2 transform -translate-x-1/2">
          <div className="relative flex flex-col items-start w-[252px]">
            <div className="absolute flex flex-row justify-center items-center left-1/2 transform -translate-x-1/2 overflow-hidden mt-4">
              <h1
                className="text-black text-[80px] font-bold uppercase text-center"
                style={{ clipPath: 'inset(0 0 43% 0)' }}
              >
                odoc
              </h1>
            </div>
            <div
              className="absolute flex flex-row justify-center items-end w-[239px] left-1/2 transform -translate-x-1/2"
              style={{ marginTop: '5.4rem' }}
            >
              <h2 className="text-black text-[19px] font-bold uppercase text-center">
                우리의 AI 서비스에 앞장섭니다
              </h2>
            </div>
          </div>

          <div className="relative w-[154px]">
            <div className="absolute overflow-hidden rounded-full mt-12">
              <button
                onClick={() => {
                  navigate('/ourworks');
                  window.scrollTo(0, 0);
                }}
                className="flex items-center px-6 py-3 bg-[#E1E2FF] border-[1.5px] border-[#2E3190] rounded-full relative overflow-hidden group"
              >
                <div
                  className="absolute left-0 top-0 h-full w-0 bg-black transition-all duration-500 group-hover:w-full"
                  style={{ zIndex: 1 }}
                ></div>
                <span className="text-black text-lg font-medium z-10 group-hover:text-white transition-colors duration-500">
                  자세히 보기
                </span>
                <span className="ml-2 text-black z-10 group-hover:text-white transition-colors duration-500">
                  &gt;
                </span>
              </button>
            </div>
          </div>
        </div>

        {/* 4개의 카드 */}
        <div className="flex justify-center items-center mt-40 space-x-10">
          {serviceCards.map((card, index) => (
            <ServiceCard
              key={index}
              backgroundImage={card.backgroundImage}
              title={card.title}
              description={card.description}
              link={card.link}
            />
          ))}
        </div>
      </div>

      <TextSliding
        text={'AI 기술을 활용하여 기업 맞춤형 솔루션, 초개인화 서비스, 데이터 기반 의사결정을 지원합니다'}
        position={4080}
      />

      {/* 원데이원 최신 소식 */}
      <div className="max-w-[1200px] w-[1180px] mx-auto mt-4">
        <div className="flex flex-col mt-36">
          <div className="text-[36px] font-bold text-black uppercase mb-4">ODOC 최신 소식</div>
          {/* 태그 버튼 */}
          <div className="flex flex-row gap-6 w-[1196px] h-[48px]">
            {news_tags.map((tag, index) => (
              <TagButton key={index} label={tag} />
            ))}
          </div>
          {/* 뉴스 목록 */}
          <div className="flex flex-col items-start mt-10 w-[1196px] gap-10">
            {/* 헤드 뉴스 */}
            <div className="flex flex-row items-end w-[1196px] h-[296px] shadow-md bg-white">
              <div className="relative w-[618px] h-[296px] overflow-hidden">
                <div
                  className="absolute w-full h-full bg-cover bg-center transform transition-transform duration-500 hover:scale-125"
                  style={{ backgroundImage: `url(${newsData[0].image})`, backgroundSize: 'cover' }}
                ></div>
              </div>
              <div className="flex flex-col justify-between items-end p-6 gap-6 w-[578px] h-[296px] relative">
                <div className="absolute overflow-hidden rounded-full top-6 right-6">
                  <button
                    onClick={() => {
                      navigate(`/newsdetail/${newsData[0].id}`);
                      window.scrollTo(0, 0);
                    }}
                    className="flex items-center px-6 py-3 bg-[#E1E2FF] border-[1.5px] border-[#2E3190] rounded-full relative overflow-hidden group"
                  >
                    <div
                      className="absolute left-0 top-0 h-full w-0 bg-black transition-all duration-500 group-hover:w-full"
                      style={{ zIndex: 1 }}
                    ></div>
                    <span className="text-black text-lg font-medium z-10 group-hover:text-white transition-colors duration-500">
                      자세히 보기
                    </span>
                    <span className="ml-2 text-black z-10 group-hover:text-white transition-colors duration-500">
                      &gt;
                    </span>
                  </button>
                </div>
                <div className="absolute bottom-[3.5rem] left-6 right-6">
                  <h2 className="text-black text-3xl font-bold uppercase mb-4 tracking-wide">
                    {newsData[0].title}
                  </h2>
                  <p className="text-black text-lg font-light leading-tight">{newsData[0].title_content}</p>
                </div>
              </div>
            </div>
            {/* 서브 뉴스 */}
            <div className="flex gap-10 pb-12 overflow-x-auto whitespace-nowrap w-[100%]">
              {newsData.slice(1).map((newsItem) => (
                <SubNewsCard
                  key={newsItem.id}
                  id={newsItem.id}
                  image={newsItem.image}
                  title={newsItem.title}
                  content={newsItem.title_content}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
