import React from 'react';
import '../styles/textsliding.css';

const TextSliding = ({ text, position }) => {
    const textLength = text.length;
    const baseDuration = 30;
    const animationDuration = baseDuration + textLength * 0.1;

    return (
        <div
            className="text-sliding-wrapper"
            style={{ top: `${position}px` }}
        >
            <div
                className="text-sliding-content"
                style={{ animationDuration: `${animationDuration}s` }}
            >
                <span className="text-item">{text}</span>
                {[...Array(5)].map((_, index) => (
                    <React.Fragment key={index}>
                        <div className="text-divider"></div>
                        <span className="text-item">{text}</span>
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

export default TextSliding;
