import React from 'react';

const TabBanner = ({ image, title, details }) => (
  <div className="relative w-full h-[542px] bg-cover bg-center" style={{ backgroundImage: `url(${image})` }}>
    <div className="absolute inset-0 bg-[rgba(1,1,1,0.12)] backdrop-blur-[32px]"></div>

    <div
      className="absolute flex flex-col items-center gap-6 p-0 w-[573px] left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      style={{ height: `${96 + details.length * 24}px`, top: `calc(50% - ${96 + details.length * 12}px / 2)` }}
    >
      <p className="text-white text-[64px] font-bold uppercase text-center">{title}</p>

      <div className="flex flex-col gap-3 items-center">
        {details.map((detail, index) => (
          <p key={index} className="text-white text-[24px] font-light text-center">
            {detail}
          </p>
        ))}
      </div>
    </div>
  </div>
);

export default TabBanner;
