import {
  ourworks_1_1,
  ourworks_1_2,
  ourworks_1_3,
  ourworks_1_4,
  ourworks_2_1,
  ourworks_2_2,
  ourworks_2_3,
  ourworks_2_4,
  ourworks_3_1,
  ourworks_3_2,
  ourworks_3_3,
  ourworks_3_4,
  ourworks_3_5,
  ourworks_3_6,
} from '../assets/images/Images';

export const products_1 = [
  { image: ourworks_1_1, title: 'AI 전략 수립' },
  { image: ourworks_1_2, title: '데이터 분석 및 관리' },
  { image: ourworks_1_3, title: '모델 개발 및 검증' },
  { image: ourworks_1_4, title: 'AI/ML 도입 및 운영' },
];

export const products_2 = [
  { image: ourworks_2_1, title: 'AI모델, 알고리즘' },
  { image: ourworks_2_2, title: '빠른배포' },
  { image: ourworks_2_3, title: '사용자 피드백 수집' },
  { image: ourworks_2_4, title: '반복적 개선 가능' },
];

export const products_3 = [
  { image: ourworks_3_1, title: '1 문제정의 및 요구사항 분석' },
  { image: ourworks_3_2, title: '2 데이터 수집 및 분석' },
  { image: ourworks_3_3, title: '3 모델 선택 및 설계' },
  { image: ourworks_3_4, title: '4 모델 훈련 및 검증' },
  { image: ourworks_3_5, title: '5 모델 테스트 및 튜닝' },
  { image: ourworks_3_6, title: '6 배포 및 모니터링' },
];

export const products_4 = [
  {
    title: '빅데이터 관리',
    details: ['데이터 수집 및 분석', 'AI 모델 성능 개선', '모델의 정교화'],
  },
  {
    title: '클라우드 서비스',
    details: [
      'AI 서비스 모델 데이터 저장 및 처리',
      'AI 모델 배포 및 관리',
      '데브옵스 및 CI/CD 지원',
      '모델 배포 후 모니터링 유지보수',
    ],
  },
];

export const descriptions = [
  [
    'AI 전략 및 컨설팅 서비스는 기업의 목표에 맞춘 맞춤형 인공지능 솔루션을 제공합니다. 데이터 분석, 프로세스 최적화 및 기술 도입 전략을 통해 기업의 혁신과 성장을 지원합니다',
  ],
  ['초기 사용자의 피드백을 받아 제품을 개선하기 위해 빠르게 시장 가치를 제공합니다'],
  [
    '사용자 정의 모델 개발은 특정 요구와 비즈니스 목표에 맞춘 맞춤형 AI 솔루션을 제공합니다. 이를 통해 기업은 데이터 분석과 의사결정을 최적화하여 성과를 극대화할 수 있습니다',
  ],
  [
    '빅데이터 & 보유 솔루션은 대량의 데이터를 효과적으로 수집, 저장, 분석하여 인사이트를 제공합니다. 이를 통해 기업의 데이터 기반의 의사결정을 지원하고 경쟁력을 강화하는 데 기여합니다',
  ],
];
