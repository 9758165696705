export const solutionCardData = [
  {
    title: '아트 - UNC갤러리',
    content: ['UNC갤러리 주최 전시회', "'그림 깨우기:크리스토퍼 루크헤베를레'"],
    details: '(2023.11.29~2024.03.03)',
  },
  {
    title: '마케팅 - 퍼즐코퍼레이션',
    content: ['AI기반의 정확한 RULE-BASED', '성과형 콘텐츠 구별'],
    details: '(2023.11.29~2024.03.03)',
  },
  {
    title: '유통 - 제이크이엔앰',
    content: ['AI 알고리즘을 통한 화장품 추천', '서비스의 성능 향상'],
    details: '(2023.11.29~2024.03.03)',
  },
  {
    title: '의료 - 3D 메디비젼',
    content: ['본사와의 상담을 통한', '수술자막 데이터 자동AI번역  솔루션'],
    details: '(2023.11.29~2024.03.03)',
  },
];
