// import React, { useState } from 'react';
// import { Link, useLocation, useNavigate } from 'react-router-dom';
// import logo from '../../ourlogo.svg';
// import { menuItems } from '../../constants/headerData';
//
// const Header = () => {
//   const [hoveredMenu, setHoveredMenu] = useState(null);
//   const [hoveredSubMenu, setHoveredSubMenu] = useState(null);
//   const location = useLocation();
//   const navigate = useNavigate();
//
//   const handleMouseEnter = (menu) => {
//     setHoveredMenu(menu);
//     setHoveredSubMenu(null);
//   };
//   const handleMouseLeave = () => {
//     if (!hoveredSubMenu) {
//       setHoveredMenu(null);
//       setHoveredSubMenu(null);
//     }
//   };
//
//   const activeMenu = menuItems.find((menuItem) => menuItem.label.toLowerCase() === hoveredMenu);
//
//   return (
//     <header className="bg-black text-white fixed top-0 left-0 right-0 z-50">
//       <div className="container mx-auto flex justify-between items-center content-center h-[65px] px-[64px] max-w-[1440px]">
//         <div
//           className="flex items-center cursor-pointer"
//           onClick={() => {
//             navigate('/');
//             window.scrollTo(0, 0);
//           }}
//         >
//           <img src={logo} alt="O:DOC Logo" className="h-[32px] w-[114px]" />
//         </div>
//         <nav className="flex items-center justify-center text-center h-[100%]">
//           <ul
//             className="flex items-center justify-between space-x-[40px] text-white"
//             style={{ height: '100%', alignContent: 'center' }}
//           >
//             {menuItems.map((menuItem) => {
//               const isHomeActive = location.pathname === '/' && menuItem.path === '/';
//               const isPathActive = location.pathname.startsWith(menuItem.path) && menuItem.path !== '/';
//               const isActive = isHomeActive || isPathActive;
//               return (
//                 <li
//                   key={menuItem.label}
//                   onMouseEnter={() => handleMouseEnter(menuItem.label.toLowerCase())}
//                   onMouseLeave={() => {
//                     if (!activeMenu.subMenu || (!hoveredMenu && !hoveredSubMenu)) handleMouseLeave();
//                   }}
//                   className="relative flex items-center h-full"
//                 >
//                   <Link
//                     to={menuItem.path}
//                     className={`text-white hover:text-white ${!isActive && ' hover:text-opacity-80'}`}
//                     style={{ fontWeight: 600 }}
//                   >
//                     {menuItem.label}
//                     <span
//                       className={`absolute bottom-0 left-0 w-full h-[4px] bg-[#FFFFFF] transform transition-transform duration-500 ease-in-out origin-center ${
//                         isActive || hoveredMenu === menuItem.label.toLowerCase() ? 'scale-x-100' : 'scale-x-0'
//                       } ${!isActive && hoveredMenu === menuItem.label.toLowerCase() && 'bg-opacity-80'}`}
//                     />
//                   </Link>
//                 </li>
//               );
//             })}
//           </ul>
//         </nav>
//         <div className="flex items-center space-x-12">
//           <span className="text-white underline cursor-pointer">KOR</span>
//         </div>
//       </div>
//
//       {/* 세부 페이지 하위 메뉴 */}
//       {hoveredMenu && activeMenu && activeMenu.subMenu && (
//         <div
//           className="bg-[#FFFFFF]"
//           onMouseEnter={() => setHoveredSubMenu(hoveredMenu)}
//           onMouseLeave={handleMouseLeave}
//         >
//           <div className="container mx-auto flex justify-center items-center h-[65px] max-w-[1440px] space-x-6">
//             {activeMenu.subMenu.map((subMenuItem) => (
//               <Link
//                 key={subMenuItem.label}
//                 to={subMenuItem.path}
//                 onMouseEnter={() => setHoveredSubMenu(subMenuItem.label.toLowerCase())}
//                 onMouseLeave={() => setHoveredSubMenu(null)}
//                 className="relative text-[#2E3190] hover:text-[#2E3190]"
//                 style={{ fontWeight: 600 }}
//               >
//                 {subMenuItem.label}
//                 <span
//                   className={`absolute bottom-[-1.3rem] left-0 w-full h-[4px] bg-[#2E3190] transform transition-transform duration-500 ease-in-out origin-center ${
//                     hoveredSubMenu === subMenuItem.label.toLowerCase() ? 'scale-x-100' : 'scale-x-0'
//                   }`}
//                 />
//               </Link>
//             ))}
//           </div>
//         </div>
//       )}
//     </header>
//   );
// };
//
// export default Header;


import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../logo1.svg';
import { menuItems } from '../../constants/headerData';

const Header = () => {
    const [hoveredMenu, setHoveredMenu] = useState(null);
    const [hoveredSubMenu, setHoveredSubMenu] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();

    const handleMouseEnter = (menu) => {
        setHoveredMenu(menu);
        setHoveredSubMenu(null);
    };

    const handleMouseLeave = () => {
        if (!hoveredSubMenu) {
            setHoveredMenu(null);
            setHoveredSubMenu(null);
        }
    };

    const activeMenu = menuItems.find((menuItem) => menuItem.label.toLowerCase() === hoveredMenu);

    return (
        <header className="bg-white text-white fixed top-0 left-0 right-0 z-50">
            <div className="container mx-auto flex justify-between items-center content-center h-[65px] px-[64px] max-w-[1440px]">
                <div
                    className="flex items-center cursor-pointer"
                    onClick={() => {
                        navigate('/');
                        window.scrollTo(0, 0);
                    }}
                >
                    <img src={logo} alt="O:DOC Logo" className="h-[32px] w-[114px]" />
                </div>
                <nav className="flex items-center justify-center text-center h-[100%]">
                    <ul
                        className="flex items-center justify-between space-x-[40px] text-white"
                        style={{ height: '100%', alignContent: 'center' }}
                    >
                        {menuItems.map((menuItem) => {
                            const isHomeActive = location.pathname === '/' && menuItem.path === '/';
                            const isPathActive = location.pathname.startsWith(menuItem.path) && menuItem.path !== '/';
                            const isActive = isHomeActive || isPathActive;
                            return (
                                <li
                                    key={menuItem.label}
                                    onMouseEnter={() => handleMouseEnter(menuItem.label.toLowerCase())}
                                    onMouseLeave={() => {
                                        // 수정된 부분: activeMenu가 있고 subMenu가 있을 때만 체크
                                        if (!activeMenu?.subMenu || (!hoveredMenu && !hoveredSubMenu)) {
                                            handleMouseLeave();
                                        }
                                    }}
                                    className="relative flex items-center h-full"
                                >
                                    <Link
                                        to={menuItem.path}
                                        className={`text-black hover:text-white ${!isActive && ' hover:text-opacity-80'}`}
                                        style={{ fontWeight: 600 }}
                                    >
                                        {menuItem.label}
                                        <span
                                            className={`absolute bottom-0 left-0 w-full h-[4px] bg-[#FFFFFF] transform transition-transform duration-500 ease-in-out origin-center ${
                                                isActive || hoveredMenu === menuItem.label.toLowerCase() ? 'scale-x-100' : 'scale-x-0'
                                            } ${!isActive && hoveredMenu === menuItem.label.toLowerCase() && 'bg-opacity-80'}`}
                                        />
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </nav>
                <div className="flex items-center space-x-12">
                    <span className="text-white underline cursor-pointer">KOR</span>
                </div>
            </div>

            {/* 세부 페이지 하위 메뉴 */}
            {hoveredMenu && activeMenu?.subMenu && (
                <div
                    className="bg-[#FFFFFF]"
                    onMouseEnter={() => setHoveredSubMenu(hoveredMenu)}
                    onMouseLeave={handleMouseLeave}
                >
                    <div className="container mx-auto flex justify-center items-center h-[65px] max-w-[1440px] space-x-6">
                        {activeMenu.subMenu.map((subMenuItem) => (
                            <Link
                                key={subMenuItem.label}
                                to={subMenuItem.path}
                                onMouseEnter={() => setHoveredSubMenu(subMenuItem.label.toLowerCase())}
                                onMouseLeave={() => setHoveredSubMenu(null)}
                                className="relative text-black hover:text-black"
                                style={{ fontWeight: 600 }}
                            >
                                {subMenuItem.label}
                                <span
                                    className={`absolute bottom-[-1.3rem] left-0 w-full h-[4px] bg-black transform transition-transform duration-500 ease-in-out origin-center ${
                                        hoveredSubMenu === subMenuItem.label.toLowerCase() ? 'scale-x-100' : 'scale-x-0'
                                    }`}
                                />
                            </Link>
                        ))}
                    </div>
                </div>
            )}
        </header>
    );
};

export default Header;