import React, { useState, useRef } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import emailjs from '@emailjs/browser';

const InquiryForm = ({ isModal, onModalSuccess }) => {
    const formRef = useRef();
    const [formData, setFormData] = useState({
        name: '',
        company: '',
        email: '',
        phone: '',
        inquiryType: '',
        message: ''
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const validateField = (fieldName, value) => {
        if (!value) {
            setErrors(prev => ({ ...prev, [fieldName]: "정보가 입력되지 않았습니다." }));
            return false;
        }

        if (fieldName === 'email') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value)) {
                setErrors(prev => ({ ...prev, [fieldName]: "올바른 이메일 형식이 아닙니다." }));
                return false;
            }
        }

        if (fieldName === 'phone') {
            const phoneRegex = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
            if (!phoneRegex.test(value)) {
                setErrors(prev => ({ ...prev, [fieldName]: "올바른 전화번호 형식이 아닙니다." }));
                return false;
            }
        }

        setErrors(prev => ({ ...prev, [fieldName]: null }));
        return true;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        validateField(name, value);

        if (name === 'inquiryType') {
            setIsOpen(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let hasError = false;

        // Validate all fields
        Object.keys(formData).forEach(key => {
            if (!validateField(key, formData[key])) {
                hasError = true;
            }
        });

        if (!hasError) {
            setLoading(true);
            try {
                const templateParams = {
                    from_name: formData.name,
                    company: formData.company,
                    from_email: formData.email,
                    phone: formData.phone,
                    inquiry_type: formData.inquiryType,
                    message: formData.message,
                    to_name: 'ODOC'
                };

                const result = await emailjs.send(
                    process.env.REACT_APP_EMAILJS_SERVICE_ID,
                    process.env.REACT_APP_EMAILJS_INQUIRY_TEMPLATE_ID,
                    templateParams,
                    process.env.REACT_APP_EMAILJS_PUBLIC_KEY
                );

                if (result.status === 200) {
                    setStatus('success');
                    setFormData({
                        name: '',
                        company: '',
                        email: '',
                        phone: '',
                        inquiryType: '',
                        message: ''
                    });

                    if (isModal && onModalSuccess) {
                        onModalSuccess();
                    }
                } else {
                    throw new Error('메일 발송 실패');
                }
            } catch (error) {
                console.error('Error sending email:', error);
                setStatus('error');
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <div className="bg-white p-8">
            <h2 className="text-2xl font-bold mb-8">CONTACT US</h2>

            <form ref={formRef} onSubmit={handleSubmit} className="space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                    <div className="h-[85px]">
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className={`w-full p-3 rounded-lg bg-[#F6F6F6] border-none ${errors.name ? 'border-red-500' : ''}`}
                            placeholder="성명"
                        />
                        <p className="text-red-500 text-sm h-5 mt-1">{errors.name || ' '}</p>
                    </div>

                    <div className="h-[85px]">
                        <input
                            type="text"
                            name="company"
                            value={formData.company}
                            onChange={handleChange}
                            className={`w-full p-3 rounded-lg bg-[#F6F6F6] border-none ${errors.company ? 'border-red-500' : ''}`}
                            placeholder="회사명"
                        />
                        <p className="text-red-500 text-sm h-5 mt-1">{errors.company || ' '}</p>
                    </div>

                    <div className="h-[85px]">
                        <input
                            type="tel"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            className={`w-full p-3 rounded-lg bg-[#F6F6F6] border-none ${errors.phone ? 'border-red-500' : ''}`}
                            placeholder="연락처"
                        />
                        <p className="text-red-500 text-sm h-5 mt-1">{errors.phone || ' '}</p>
                    </div>

                    <div className="h-[85px]">
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className={`w-full p-3 rounded-lg bg-[#F6F6F6] border-none ${errors.email ? 'border-red-500' : ''}`}
                            placeholder="이메일 주소"
                        />
                        <p className="text-red-500 text-sm h-5 mt-1">{errors.email || ' '}</p>
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="h-[85px]">
                        <input
                            type="text"
                            name="title"
                            value={formData.title}
                            onChange={handleChange}
                            className={`w-full p-3 rounded-lg bg-[#F6F6F6] border-none ${errors.title ? 'border-red-500' : ''}`}
                            placeholder="문의제목"
                        />
                        <p className="text-red-500 text-sm h-5 mt-1">{errors.title || ' '}</p>
                    </div>

                    <div className="h-[85px] relative">
                        <select
                            name="inquiryType"
                            value={formData.inquiryType}
                            onChange={handleChange}
                            onClick={() => setIsOpen(!isOpen)}
                            onBlur={() => setIsOpen(false)}
                            className={`w-full p-3 rounded-lg bg-[#F6F6F6] border-none appearance-none pr-10 ${errors.inquiryType ? 'border-red-500' : ''} ${formData.inquiryType === '' ? 'text-gray-500' : 'text-black'}`}
                        >
                            <option value="">서비스 종류</option>
                            <option value="business">사업 제휴</option>
                            <option value="demo">데모 문의</option>
                            <option value="pricing">가격 문의</option>
                            <option value="other">기타 문의</option>
                        </select>
                        <div className="absolute right-3 top-3.5 pointer-events-none">
                            {isOpen ? <ChevronUp className="text-gray-400" /> : <ChevronDown className="text-gray-400" />}
                        </div>
                        <p className="text-red-500 text-sm h-5 mt-1">{errors.inquiryType || ' '}</p>
                    </div>
                </div>

                <div className="h-[245px]">
                    <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        className={`w-full p-3 rounded-lg bg-[#F6F6F6] border-none h-[200px] resize-none ${errors.message ? 'border-red-500' : ''}`}
                        placeholder="문의 사항"
                    />
                    <p className="text-red-500 text-sm h-5 mt-1">{errors.message || ' '}</p>
                </div>

                <div className="flex justify-between items-center h-[50px]">
                    <div className="flex-1">
                        {status === 'success' && (
                            <div className="p-3 bg-green-50 border border-green-200 rounded-lg">
                                <p className="text-green-800">문의가 성공적으로 접수되었습니다!</p>
                            </div>
                        )}

                        {status === 'error' && (
                            <div className="p-3 bg-red-50 border border-red-200 rounded-lg">
                                <p className="text-red-800">문의 접수 중 오류가 발생했습니다. 다시 시도해주세요.</p>
                            </div>
                        )}
                    </div>

                    <button
                        type="submit"
                        disabled={loading}
                        className="px-6 py-3 bg-white text-black border border-black rounded-full hover:bg-black hover:text-white transition-colors duration-200 disabled:opacity-50 ml-4"
                    >
                        {loading ? '처리중...' : '문의하기 →'}
                    </button>
                </div>
            </form>
        </div>
    );
};
export default InquiryForm;