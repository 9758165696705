import React from 'react';

const PageBanner = ({ title, subtitle, backgroundImage }) => {
  return (
    <div
      className="relative w-full h-[426px]"
      style={{
        aspectRatio: '1440 / 426',
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <div className="absolute inset-0 bg-[rgba(1,1,1,0.24)] backdrop-blur-[1px]"></div>

      <div className="absolute flex flex-col items-center justify-center inset-0 text-center text-white">
        <div className="text-6xl font-bold uppercase">{title}</div>
        <div className="text-lg mt-4">{subtitle}</div>
      </div>

      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 text-center">
        <div className="text-sm font-medium text-white mb-2">아래로 스크롤</div>
        <div className="relative w-[6px] h-[9px] mx-auto mt-1">
          <div className="w-[6px] h-[6px] bg-white rounded-full"></div>
          <div className="w-[1px] h-[20px] bg-[rgba(255,255,255,0.6)] mx-auto"></div>
        </div>
      </div>
    </div>
  );
};

export default PageBanner;
