import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TabMenu from '../components/Tabmenu';
import TextSliding from '../components/TextSliding';
import {
  aiservice,
  ourworks_mid_banner,
  aiservice_1_1,
  aiservice_1_2,
  aiservice2,
  aiservice3,
  aiservice4,
  aiservice5,
  aiservice6,
  aiservice7,
  aiservice8,
  aiservice9,
  aiservice10,
  aiservice11,
  aiservice12,
  ourworks_btm_banner,
  upper2,
} from '../assets/images/Images';
import DetailsButton from '../components/DetailsButton';
import { products_1, products_2, products_3, products_4, descriptions } from '../constants/aiServiceData';

const tabs = [
  { id: 1, label: 'AI 전략 및 컨설팅' },
  { id: 2, label: 'AI 기반 MVP 개발' },
  { id: 3, label: '사용자 정의 모델 개발' },
  { id: 4, label: '빅데이터 & 보유 솔루션' },
];

const TabContent = ({ activeTab, sectionRefs }) => {
  const navigate = useNavigate();

  switch (activeTab) {
    case 1:
      return (
        <div>
          <div className="max-w-[1200px] w-[1180px] mx-auto mt-12">
            {/* 최상단 컴포넌트 */}
            <div className="flex gap-[40px] w-full h-[269px]">
              {products_1.map((product, index) => (
                <div className="flex flex-col items-start w-[269px] h-[269px] bg-white shadow-md group">
                  <div className="overflow-hidden">
                    <div
                      className="w-[269px] h-[201px] bg-cover bg-center transition-transform duration-500 ease-in-out group-hover:scale-150"
                      style={{
                        backgroundImage: `url(${product.image})`,
                      }}
                    />
                  </div>
                  <div className="flex justify-center items-center w-[269px] h-[68px] p-6 transition-colors duration-500 ease-in-out group-hover:bg-black">
                    <h2 className="text-center text-black font-bold text-[20px] uppercase transition-colors duration-500 ease-in-out group-hover:text-white">
                      {product.title}
                    </h2>
                  </div>
                </div>
              ))}
            </div>

            {/* 중간 배너파트 */}
            <div className="flex flex-col items-center gap-6 w-[1196px] h-[496px] mx-auto mt-10">
              <h1 className="text-[36px] font-bold text-black uppercase text-center">
                ODOC는 컨설팅 전 AI POC를 진행합니다.
              </h1>

              <div className="relative w-[1196px] h-[420px]">
                <div
                  className="absolute inset-0 bg-cover bg-center"
                  style={{ backgroundImage: `url(${ourworks_mid_banner})` }}
                />
                <div className="absolute inset-0 bg-[rgba(1,1,1,0.12)] backdrop-blur-[32px]"></div>

                <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[870px] h-[224px] flex flex-col items-center gap-6">
                  <h2 className="text-[44px] font-bold text-white uppercase text-center">AI POC란?</h2>

                  <div className="flex flex-col items-center w-[870px]">
                    <p className="text-[20px] font-light text-white text-center">
                      POC는 Proof of Concept의 약자로, <span className="font-bold">구현 가능성을 검증하는 시제품</span>
                      을 의미합니다.
                    </p>
                    <p className="text-[20px] font-light text-white text-center my-4">---</p>
                    <p className="text-[20px] font-light text-white text-center">
                      AI 기반 솔루션 POC는 인공지능을 활용한 솔루션의 개념 검증 단계를 의미합니다.
                    </p>
                    <p className="text-[20px] font-light text-white text-center">
                      AI 기술을 사용하여 특정 비즈니스 문제를 해결할 수 있는지, 성능을 발휘할 수 있는지 실험, 평가하는
                      과정입니다.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <TextSliding
            text={'AI 기술을 활용하여 기업 맞춤형 솔루션, 초개인화 서비스, 데이터 기반 의사결정을 지원합니다'}
            position={1670}
          />

          {/* 본내용 컴포넌트 */}
          <div ref={sectionRefs.projects} className="w-full mx-auto bg-[#F1F4F9] mt-40">
            <div className="flex flex-col items-center w-[1196px] mx-auto gap-8">
              <h1 className="text-black text-4xl font-bold uppercase text-center">주요 프로젝트</h1>

              {/* 첫 번째 섹션 */}
              <div className="flex flex-col items-start bg-[#E1E2FF] p-10 gap-4 w-[1196px] h-[900px]">
                <div className="flex flex-col gap-4 w-[636px]">
                  <h2 className="text-black text-3xl font-bold uppercase">해외주재원 AI</h2>
                  <p className="text-black text-lg font-light">
                    해외주재원 AI는 주재원의 의사소통과 업무 효율을 높이고 현지 적응을 지원합니다.
                  </p>
                </div>

                <div className="flex flex-row gap-4 w-[1116px] h-[274px]">
                  <img src={aiservice_1_1} alt="Project 1-1" className="w-[542px] h-[274px]" />
                  <div className="flex flex-col gap-4 w-[550px]">
                    <h3 className="text-black text-xl font-bold uppercase">
                      해외 주재원 관리를 위한 맞춤형 AI 캘린더 ‘엑스팻(Expat) AI’를 개발
                    </h3>
                    <div className="flex flex-col gap-2">
                      <p className="text-black text-base font-light">
                        기존의 주재원 육성-관리-귀임 과정의 한계점을 극복하기 위해 주재원 파견 전ᆞ중ᆞ후 주재원의 역량
                        강화와 국가 별 업무 지원, 주거 및 자녀 교육 등 생활 정보 등을 통합 제공하는, 해외 파견
                        근무자(주재원)의 이 문화 적응과 업무 지원을 위한 맞춤형 AI 캘린더 ‘엑스팻(Expat) AI’를 개발하여
                        주재원들의 성공적인 해외 주재원 과정을 지원.
                      </p>
                    </div>
                  </div>
                </div>
                <img src={aiservice_1_2} alt="Project 1-1" className="w-[1116px] h-[374px]" />
                <h3 className="text-black text-xl font-bold uppercase self-center">
                  해외 주재원 관리를 위한 맞춤형 AI 캘린더 ‘엑스팻(Expat) AI’를 개발
                </h3>
              </div>

              {/* 두 번째 섹션 */}
              <div className="flex flex-col items-start bg-[#E1E2FF] p-10 gap-4 w-[1196px] h-[910px]">
                <div className="flex flex-col gap-4 w-[543px]">
                  <h2 className="text-black text-3xl font-bold uppercase">SEO자동화 AI 솔루션</h2>
                  <p className="text-black text-lg font-light">
                    SEO 자동화를 통해 웹사이트의 가시성을 극대화하는 AI 솔루션입니다.
                  </p>
                </div>

                <div className="flex flex-col items-center w-[1116px] h-[726px]">
                  <div className="bg-white w-[1116px] h-[678px] flex justify-center">
                    <img src={aiservice2} alt="Project 2" className="w-[618px] h-[678px]" />
                  </div>
                  <h3 className="text-black text-2xl font-light mt-6">
                    검색엔진 최적화를 위한 <span className="font-semibold">‘seo자동화 AI 솔루션’</span> 기획
                  </h3>
                </div>
              </div>

              {/* 세 번째 섹션 */}
              <div className="flex flex-col items-start bg-[#E1E2FF] p-10 gap-4 w-[1196px] h-[644px]">
                <div className="flex flex-col gap-4 w-[543px]">
                  <h2 className="text-black text-3xl font-bold uppercase">AI 뷰티 에이전트 새롭</h2>
                  <p className="text-black text-lg font-light">
                    SEO 자동화를 통해 웹사이트의 가시성을 극대화하는 AI 솔루션입니다.
                  </p>
                </div>

                <div className="flex flex-col items-center w-[1116px] h-[460px]">
                  <div className="w-[1116px] h-[412px] bg-white flex justify-center">
                    <img src={aiservice3} alt="Project 3" className="w-[526px] h-[412px]" />
                  </div>
                  <h3 className="text-black text-2xl font-light mt-6">
                    뷰티 중소기업 인디 브랜드 마케팅 강화와 매출증대를 위한 현장 AI 뷰티 마케터{' '}
                    <span className="font-semibold">‘AI 뷰티 에이전트 새롭’ </span>
                    개발
                  </h3>
                </div>
              </div>
            </div>
          </div>

          {/* 공통 하단부 배너 컴포넌트 */}
          <div className="relative w-full h-[640px] my-12">
            <img src={ourworks_btm_banner} alt="Background" className="absolute w-full h-full object-cover" />
            <div className="absolute inset-0 bg-black bg-opacity-30 backdrop-blur-lg"></div>

            <div
              className="absolute flex flex-col items-center gap-10 w-[819px] h-[200px]"
              style={{
                left: 'calc(50% - 819px / 2)',
                top: 'calc(50% - 200px / 2)',
              }}
            >
              <div className="flex flex-col items-center gap-6">
                <h2 className="w-[819px] h-[64px] font-bold text-white text-[64px] leading-[64px] text-center uppercase">
                  ODOC와 미래를 함께할 파트너
                </h2>
                <p className="w-[465px] h-[24px] font-light text-white text-[24px] leading-[24px] text-center">
                  문의사항을 남겨주시면 확인 후 연락드리겠습니다.
                </p>
              </div>

              <DetailsButton onClick={() => navigate('/contact')} label="문의하기" />
            </div>
          </div>
        </div>
      );
    case 2:
      return (
        <div>
          <div className="max-w-[1200px] w-[1180px] mx-auto mt-12">
            {/* 최상단 컴포넌트 */}
            <div className="flex gap-[40px] w-full h-[269px]">
              {products_2.map((product, index) => (
                <div className="flex flex-col items-start w-[269px] h-[269px] bg-white shadow-md group">
                  <div className="overflow-hidden">
                    <div
                      className="w-[269px] h-[201px] bg-cover bg-center transition-transform duration-500 ease-in-out group-hover:scale-150"
                      style={{
                        backgroundImage: `url(${product.image})`,
                      }}
                    />
                  </div>
                  <div className="flex justify-center items-center w-[269px] h-[68px] p-6 transition-colors duration-500 ease-in-out group-hover:bg-black">
                    <h2 className="text-center text-black font-bold text-[20px] uppercase transition-colors duration-500 ease-in-out group-hover:text-white">
                      {product.title}
                    </h2>
                  </div>
                </div>
              ))}
            </div>

            {/* 중간 배너파트 */}
            <div className="flex flex-col items-center gap-6 w-[1196px] h-[496px] mx-auto mt-10">
              <h1 className="text-[36px] font-bold text-black uppercase text-center">
                ODOC는 컨설팅 전 AI POC를 진행합니다.
              </h1>

              <div className="relative w-[1196px] h-[420px]">
                <div
                  className="absolute inset-0 bg-cover bg-center"
                  style={{ backgroundImage: `url(${ourworks_mid_banner})` }}
                />
                <div className="absolute inset-0 bg-[rgba(1,1,1,0.12)] backdrop-blur-[32px]"></div>

                <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[870px] h-[224px] flex flex-col items-center gap-6">
                  <h2 className="text-[44px] font-bold text-white uppercase text-center">AI POC란?</h2>

                  <div className="flex flex-col items-center w-[870px]">
                    <p className="text-[20px] font-light text-white text-center">
                      POC는 Proof of Concept의 약자로, <span className="font-bold">구현 가능성을 검증하는 시제품</span>
                      을 의미합니다.
                    </p>
                    <p className="text-[20px] font-light text-white text-center my-4">---</p>
                    <p className="text-[20px] font-light text-white text-center">
                      AI 기반 솔루션 POC는 인공지능을 활용한 솔루션의 개념 검증 단계를 의미합니다.
                    </p>
                    <p className="text-[20px] font-light text-white text-center">
                      AI 기술을 사용하여 특정 비즈니스 문제를 해결할 수 있는지, 성능을 발휘할 수 있는지 실험, 평가하는
                      과정입니다.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <TextSliding
            text={'AI 기술을 활용하여 기업 맞춤형 솔루션, 초개인화 서비스, 데이터 기반 의사결정을 지원합니다'}
            position={1670}
          />

          {/* 본내용 컴포넌트 */}
          <div
            ref={sectionRefs.projects}
            className="flex flex-col items-center w-[1196px] h-[981px] gap-10 mx-auto mt-40"
          >
            <h2 className="text-[36px] font-bold text-black uppercase">주요 프로젝트</h2>

            <div className="flex flex-col items-start w-[1196px] bg-[#E1E2FF] p-10 gap-4">
              <div className="flex flex-col items-start gap-4 w-[545px]">
                <h3 className="text-[36px] font-bold text-black uppercase">디지털 마케팅 시뮬레이터 A-Bridge</h3>
                <p className="text-[20px] font-light text-black">
                  A-Bridge는 전략적 결정을 지원하는 디지털 마케팅 시뮬레이터입니다.
                </p>
              </div>

              <div className="flex flex-col items-center w-[1116px] h-[721px] gap-6">
                <div className="w-[1116px] h-[609px] bg-white flex justify-center items-center">
                  <img
                    src={aiservice4}
                    alt="A-Bridge 디지털 마케팅 시뮬레이터"
                    className="w-[590px] h-[609px] object-cover"
                  />
                </div>

                <div className="flex flex-col items-start w-full h-[88px] text-black text-[24px] font-light">
                  <p>실제 마케팅 캠페인을 진행하지 않고도 가상환경에서 마케팅 전략과 실행 테스트 및 학습.</p>
                  <p>
                    디지털 마케팅의 다양한 요소들을 시뮬레이션화하여, 사용자가 실제 마케팅에서 경험할 수 있는 다양한
                    상황 체험 할 수 있습니다.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* 공통 하단부 배너 컴포넌트 */}
          <div className="relative w-full h-[640px] my-12 mt-24">
            <img src={ourworks_btm_banner} alt="Background" className="absolute w-full h-full object-cover" />
            <div className="absolute inset-0 bg-black bg-opacity-30 backdrop-blur-lg"></div>

            <div
              className="absolute flex flex-col items-center gap-10 w-[819px] h-[200px]"
              style={{
                left: 'calc(50% - 819px / 2)',
                top: 'calc(50% - 200px / 2)',
              }}
            >
              <div className="flex flex-col items-center gap-6">
                <h2 className="w-[819px] h-[64px] font-bold text-white text-[64px] leading-[64px] text-center uppercase">
                  ODOC와 미래를 함께할 파트너
                </h2>
                <p className="w-[465px] h-[24px] font-light text-white text-[24px] leading-[24px] text-center">
                  문의사항을 남겨주시면 확인 후 연락드리겠습니다.
                </p>
              </div>

              <DetailsButton onClick={() => navigate('/contact')} label="문의하기" />
            </div>
          </div>
        </div>
      );
    case 3:
      return (
        <div>
          <div className="max-w-[1200px] w-[1180px] mx-auto mt-12">
            {/* 최상단 컴포넌트 */}
            <div className="flex items-center justify-center gap-3 w-[1196px] h-[162px] mx-auto">
              {products_3.map((product, index) => (
                <React.Fragment key={product.id}>
                  <div className="flex flex-col items-center bg-white shadow-md w-[166px] h-[162px] group">
                    <div className="overflow-hidden">
                      <div
                        className="w-[166px] h-[110px] bg-center bg-cover transition-transform duration-500 ease-in-out group-hover:scale-125"
                        style={{ backgroundImage: `url(${product.image})` }}
                      />
                    </div>
                    <div className="flex justify-center items-center px-6 py-3 w-[166px] h-[52px] transition-colors duration-500 ease-in-out group-hover:bg-black">
                      <p className="text-[14px] font-medium text-center text-black leading-tight break-words w-[70%] transition-colors duration-500 ease-in-out group-hover:text-white">
                        {product.title}
                      </p>
                    </div>
                  </div>

                  {index < products_3.length - 1 && (
                    <div className="flex items-center justify-center">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-[#2E3190]"
                      >
                        <path
                          d="M8 5L16 12L8 19"
                          stroke="currentColor"
                          strokeWidth="3.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>

            {/* 중간 배너파트 */}
            <div className="flex flex-col items-center gap-6 w-[1196px] h-[496px] mx-auto mt-10">
              <h1 className="text-[36px] font-bold text-black uppercase text-center">
                ODOC는 컨설팅 전 AI POC를 진행합니다.
              </h1>

              <div className="relative w-[1196px] h-[420px]">
                <div
                  className="absolute inset-0 bg-cover bg-center"
                  style={{ backgroundImage: `url(${ourworks_mid_banner})` }}
                />
                <div className="absolute inset-0 bg-[rgba(1,1,1,0.12)] backdrop-blur-[32px]"></div>

                <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[870px] h-[224px] flex flex-col items-center gap-6">
                  <h2 className="text-[44px] font-bold text-white uppercase text-center">AI POC란?</h2>

                  <div className="flex flex-col items-center w-[870px]">
                    <p className="text-[20px] font-light text-white text-center">
                      POC는 Proof of Concept의 약자로, <span className="font-bold">구현 가능성을 검증하는 시제품</span>
                      을 의미합니다.
                    </p>
                    <p className="text-[20px] font-light text-white text-center my-4">---</p>
                    <p className="text-[20px] font-light text-white text-center">
                      AI 기반 솔루션 POC는 인공지능을 활용한 솔루션의 개념 검증 단계를 의미합니다.
                    </p>
                    <p className="text-[20px] font-light text-white text-center">
                      AI 기술을 사용하여 특정 비즈니스 문제를 해결할 수 있는지, 성능을 발휘할 수 있는지 실험, 평가하는
                      과정입니다.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <TextSliding
            text={'AI 기술을 활용하여 기업 맞춤형 솔루션, 초개인화 서비스, 데이터 기반 의사결정을 지원합니다'}
            position={1570}
          />

          {/* 본내용 컴포넌트 */}
          <div
            ref={sectionRefs.projects}
            className="flex flex-col items-center w-[1196px] h-[969px] gap-10 mx-auto mt-40"
          >
            <h2 className="text-[36px] font-bold text-black uppercase text-center">주요 프로젝트</h2>

            <div className="flex flex-col items-start w-[1196px] bg-[#E1E2FF] p-10 gap-6">
              <div className="flex flex-col items-start gap-4 w-[658px]">
                <h3 className="text-[36px] font-bold text-black uppercase">화장품 추천 역직구 치빅스(CheapEx)</h3>
                <p className="text-[20px] font-light text-black">
                  K-Beauty 한국 화장품 역직구 플랫폼 치빅스 (CheapEx) 화장품 추천과 데이터 가공.
                </p>
              </div>

              <div className="flex flex-col items-center w-[1116px] h-[709px] gap-6">
                <div className="w-[1116px] h-[709px] bg-white flex justify-center items-center">
                  <img src={aiservice5} alt="CheapEx 화장품 추천 서비스" className="w-[502px] h-[709px] object-cover" />
                </div>
              </div>
            </div>
          </div>

          {/* 공통 하단부 배너 컴포넌트 */}
          <div className="relative w-full h-[640px] my-12 mt-24">
            <img src={ourworks_btm_banner} alt="Background" className="absolute w-full h-full object-cover" />
            <div className="absolute inset-0 bg-black bg-opacity-30 backdrop-blur-lg"></div>

            <div
              className="absolute flex flex-col items-center gap-10 w-[819px] h-[200px]"
              style={{
                left: 'calc(50% - 819px / 2)',
                top: 'calc(50% - 200px / 2)',
              }}
            >
              <div className="flex flex-col items-center gap-6">
                <h2 className="w-[819px] h-[64px] font-bold text-white text-[64px] leading-[64px] text-center uppercase">
                  ODOC와 미래를 함께할 파트너
                </h2>
                <p className="w-[465px] h-[24px] font-light text-white text-[24px] leading-[24px] text-center">
                  문의사항을 남겨주시면 확인 후 연락드리겠습니다.
                </p>
              </div>

              <DetailsButton onClick={() => navigate('/contact')} label="문의하기" />
            </div>
          </div>
        </div>
      );
    case 4:
      return (
        <div>
          <div className="max-w-[1200px] w-[1180px] mx-auto mt-8">
            {/* 최상단 컴포넌트 */}
            <div className="flex flex-col items-center w-[1196px] h-auto gap-8 mx-auto">
              <div className="flex flex-row gap-10 w-[656px] h-[308px]">
                {products_4.map((card, index) => (
                  <div
                    key={index}
                    className="w-[308px] h-[308px] bg-black flex items-center justify-center relative overflow-hidden group"
                  >
                    <img
                      src={upper2}
                      alt="upper2"
                      className="absolute top-4 left-4 w-6 h-6 opacity-0 transform scale-75 transition-all duration-500 ease-in-out group-hover:opacity-100 group-hover:scale-100"
                    />
                    <img
                      src={upper2}
                      alt="upper2"
                      className="absolute bottom-4 right-4 w-6 h-6 opacity-0 transform rotate-180 scale-75 transition-all duration-500 ease-in-out group-hover:opacity-100 group-hover:scale-100"
                    />

                    <div className="absolute inset-0 flex flex-col justify-center items-center transition-all duration-500 ease-in-out">
                      <span className="text-white text-[24px] font-bold uppercase mb-2 transition-all duration-500 ease-in-out group-hover:mb-6">
                        {card.title}
                      </span>
                      <div className="flex flex-col items-center text-[16px] font-light text-white uppercase">
                        {card.details.map((detail, idx) => (
                          <span
                            key={idx}
                            className="transition-all duration-500 ease-in-out mb-1"
                            style={{ transitionTimingFunction: 'ease-in-out' }}
                          >
                            {detail}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <h2 className="text-[36px] font-bold text-black uppercase text-center">
                보유 데이터 (화장품 데이터 셋 50만종 보유)
              </h2>

              <div className="w-[1196px] h-[385px] bg-white flex items-center justify-center">
                <img src={aiservice6} alt="Office Relocation" className="w-[942px] h-[385px] object-cover" />
              </div>
            </div>

            {/* 중간 배너파트 */}
            <div className="flex flex-col items-center gap-6 w-[1196px] h-[496px] mx-auto mt-10">
              <h1 className="text-[36px] font-bold text-black uppercase text-center">
                ODOC는 컨설팅 전 AI POC를 진행합니다.
              </h1>

              <div className="relative w-[1196px] h-[420px]">
                <div
                  className="absolute inset-0 bg-cover bg-center"
                  style={{ backgroundImage: `url(${ourworks_mid_banner})` }}
                />
                <div className="absolute inset-0 bg-[rgba(1,1,1,0.12)] backdrop-blur-[32px]"></div>

                <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[870px] h-[224px] flex flex-col items-center gap-6">
                  <h2 className="text-[44px] font-bold text-white uppercase text-center">AI POC란?</h2>

                  <div className="flex flex-col items-center w-[870px]">
                    <p className="text-[20px] font-light text-white text-center">
                      POC는 Proof of Concept의 약자로, <span className="font-bold">구현 가능성을 검증하는 시제품</span>
                      을 의미합니다.
                    </p>
                    <p className="text-[20px] font-light text-white text-center my-4">---</p>
                    <p className="text-[20px] font-light text-white text-center">
                      AI 기반 솔루션 POC는 인공지능을 활용한 솔루션의 개념 검증 단계를 의미합니다.
                    </p>
                    <p className="text-[20px] font-light text-white text-center">
                      AI 기술을 사용하여 특정 비즈니스 문제를 해결할 수 있는지, 성능을 발휘할 수 있는지 실험, 평가하는
                      과정입니다.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <TextSliding
            text={'AI 기술을 활용하여 기업 맞춤형 솔루션, 초개인화 서비스, 데이터 기반 의사결정을 지원합니다'}
            position={2140}
          />

          {/* 본내용 컴포넌트 */}
          <div ref={sectionRefs.solutions} className="max-w-[1200px] w-[1180px] mx-auto mt-40">
            <div className="flex flex-col items-center w-[1196px] mx-auto space-y-10">
              <div className="w-full flex flex-col items-center space-y-3">
                <h2 className="text-[36px] font-bold text-black uppercase">보유 솔루션</h2>
                <p className="text-[24px] font-light text-black">본사 자체 개발 솔루션 4종 보유</p>
              </div>

              <div className="w-full flex flex-col space-y-10">
                {/* 첫번째 요소 */}
                <div className="flex flex-col justify-center items-start bg-[#E1E2FF] p-10 gap-4 w-[1196px] h-[568px] mx-auto">
                  <div className="flex flex-col items-start w-[480px] h-[80px] gap-6">
                    <h3 className="text-[36px] font-bold text-black uppercase">하이브리드 챗봇</h3>
                    <p className="text-[20px] font-light text-black">
                      LLM, 키워드 추출로 질문의 의도를 파악하는 하이브리드 챗봇
                    </p>
                  </div>

                  <div className="flex flex-row items-start gap-6 w-[1116px] h-[384px] mt-8">
                    <div className="flex flex-row items-start bg-white p-6 gap-6 w-[546px] h-[384px]">
                      <div
                        className="w-[190px] h-[336px] bg-cover bg-center"
                        style={{ backgroundImage: `url(${aiservice7})` }}
                      ></div>
                      <div className="flex flex-col gap-4 w-[284px]">
                        <p className="text-[20px] font-light text-black">
                          생성형 AI, 키워드 분석을 통한 하이브리드 챗봇 솔루션
                        </p>
                        <div className="w-full h-1 bg-black"></div>
                        <div className="flex flex-row items-center gap-2 text-black">
                          <span className="font-bold text-[20px] uppercase">파트너:</span>
                          <span className="text-[20px] font-light">대한작업치료사협회 “아띠”</span>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-row items-start bg-white p-6 gap-6 w-[546px] h-[384px]">
                      <div
                        className="w-[190px] h-[336px] bg-cover bg-center"
                        style={{ backgroundImage: `url(${aiservice8})` }}
                      ></div>
                      <div className="flex flex-col gap-4 w-[284px]">
                        <p className="text-[20px] font-light text-black">
                          생성형 AI와 decision tree를 이용한 뷰티 특화 챗봇
                        </p>
                        <div className="w-full h-1 bg-black"></div>
                        <div className="flex flex-row items-center gap-2 text-black">
                          <span className="font-bold text-[20px] uppercase">파트너:</span>
                          <span className="text-[20px] font-light">스윗솔루션 서비스 “스윗”</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* 두번째 요소 */}
                <div className="flex flex-col justify-center items-start bg-[#E1E2FF] p-10 gap-4 w-[1196px] h-[568px] mx-auto">
                  <div className="flex flex-col items-start w-[480px] h-[80px] gap-6">
                    <h3 className="text-[36px] font-bold text-black uppercase">소비재 상품 추천 알고리즘</h3>
                    <p className="text-[20px] font-light text-black">자연어처리 솔루션 - 추천 솔루션</p>
                  </div>

                  <div className="flex flex-row items-start gap-6 w-[1116px] h-[384px] mt-8">
                    <div className="flex flex-row items-start bg-white p-6 gap-6 w-[546px] h-[384px]">
                      <div
                        className="w-[190px] h-[336px] bg-cover bg-center"
                        style={{ backgroundImage: `url(${aiservice9})` }}
                      ></div>
                      <div className="flex flex-col gap-4 w-[284px]">
                        <p className="text-[20px] font-light text-black">
                          자사에서 보유한 2,000,000 건 이상의 뷰티 도메인 데이터를 학습한 솔루션
                        </p>
                        <div className="w-full h-1 bg-black"></div>
                        <div className="flex flex-row items-center gap-2 text-black">
                          <span className="font-bold text-[20px] uppercase">파트너:</span>
                          <span className="text-[20px] font-light">대한작업치료사협회 “아띠”</span>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-row items-start bg-white p-6 gap-6 w-[546px] h-[384px]">
                      <div
                        className="w-[190px] h-[336px] bg-cover bg-center"
                        style={{ backgroundImage: `url(${aiservice10})` }}
                      ></div>
                      <div className="flex flex-col gap-4 w-[284px]">
                        <p className="text-[20px] font-light text-black">
                          역직구 플랫폼 "CheapEX" 內 화장품 추천 서비스 개발
                        </p>
                        <div className="w-full h-1 bg-black"></div>
                        <div className="flex flex-row items-center gap-2 text-black">
                          <span className="font-bold text-[20px] uppercase">파트너:</span>
                          <span className="text-[20px] font-light">제이크이엔엠 플랫폼 치빅스</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* 세번째 요소 */}
                <div className="flex flex-col justify-center items-start bg-[#E1E2FF] p-10 gap-4 w-[1196px] mx-auto">
                  <div className="flex flex-col items-start gap-6">
                    <h2 className="text-[36px] font-bold text-black uppercase">진성리뷰 판독 솔루션</h2>
                    <p className="text-[20px] font-light text-black">
                      자연어처리 솔루션으로, 리뷰 텍스트 내 형태소 분석으로 진성 리뷰를 판독하는 AI 솔루션.
                    </p>
                  </div>

                  <div className="flex flex-col justify-center items-center w-[1116px] h-[394px]">
                    <div className="relative w-full h-[250px] bg-white flex items-center justify-center">
                      <img src={aiservice11} alt="AI Review Solution" className="w-[360px] h-[250px] object-cover" />
                    </div>
                    <div className="flex flex-col text-black w-full mt-4">
                      <p className="text-[24px] font-light">
                        01. 리뷰(비정형데이터)에서 진성리뷰를 판독하는 AI 솔루션.
                      </p>
                      <p className="text-[24px] font-light">
                        02. 정확도 0.8, 정밀도 0.9, 재현율 0.8, F1점수 0.8의 높은 성능.
                      </p>
                      <p className="text-[24px] font-light">
                        자사 플랫폼 <span className="font-bold">‘키인’</span>적용.
                      </p>
                      <p className="text-[24px] font-light">CJ 올리브영 협의 중 - 한국인공지능원 테스트 완료.</p>
                    </div>
                  </div>
                </div>

                {/* 네번째 요소 */}
                <div className="flex flex-col justify-center items-start bg-[#E1E2FF] p-10 gap-4 w-[1196px] mx-auto">
                  <div className="flex flex-col items-start gap-6">
                    <h2 className="text-[36px] font-bold text-black uppercase">관상 솔루션</h2>
                    <p className="text-[20px] font-light text-black">
                      컴퓨터비전 솔루션으로, 사용자의 얼굴 이미지를 분석하여 관상 파악, 성격 예측, 극중 배역을 제안.
                    </p>
                  </div>

                  <div className="flex flex-col justify-center items-center w-[1116px] h-[394px]">
                    <div className="relative w-full h-[255px] bg-white flex items-center justify-center">
                      <img src={aiservice12} alt="AI Review Solution" className="w-[360px] h-[255px] object-cover" />
                    </div>
                    <div className="flex flex-col text-black w-full mt-4">
                      <p className="text-[24px] font-light">
                        01. 동양인 얼굴 3,000건 이미지와 직접 생성한 관상 데이터 100건을 학습한 솔루션.
                      </p>
                      <p className="text-[24px] font-light">02. 관상의 요소에 따라 각각 다른 이미지 생성.</p>
                      <p className="text-[24px] font-light">UNC 갤러리 &lt;그림 깨우기: 크리스토프 루크레베를레&gt;.</p>
                      <p className="text-[24px] font-light">관객 참여형 기술 개발 보급.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* 공통 하단부 배너 컴포넌트 */}
          <div className="relative w-full h-[640px] my-12">
            <img src={ourworks_btm_banner} alt="Background" className="absolute w-full h-full object-cover" />
            <div className="absolute inset-0 bg-black bg-opacity-30 backdrop-blur-lg"></div>

            <div
              className="absolute flex flex-col items-center gap-10 w-[819px] h-[200px]"
              style={{
                left: 'calc(50% - 819px / 2)',
                top: 'calc(50% - 200px / 2)',
              }}
            >
              <div className="flex flex-col items-center gap-6">
                <h2 className="w-[819px] h-[64px] font-bold text-white text-[64px] leading-[64px] text-center uppercase">
                  원데이원과 미래를 함께할 파트너
                </h2>
                <p className="w-[465px] h-[24px] font-light text-white text-[24px] leading-[24px] text-center">
                  문의사항을 남겨주시면 확인 후 연락드리겠습니다.
                </p>
              </div>

              <DetailsButton onClick={() => navigate('/contact')} label="문의하기" />
            </div>
          </div>
        </div>
      );
    default:
      return null;
  }
};

const AiServicePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const initialTab = parseInt(query.get('tab')) || 1;
  const [activeTab, setActiveTab] = useState(initialTab);

  const sectionRefs = {
    projects: useRef(null),
    solutions: useRef(null),
  };

  const handleScrollToSection = () => {
    const targetRef = activeTab === 4 ? sectionRefs.solutions : sectionRefs.projects;

    if (targetRef.current) {
      window.scrollTo({
        top: targetRef.current.offsetTop - 100,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    setActiveTab(initialTab);
  }, [initialTab]);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    navigate(`?tab=${tabId}`);
  };

  return (
    <div className="w-full mx-auto bg-[#F1F4F9]">
      <div
        className="relative w-full h-[558px] bg-cover bg-center"
        style={{
          aspectRatio: '1440 / 558',
          backgroundImage: `url(${aiservice})`,
        }}
      >
        <div className="absolute inset-0 bg-[rgba(1,1,1,0.24)] backdrop-blur-[1px]"></div>

        <div className="absolute left-[4rem] top-1/2 -translate-y-1/2 w-[442px] h-[398px] flex flex-col justify-between items-start p-0 gap-10">
          <div className="flex flex-col items-start gap-2 w-[322px] h-[120px]">
            <h1 className="uppercase text-white text-[80px] font-bold leading-none">AI 서비스</h1>
            <p className="text-white text-[24px] font-light">{tabs.find((tab) => tab.id === activeTab).label}</p>
          </div>

          <div className="flex flex-col gap-10 w-[442px]">
            <div className="flex flex-col p-6 gap-1 bg-[rgba(1,1,1,0.12)] border border-white backdrop-blur-md w-full h-[192px]">
              <p className="text-white text-[16px] font-thin uppercase">{descriptions[activeTab - 1]}</p>
              <button
                onClick={handleScrollToSection}
                className="relative w-[120px] px-2 py-3 mt-4 text-white text-base bg-[rgba(1,1,1,0.12)] border border-white rounded-full overflow-hidden group"
              >
                <div
                  className="absolute inset-0 w-0 bg-[#2E3190] transition-all duration-500 ease-in-out group-hover:w-full"
                  style={{ zIndex: 1 }}
                ></div>
                <span className="relative z-10 transition-colors duration-500 group-hover:text-white">자세히 보기</span>
              </button>
            </div>
          </div>
        </div>

        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 text-center">
          <div className="text-sm font-medium text-white mb-2">아래로 스크롤</div>
          <div className="relative w-[6px] h-[9px] mx-auto mt-1">
            <div className="w-[6px] h-[6px] bg-white rounded-full"></div>
            <div className="w-[1px] h-[20px] bg-[rgba(255,255,255,0.6)] mx-auto"></div>
          </div>
        </div>
      </div>

      <div className="max-w-[1200px] w-[1180px] mx-auto mt-4">
        <TabMenu tabs={tabs} activeTab={activeTab} onTabClick={handleTabClick} />

        {activeTab !== 4 && (
          <div className="w-[1196px] h-[36px] flex items-center justify-center text-[36px] font-bold uppercase text-black">
            주요 서비스
          </div>
        )}
      </div>

      <TabContent activeTab={activeTab} sectionRefs={sectionRefs} />
    </div>
  );
};

export default AiServicePage;
