import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TabMenu from '../components/Tabmenu';
import TextSliding from '../components/TextSliding';
import {
  newsBanner,
  ourworks_btm_banner,
  aisystem,
  aisystemTab_1,
  aisystemTab_2,
  aisystemTab_3,
  aisystem_0_0,
  aisystem_0_1,
  aisystem_0_2,
  aisystem_0_3,
  aisystem_0_4,
  aisystem_0_5,
  aisystemIcon_1,
  aisystemIcon_2,
  aisystemIcon_3,
  upper,
  arrow,
} from '../assets/images/Images';
import TabBanner from '../components/TabBanner';
import DetailsButton from '../components/DetailsButton';
import BannerWithOverlay from '../components/BannerWithOverlay';
import { items_3, boxData } from '../constants/aiSystemData';

const tabs = [
  { id: 1, label: 'AI 서비스 개발' },
  { id: 2, label: 'AI 챗봇 개발' },
  { id: 3, label: 'AI 에이전트 개발' },
];

const imageUrls_0 = [aisystem_0_1, aisystem_0_2, aisystem_0_3];

const TabContent = ({ activeTab }) => {
  const [selectedBoxId, setSelectedBoxId] = useState(null);

  const handleBoxClick = (box) => {
    setSelectedBoxId((prevSelectedBoxId) => {
      if (prevSelectedBoxId === box.id) {
        return null;
      } else {
        return box.id;
      }
    });
  };

  useEffect(() => {
    setSelectedBoxId(null);
  }, [activeTab]);

  const selectedBox = boxData.find((box) => box.id === selectedBoxId);

  switch (activeTab) {
    case 1:
      return (
        <div>
          <div className="w-full mx-auto mt-12">
            {/* 본내용 컴포넌트 */}
            <div className="flex flex-col items-center px-32 py-16 gap-10 w-full bg-[#E1E2FF] relative">
              <div className="flex flex-col items-start w-[1196px]">
                <h2 className="text-black font-bold text-[36px] uppercase">산업별 관련 사례 연구</h2>
                <p className="text-black font-light text-[20px]">
                  산업별 맞춤 사례 연구를 통해 다양한 비즈니스 인사이트와 성공 전략을 제공합니다
                </p>
              </div>

              {/* 본내용 가변 컴포넌트 */}
              <div className="flex w-[1196px] h-[480px]">
                <div className="flex flex-col">
                  <div className="flex w-full">
                    <div className="flex flex-col items-start w-[120px] h-full p-0">
                      {boxData.slice(0, 3).map((box, index) => (
                        <div
                          key={box.id}
                          className={`relative w-[120px] h-[120px] bg-black border border-white cursor-pointer group`}
                          onClick={() => handleBoxClick(box)}
                        >
                          <div className="absolute w-full h-full bg-[rgba(1,1,1,0.12)]"></div>
                          <div
                            className={`flex flex-col absolute w-full h-5 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 font-bold text-white flex items-center justify-center uppercase transition-all duration-500 ${
                              selectedBoxId === 7 ? 'text-xl' : 'text-lg group-hover:text-2xl'
                            }`}
                          >
                            {box.tag}
                            {selectedBoxId === box.id && <img className="mt-2" src={arrow} alt="arrow icon" />}
                          </div>
                          {selectedBoxId !== box.id && (
                            <>
                              <div className="absolute w-3.5 h-3 left-3 top-3 opacity-0 group-hover:opacity-100 transition-opacity duration-500">
                                <img src={upper} alt="upper icon" />
                              </div>
                              <div className="absolute w-3.5 h-3 right-3 bottom-3 transform rotate-180 opacity-0 group-hover:opacity-100 transition-opacity duration-500">
                                <img src={upper} alt="upper icon" />
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                    </div>

                    <div className="flex flex-col items-start w-[120px] h-full p-0">
                      {boxData.slice(3, 6).map((box, index) => (
                        <div
                          key={box.id}
                          className={`relative w-[120px] h-[120px] bg-black border border-white cursor-pointer group`}
                          onClick={() => handleBoxClick(box)}
                        >
                          <div className="absolute w-full h-full bg-[rgba(1,1,1,0.12)]"></div>
                          <div
                            className={`flex flex-col absolute w-full h-5 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 font-bold text-white flex items-center justify-center uppercase transition-all duration-500 ${
                              selectedBoxId === 7 ? 'text-xl' : 'text-lg group-hover:text-2xl'
                            }`}
                          >
                            {box.tag}
                            {selectedBoxId === box.id && <img className="mt-2" src={arrow} alt="arrow icon" />}
                          </div>
                          {selectedBoxId !== box.id && (
                            <>
                              <div className="absolute w-3.5 h-3 left-3 top-3 opacity-0 group-hover:opacity-100 transition-opacity duration-500">
                                <img src={upper} alt="upper icon" />
                              </div>
                              <div className="absolute w-3.5 h-3 right-3 bottom-3 transform rotate-180 opacity-0 group-hover:opacity-100 transition-opacity duration-500">
                                <img src={upper} alt="upper icon" />
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>

                  <div
                    className={`relative w-[240px] h-[120px] bg-black border border-white cursor-pointer group`}
                    onClick={() => handleBoxClick(boxData[6])}
                  >
                    <div className="absolute w-full h-full bg-[rgba(1,1,1,0.12)]"></div>
                    <div
                      className={`flex flex-col absolute w-full h-5 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 font-bold text-white flex items-center justify-center uppercase transition-all duration-500 ${
                        selectedBoxId === 7 ? 'text-xl' : 'text-lg group-hover:text-2xl'
                      }`}
                    >
                      뷰티/헬스
                      {selectedBoxId === 7 && <img className="mt-2" src={arrow} alt="arrow icon" />}
                    </div>
                    {selectedBoxId !== 7 && (
                      <>
                        <div className="absolute w-3.5 h-3 left-3 top-3 opacity-0 group-hover:opacity-100 transition-opacity duration-500">
                          <img src={upper} alt="upper icon" />
                        </div>
                        <div className="absolute w-3.5 h-3 right-3 bottom-3 transform rotate-180 opacity-0 group-hover:opacity-100 transition-opacity duration-500">
                          <img src={upper} alt="upper icon" />
                        </div>
                      </>
                    )}
                  </div>
                </div>

                {selectedBox ? (
                  <div className="relative w-[956px] h-full bg-cover bg-center overflow-hidden shadow-lg">
                    <div
                      className="absolute inset-0 bg-cover bg-center"
                      style={{
                        backgroundImage: `url(${selectedBox.image})`,
                        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                      }}
                    ></div>

                    <div className="absolute inset-0 bg-[rgba(1,1,1,0.12)] backdrop-blur-sm"></div>

                    <div className="absolute top-10 left-10 flex flex-col w-[320px]">
                      <p className="text-white text-[36px] font-bold uppercase">{selectedBox.tag}</p>
                      <p className="text-white text-[24px] font-thin">{selectedBox.details}</p>
                    </div>

                    <div className="absolute bottom-10 left-10 flex gap-6 w-[828px] h-[252px]">
                      {selectedBox.products.map((product, index) => (
                        <div
                          key={index}
                          className="flex flex-col h-[252px] p-6 gap-2 bg-[rgba(1,1,1,0.12)] border border-white backdrop-blur-lg shadow-md"
                          style={{ width: product.width }}
                        >
                          <p className="text-white text-[14px] font-semibold uppercase">{product.title}</p>
                          <div
                            className="w-full h-full bg-cover bg-center"
                            style={{ backgroundImage: `url(${product.image})` }}
                          ></div>
                          <p className="text-white text-[14px] font-semibold uppercase">
                            파트너:&nbsp;&nbsp;<span className="font-light">{product.partner}</span>
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="relative w-[956px] h-full bg-cover bg-center overflow-hidden shadow-lg">
                    <div
                      className="absolute inset-0 bg-cover bg-center"
                      style={{
                        backgroundImage: `url(${aisystem_0_0})`,
                        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                      }}
                    ></div>

                    <div className="absolute inset-0 backdrop-blur-sm"></div>

                    <div className="absolute top-10 left-10 flex flex-col w-[320px]">
                      <p className="text-white text-[36px] font-bold uppercase">원데이원 커뮤니케이션</p>
                      <p className="text-white text-[24px] font-thin">실력 - 진정성</p>
                    </div>

                    <div className="absolute bottom-10 left-10 flex gap-6 w-[828px] h-[252px]">
                      {['모두가 행복한 기술개발', '글로벌 No1. 크리에이티브 AI 파트너', '실력과 진정성'].map(
                        (title, index) => (
                          <div
                            key={index}
                            className="flex flex-col w-[260px] h-[252px] p-6 gap-4 bg-[rgba(1,1,1,0.12)] border border-white backdrop-blur-lg shadow-md transform"
                          >
                            <p className="text-white text-[14px] font-light uppercase">{title}</p>
                            <div
                              className="w-full h-[174px] bg-cover bg-center"
                              style={{ backgroundImage: `url(${imageUrls_0[index]})` }}
                            ></div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    case 2:
      return (
        <div>
          <div className="w-full mx-auto mt-12">
            {/* 본내용 컴포넌트 */}
            <div className="flex flex-col items-center px-32 py-16 gap-10 w-full bg-[#E1E2FF] relative">
              <div className="flex flex-col items-start w-[1196px]">
                <h2 className="text-black font-bold text-[36px] uppercase">산업별 관련 사례 연구</h2>
                <p className="text-black font-light text-[20px]">
                  산업별 맞춤 사례 연구를 통해 다양한 비즈니스 인사이트와 성공 전략을 제공합니다
                </p>
              </div>

              {/* 본내용 가변 컴포넌트 */}
              <div className="flex w-[1196px] h-[480px]">
                <div className="flex flex-col">
                  <div className="flex w-full">
                    <div className="flex flex-col items-start w-[240px] h-full p-0">
                      {boxData.slice(7, 9).map((box) => (
                        <div
                          key={box.id}
                          className={`relative w-[240px] h-[240px] bg-black border border-white cursor-pointer group`}
                          onClick={() => handleBoxClick(box)}
                        >
                          <div className="absolute w-full h-full bg-[rgba(1,1,1,0.12)]"></div>
                          <div
                            className={`flex flex-col absolute w-full h-5 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 font-bold text-white flex items-center justify-center uppercase transition-all duration-500 ${
                              selectedBoxId === box.id ? 'text-3xl' : 'text-2xl group-hover:text-3xl'
                            }`}
                          >
                            {box.tag}
                            {selectedBoxId === box.id && <img className="mt-4" src={arrow} alt="arrow icon" />}
                          </div>

                          {selectedBoxId !== box.id && (
                            <>
                              <div className="absolute w-3.5 h-3 left-3 top-3 opacity-0 group-hover:opacity-100 transition-opacity duration-500">
                                <img src={upper} alt="upper icon" />
                              </div>
                              <div className="absolute w-3.5 h-3 right-3 bottom-3 transform rotate-180 opacity-0 group-hover:opacity-100 transition-opacity duration-500">
                                <img src={upper} alt="upper icon" />
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {selectedBox ? (
                  <div className="relative w-[956px] h-full bg-cover bg-center overflow-hidden shadow-lg">
                    <div
                      className="absolute inset-0 bg-cover bg-center"
                      style={{
                        backgroundImage: `url(${selectedBox.image})`,
                        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                      }}
                    ></div>

                    <div className="absolute inset-0 bg-[rgba(1,1,1,0.12)] backdrop-blur-sm"></div>

                    <div className="absolute top-10 left-10 flex flex-col w-[320px]">
                      <p className="text-white text-[36px] font-bold uppercase">{selectedBox.tag}</p>
                      <p className="text-white text-[24px] font-thin">{selectedBox.details}</p>
                    </div>

                    <div className="absolute bottom-10 left-10 flex gap-6 w-[828px] h-[252px]">
                      {selectedBox.products.map((product, index) => (
                        <div
                          key={index}
                          className="flex flex-col h-[252px] p-6 gap-2 bg-[rgba(1,1,1,0.12)] border border-white backdrop-blur-lg shadow-md"
                          style={{ width: product.width }}
                        >
                          <p className="text-white text-[14px] font-semibold uppercase">{product.title}</p>
                          <div
                            className="w-full h-full bg-cover bg-center"
                            style={{ backgroundImage: `url(${product.image})` }}
                          ></div>
                          <p className="text-white text-[14px] font-semibold uppercase">
                            파트너:&nbsp;&nbsp;<span className="font-light">{product.partner}</span>
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="relative w-[956px] h-full bg-cover bg-center overflow-hidden shadow-lg">
                    <div
                      className="absolute inset-0 bg-cover bg-center"
                      style={{
                        backgroundImage: `url(${aisystem_0_0})`,
                        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                      }}
                    ></div>

                    <div className="absolute inset-0 backdrop-blur-sm"></div>

                    <div className="absolute top-10 left-10 flex flex-col w-[320px]">
                      <p className="text-white text-[36px] font-bold uppercase">원데이원 커뮤니케이션</p>
                      <p className="text-white text-[24px] font-thin">실력 - 진정성</p>
                    </div>

                    <div className="absolute bottom-10 left-10 flex gap-6 w-[828px] h-[252px]">
                      {['모두가 행복한 기술개발', '글로벌 No1. 크리에이티브 AI 파트너', '실력과 진정성'].map(
                        (title, index) => (
                          <div
                            key={index}
                            className="flex flex-col w-[260px] h-[252px] p-6 gap-4 bg-[rgba(1,1,1,0.12)] border border-white backdrop-blur-lg shadow-md transform"
                          >
                            <p className="text-white text-[14px] font-light uppercase">{title}</p>
                            <div
                              className="w-full h-[174px] bg-cover bg-center"
                              style={{ backgroundImage: `url(${imageUrls_0[index]})` }}
                            ></div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    case 3:
      return (
        <div>
          {/* 최상단 컴포넌트 */}
          <div className="flex flex-col items-center w-full h-full bg-[#E1E2FF] py-16 px-32 gap-10">
            <div className="flex flex-col items-start w-[1196px] gap-6">
              <p className="text-[36px] font-bold text-black uppercase">간단한 사용 방법</p>
              <p className="text-[20px] font-light text-black">
                프롬프트 입력만으로 전문적인 컨텐츠를 자동 생성하는 AI솔루션
              </p>
            </div>

            <div className="flex w-[1196px] gap-y-4">
              <div className="w-[240px] bg-black relative flex flex-col justify-between p-10 gap-6">
                {[
                  { number: '01.', text1: '원하는 프로젝트', text2: '선택', icon: aisystemIcon_1 },
                  { number: '02.', text1: '필요한 정보 입력', text2: '', icon: aisystemIcon_2 },
                  { number: '03.', text1: 'AI가 자동으로', text2: '콘텐츠 생성', icon: aisystemIcon_3 },
                ].map((step, index) => (
                  <div key={index} className="flex flex-col gap-4">
                    <div className="flex justify-between items-center w-[160px]">
                      <span className="text-[36px] font-bold text-white">{step.number}</span>
                      <img src={step.icon} alt={`aisystemIcon_${index + 1}`} className="w-6 h-6" />
                    </div>
                    <div className="flex flex-col gap-1 text-white">
                      <p className="text-[20px] font-light">{step.text1}</p>
                      {step.text2 && <p className="text-[20px] font-light">{step.text2}</p>}
                    </div>
                    {index < 2 && <hr className="w-[154px] border border-white" />}
                  </div>
                ))}
              </div>

              <div
                className="relative w-[956px] bg-cover bg-center"
                style={{ backgroundImage: `url(${aisystem_0_4})` }}
              >
                <div className="absolute inset-0 bg-[rgba(1,1,1,0.12)] backdrop-blur-md"></div>
                <div className="absolute left-10 top-1/2 transform -translate-y-1/2 w-[682px] bg-[rgba(1,1,1,0.12)] border border-white p-6 backdrop-blur-md">
                  <div className="w-full h-[352px] bg-white" style={{ backgroundImage: `url(${aisystem_0_5})` }}></div>
                </div>
              </div>
            </div>
          </div>

          {/* 배너 파트 */}
          <div className="max-w-[1200px] w-[1180px] mx-auto mt-12">
            <BannerWithOverlay
              backgroundImage={newsBanner}
              title="창의적인 AI로 산업을 혁신합니다"
              subtitle="모두가 행복한 개발을 목표로 하는 글로벌 No.1 크리에이티브 파트너"
            />
          </div>

          {/* 최하단 컴포넌트 */}
          <div className="flex flex-col items-center p-16 gap-10 w-full bg-[#E1E2FF] mt-12">
            <div className="flex flex-col items-start w-[1196px] gap-4">
              <p className="text-black font-bold text-[36px]">산업별 관련 사례 연구</p>
              <p className="text-black font-light text-[20px]">
                주요 프로젝트 기능은 효율적 작업과 목표 달성을 지원합니다
              </p>
            </div>

            {/* 바둑판 컴포넌트 */}
            <div className="flex flex-row w-[1196px] h-[642px]">
              {/* 1-1번째 열 (1,2,5,6,7) */}
              <div className="flex flex-col w-[594px]">
                {/* 1,2 */}
                <div className="flex flex-row h-[214px]">
                  {/* 1 */}
                  <div
                    className="relative overflow-hidden group bg-cover bg-center shadow-lg border border-white w-[270px] h-full"
                    style={{ backgroundImage: `url(${items_3[0].image})` }}
                  >
                    <div className="absolute inset-0 flex items-center justify-center bg-[rgba(1,1,1,0.1)] backdrop-blur-sm transition-all duration-500">
                      <div className="text-[36px] font-bold text-white">01.</div>
                    </div>
                    <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white opacity-0 group-hover:opacity-100 transition-opacity duration-500 bg-black z-20">
                      <img src={`${items_3[0].icon}`} alt={`${items_3[0].icon}`} className="mt-4 mb-4" />
                      <div className="text-[16px] font-bold uppercase">{items_3[0].title1}</div>
                      {items_3[0].title2 && <div className="text-[16px] font-bold uppercase">{items_3[0].title2}</div>}
                    </div>
                    <div className="absolute inset-0 slide-bg"></div>
                  </div>
                  {/* 2 */}
                  <div
                    className="relative overflow-hidden group bg-cover bg-center shadow-lg border border-white w-[324px] h-full"
                    style={{ backgroundImage: `url(${items_3[1].image})` }}
                  >
                    <div className="absolute inset-0 flex items-center justify-center bg-[rgba(1,1,1,0.1)] backdrop-blur-sm transition-all duration-500">
                      <div className="text-[36px] font-bold text-white">02.</div>
                    </div>
                    <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white opacity-0 group-hover:opacity-100 transition-opacity duration-500 bg-black z-20">
                      <img src={`${items_3[1].icon}`} alt={`${items_3[1].icon}`} className="mt-4 mb-4" />
                      <div className="text-[16px] font-bold uppercase">{items_3[1].title1}</div>
                      {items_3[1].title2 && <div className="text-[16px] font-bold uppercase">{items_3[1].title2}</div>}
                    </div>
                    <div className="absolute inset-0 slide-bg"></div>
                  </div>
                </div>
                {/* 5,6,7 */}
                <div className="flex flex-row h-[428px]">
                  {/* 5,6 */}
                  <div className="flex flex-col w-[410px]">
                    {/* 5 */}
                    <div
                      className="relative overflow-hidden group bg-cover bg-center shadow-lg border border-white w-[410px] h-full"
                      style={{ backgroundImage: `url(${items_3[4].image})` }}
                    >
                      <div className="absolute inset-0 flex items-center justify-center bg-[rgba(1,1,1,0.1)] backdrop-blur-sm transition-all duration-500">
                        <div className="text-[36px] font-bold text-white">05.</div>
                      </div>
                      <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white opacity-0 group-hover:opacity-100 transition-opacity duration-500 bg-black z-20">
                        <img src={`${items_3[4].icon}`} alt={`${items_3[4].icon}`} className="mt-4 mb-4" />
                        <div className="text-[16px] font-bold uppercase">{items_3[4].title1}</div>
                        {items_3[4].title2 && (
                          <div className="text-[16px] font-bold uppercase">{items_3[4].title2}</div>
                        )}
                      </div>
                      <div className="absolute inset-0 slide-bg"></div>
                    </div>
                    {/* 6 */}
                    <div
                      className="relative overflow-hidden group bg-cover bg-center shadow-lg border border-white w-[410px] h-full"
                      style={{ backgroundImage: `url(${items_3[5].image})` }}
                    >
                      <div className="absolute inset-0 flex items-center justify-center bg-[rgba(1,1,1,0.1)] backdrop-blur-sm transition-all duration-500">
                        <div className="text-[36px] font-bold text-white">06.</div>
                      </div>
                      <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white opacity-0 group-hover:opacity-100 transition-opacity duration-500 bg-black z-20">
                        <img src={`${items_3[5].icon}`} alt={`${items_3[5].icon}`} className="mt-4 mb-4" />
                        <div className="text-[16px] font-bold uppercase">{items_3[5].title1}</div>
                        {items_3[5].title2 && (
                          <div className="text-[16px] font-bold uppercase">{items_3[5].title2}</div>
                        )}
                      </div>
                      <div className="absolute inset-0 slide-bg"></div>
                    </div>
                  </div>
                  {/* 7 */}
                  <div
                    className="relative overflow-hidden group bg-cover bg-center shadow-lg border border-white w-[184px] h-full"
                    style={{ backgroundImage: `url(${items_3[6].image})` }}
                  >
                    <div className="absolute inset-0 flex items-center justify-center bg-[rgba(1,1,1,0.1)] backdrop-blur-sm transition-all duration-500">
                      <div className="text-[36px] font-bold text-white">07.</div>
                    </div>
                    <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white opacity-0 group-hover:opacity-100 transition-opacity duration-500 bg-black z-20">
                      <img src={`${items_3[6].icon}`} alt={`${items_3[6].icon}`} className="mt-4 mb-4" />
                      <div className="text-[16px] font-bold uppercase">{items_3[6].title1}</div>
                      {items_3[6].title2 && <div className="text-[16px] font-bold uppercase">{items_3[6].title2}</div>}
                    </div>
                    <div className="absolute inset-0 slide-bg"></div>
                  </div>
                </div>
              </div>

              {/* 1-2번째 열 (3,8,9) */}
              <div className="flex flex-col w-[214px]">
                {/* 3 */}
                <div
                  className="relative overflow-hidden group bg-cover bg-center shadow-lg border border-white w-[214px] h-full"
                  style={{ backgroundImage: `url(${items_3[2].image})` }}
                >
                  <div className="absolute inset-0 flex items-center justify-center bg-[rgba(1,1,1,0.1)] backdrop-blur-sm transition-all duration-500">
                    <div className="text-[36px] font-bold text-white">03.</div>
                  </div>
                  <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white opacity-0 group-hover:opacity-100 transition-opacity duration-500 bg-black z-20">
                    <img src={`${items_3[2].icon}`} alt={`${items_3[2].icon}`} className="mt-4 mb-4" />
                    <div className="text-[16px] font-bold uppercase">{items_3[2].title1}</div>
                    {items_3[2].title2 && <div className="text-[16px] font-bold uppercase">{items_3[2].title2}</div>}
                  </div>
                  <div className="absolute inset-0 slide-bg"></div>
                </div>
                {/* 8 */}
                <div
                  className="relative overflow-hidden group bg-cover bg-center shadow-lg border border-white w-[214px] h-full"
                  style={{ backgroundImage: `url(${items_3[7].image})` }}
                >
                  <div className="absolute inset-0 flex items-center justify-center bg-[rgba(1,1,1,0.1)] backdrop-blur-sm transition-all duration-500">
                    <div className="text-[36px] font-bold text-white">08.</div>
                  </div>
                  <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white opacity-0 group-hover:opacity-100 transition-opacity duration-500 bg-black z-20">
                    <img src={`${items_3[7].icon}`} alt={`${items_3[7].icon}`} className="mt-4 mb-4" />
                    <div className="text-[16px] font-bold uppercase">{items_3[7].title1}</div>
                    {items_3[7].title2 && <div className="text-[16px] font-bold uppercase">{items_3[7].title2}</div>}
                  </div>
                  <div className="absolute inset-0 slide-bg"></div>
                </div>
                {/* 9 */}
                <div
                  className="relative overflow-hidden group bg-cover bg-center shadow-lg border border-white w-[214px] h-full"
                  style={{ backgroundImage: `url(${items_3[8].image})` }}
                >
                  <div className="absolute inset-0 flex items-center justify-center bg-[rgba(1,1,1,0.1)] backdrop-blur-sm transition-all duration-500">
                    <div className="text-[36px] font-bold text-white">09.</div>
                  </div>
                  <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white opacity-0 group-hover:opacity-100 transition-opacity duration-500 bg-black z-20">
                    <img src={`${items_3[8].icon}`} alt={`${items_3[8].icon}`} className="mt-4 mb-4" />
                    <div className="text-[16px] font-bold uppercase">{items_3[8].title1}</div>
                    {items_3[8].title2 && <div className="text-[16px] font-bold uppercase">{items_3[8].title2}</div>}
                  </div>
                  <div className="absolute inset-0 slide-bg"></div>
                </div>
              </div>

              {/* 1-2번째 열 (4,10) */}
              <div className="flex flex-col w-[388px]">
                {/* 4 */}
                <div
                  className="relative overflow-hidden group bg-cover bg-center shadow-lg border border-white w-[388px] h-full"
                  style={{ backgroundImage: `url(${items_3[3].image})` }}
                >
                  <div className="absolute inset-0 flex items-center justify-center bg-[rgba(1,1,1,0.1)] backdrop-blur-sm transition-all duration-500">
                    <div className="text-[36px] font-bold text-white">04.</div>
                  </div>
                  <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white opacity-0 group-hover:opacity-100 transition-opacity duration-500 bg-black z-20">
                    <img src={`${items_3[3].icon}`} alt={`${items_3[3].icon}`} className="mt-4 mb-4" />
                    <div className="text-[16px] font-bold uppercase">{items_3[3].title1}</div>
                    {items_3[3].title2 && <div className="text-[16px] font-bold uppercase">{items_3[3].title2}</div>}
                  </div>
                  <div className="absolute inset-0 slide-bg"></div>
                </div>
                {/* 10 */}
                <div
                  className="relative overflow-hidden group bg-cover bg-center shadow-lg border border-white w-[388px] h-full"
                  style={{ backgroundImage: `url(${items_3[9].image})` }}
                >
                  <div className="absolute inset-0 flex items-center justify-center bg-[rgba(1,1,1,0.1)] backdrop-blur-sm transition-all duration-500">
                    <div className="text-[36px] font-bold text-white">10.</div>
                  </div>
                  <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white opacity-0 group-hover:opacity-100 transition-opacity duration-500 bg-black z-20">
                    <img src={`${items_3[9].icon}`} alt={`${items_3[9].icon}`} className="mt-4 mb-4" />
                    <div className="text-[16px] font-bold uppercase">{items_3[9].title1}</div>
                    {items_3[9].title2 && <div className="text-[16px] font-bold uppercase">{items_3[9].title2}</div>}
                  </div>
                  <div className="absolute inset-0 slide-bg"></div>
                </div>
              </div>
              <style jsx>{`
                .slide-bg {
                  background-color: black;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: -100%;
                  z-index: 5;
                  transition: left 0.5s ease;
                }

                .group:hover .slide-bg {
                  left: 0;
                  transition: left 0.5s ease;
                }
              `}</style>
            </div>
          </div>
        </div>
      );
    default:
      return null;
  }
};

const AiSystemPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const initialTab = parseInt(query.get('tab')) || 1;
  const [activeTab, setActiveTab] = useState(initialTab);

  const sectionRefs = {
    services: useRef(null),
  };

  const handleScrollToSection = () => {
    if (sectionRefs.services.current) {
      window.scrollTo({
        top: sectionRefs.services.current.offsetTop - 100,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    setActiveTab(initialTab);
  }, [initialTab]);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    navigate(`?tab=${tabId}`);
  };

  return (
    <div className="w-full mx-auto bg-[#F1F4F9]">
      <div
        className="relative w-full h-[558px] bg-cover bg-center"
        style={{
          aspectRatio: '1440 / 558',
          backgroundImage: `url(${aisystem})`,
        }}
      >
        <div className="absolute inset-0 bg-[rgba(1,1,1,0.24)] backdrop-blur-[1px]"></div>

        <div className="absolute left-[4rem] top-1/2 -translate-y-1/2 h-[398px] flex flex-col justify-between items-start p-0 gap-10">
          <div className="flex flex-col items-start gap-2 h-[120px]">
            <h1 className="uppercase text-white text-[80px] font-bold leading-none">AI 시스템 개발</h1>
            <p className="text-white text-[23px] font-light">혁신적 AI 솔루션으로 비즈니스의 미래를 설계합니다</p>
          </div>

          <div className="flex flex-col gap-10 w-[442px]">
            <div className="flex flex-col p-6 gap-4 bg-[rgba(1,1,1,0.12)] border border-white backdrop-blur-md w-full h-[192px]">
              <p className="text-white text-[16px] font-thin uppercase">
                AI 시스템 개발은 맞춤형 솔루션을 제공해 업무 효율을 높이고 데이터 기반 의사결정을 지원합니다. 혁신
                기술로 비즈니스 경쟁력을 강화하며 성장을 돕습니다
              </p>
              <button
                onClick={handleScrollToSection}
                className="relative w-[120px] px-2 py-3 text-white text-base bg-[rgba(1,1,1,0.12)] border border-white rounded-full overflow-hidden group"
              >
                <div
                  className="absolute inset-0 w-0 bg-[#2E3190] transition-all duration-500 ease-in-out group-hover:w-full"
                  style={{ zIndex: 1 }}
                ></div>
                <span className="relative z-10 transition-colors duration-500 group-hover:text-white">자세히 보기</span>
              </button>
            </div>
          </div>
        </div>

        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 text-center">
          <div className="text-sm font-medium text-white mb-2">아래로 스크롤</div>
          <div className="relative w-[6px] h-[9px] mx-auto mt-1">
            <div className="w-[6px] h-[6px] bg-white rounded-full"></div>
            <div className="w-[1px] h-[20px] bg-[rgba(255,255,255,0.6)] mx-auto"></div>
          </div>
        </div>
      </div>

      <TextSliding
        text={'AI 기술을 활용하여 기업 맞춤형 솔루션, 초개인화 서비스, 데이터 기반 의사결정을 지원합니다'}
        position={664}
      />

      <div ref={sectionRefs.services} className="max-w-[1200px] w-[1180px] mx-auto mt-40 mb-12">
        <div className="w-[1196px] h-[36px] flex items-center justify-center text-[36px] font-bold uppercase text-black">
          주요 서비스
        </div>
      </div>

      {activeTab === 1 && (
        <TabBanner
          image={aisystemTab_1}
          title="AI 서비스 개발"
          details={[
            '웹,앱 고도화',
            '자연어 처리 어플리케이션',
            '컴퓨터 비전 및 이미지 인식',
            'AI기반 자동화 솔루션 (RPA)',
          ]}
        />
      )}
      {activeTab === 2 && (
        <TabBanner
          image={aisystemTab_2}
          title="AI 챗봇 개발"
          details={['맞춤형 AI 챗봇 솔루션을 다양한 산업에 제공합니다']}
        />
      )}
      {activeTab === 3 && (
        <TabBanner
          image={aisystemTab_3}
          title="AI 에이전트 개발"
          details={['인턴 AI', '실용적인 업무 툴, 모든 결정체', '내 손안의 완벽한 업무 비서']}
        />
      )}

      <div className="max-w-[1200px] w-[1180px] mx-auto mt-8">
        <TabMenu tabs={tabs} activeTab={activeTab} onTabClick={handleTabClick} />
      </div>

      <TabContent activeTab={activeTab} />

      {/* 공통 하단부 배너 컴포넌트 */}
      <div className="relative w-full h-[640px] my-12">
        <img src={ourworks_btm_banner} alt="Background" className="absolute w-full h-full object-cover" />
        <div className="absolute inset-0 bg-black bg-opacity-30 backdrop-blur-lg"></div>

        <div
          className="absolute flex flex-col items-center gap-10 w-[819px] h-[200px]"
          style={{
            left: 'calc(50% - 819px / 2)',
            top: 'calc(50% - 200px / 2)',
          }}
        >
          <div className="flex flex-col items-center gap-6">
            <h2 className="w-[819px] h-[64px] font-bold text-white text-[64px] leading-[64px] text-center uppercase">
              ODOC와 미래를 함께할 파트너
            </h2>
            <p className="w-[465px] h-[24px] font-light text-white text-[24px] leading-[24px] text-center">
              문의사항을 남겨주시면 확인 후 연락드리겠습니다.
            </p>
          </div>

          <DetailsButton onClick={() => navigate('/contact')} label="문의하기" />
        </div>
      </div>
    </div>
  );
};

export default AiSystemPage;
