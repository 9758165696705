import React from 'react';

const FeatureCard1 = ({ backgroundImage, title, descriptionLines }) => {
  const getBackdropBlurStyle = () => {
    return {
      content: '""',
      position: 'absolute',
      inset: 0,
      backdropFilter: 'blur(7px)',
      zIndex: 1,
    };
  };

  return (
    <div
      className="relative w-[372px] h-[372px] bg-transparent flex items-center justify-center overflow-hidden group"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div style={getBackdropBlurStyle()} />
      <span className="text-white text-[64px] font-bold" style={{ zIndex: 1 }}>
        {title}
      </span>
      <div className="flex flex-col absolute z-10 inset-0 bg-black translate-x-[-100%] transition-transform duration-500 group-hover:translate-x-0 flex items-center justify-center">
        <span className="text-white text-[24px] font-semibold mb-4">{descriptionLines[0]}</span>
        {descriptionLines
          .filter((_, index) => index !== 0)
          .map((line, index) => (
            <span key={index} className="text-white text-[17px] font-thin">
              {line}
            </span>
          ))}
      </div>
    </div>
  );
};

export default FeatureCard1;
